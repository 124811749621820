import React, {useState, useEffect} from 'react';
import BarChart from '../../../components/BarChart';
import {useDispatch, useSelector} from 'react-redux';
import {
  customChartProperties,
  machineTypeMapping,
  machineTypeReverseMapping,
  roundDecimalPlaces,
  unionLabelsAndDataVehicles,
  deepCopy,
} from '../../../app/utils';
import {inVsOutFieldData} from '../dashboardDataProcessing';
import {
  KpiTooltip,
  ScorecardSelectHolder,
  KpiTable,
  StatsViewReportFiltersWrapper,
  StatsViewReportMainWrapperStyle,
  StatsViewReportSideControlWrapper,
  StatsViewReportGraphAndControlStyle,
} from '../dashboardUtils';
import {Select, MenuItem, CircularProgress, FormControl, InputLabel, Grid, Box} from '@mui/material';
import ChartJS from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import {IntelliSearchSelect} from '../../../components/IntelliSearchSelect';
import {setFilterOptions, setCompareByDatesCache, setCompareByToggle} from '../dashboardSlice';

ChartJS.register(annotationPlugin);
const timeAxes = ['Days', 'Weeks', 'Months', 'Years'];

function KpiOperationalEfficiency(props) {
  const dispatch = useDispatch();
  const loading = useSelector((state) => {
    return state.dashboard.loading;
  });
  const operationsData = useSelector((state) => {
    return state.dashboard.operationsData;
  });
  const inOutTime = useSelector((state) => {
    return state.dashboard.inOutTime;
  });
  const compareByInOutTime = useSelector((state) => {
    return state.dashboard.compareByInOutTime;
  });
  const vehicleSNDict = useSelector((state) => {
    return state.dashboard.vehicleSNDict;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });
  const compareByDatesCache = useSelector((state) => {
    return state.dashboard.compareByDatesCache;
  });
  const compareByOperationsData = useSelector((state) => {
    return state.dashboard.compareByOperationsData;
  });
  const compareByToggle = useSelector((state) => {
    return state.dashboard.compareByToggle;
  });
  const addToScorecardTrigger = useSelector((state) => {
    return state.dashboard.addToScorecardTrigger;
  });
  const selectedSubset = useSelector((state) => {
    return state.dashboard.selectedSubset;
  });
  const comparisonLoading = useSelector((state) => {
    return state.dashboard.comparisonLoading;
  });
  const filterOptions = useSelector((state) => {
    return state.dashboard.filterOptions;
  });
  const vehicleLabels = useSelector((state) => {
    return state.dashboard.vehicleLabels;
  });

  const [addToScorecardOpened, setAddToScorecardOpened] = useState(null);
  const [inVsOutContent, setInVsOutContent] = useState(null);
  const [targetInFieldPercentage, setTargetInFieldPercentage] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [selectedMachineType, setSelectedMachineType] = useState([]);
  const [targetsObject, setTargetsObject] = useState({});

  const [filters, setFilters] = useState({
    'oppEffVehSNs': [],
  });

  // In other scorecards displayedAxis is typically the y axis, however for this scorecard this is the x axis
  // Other scorecards stores x axis field as either ['selectedSpecialView', 'selectedZoneLevel']
  // Only support values listed below
  const [displayedAxis, setDisplayedAxis] = useState('Total');

  // Other scorecards stores y axis as displayedAxis, ony in this page the state & scorecard field is named displayedYAxis
  const [displayedYAxis, setDisplayedYAxis] = useState('percent');

  // Axis displays only support values listed below
  const possibleDisplayedXAxis = ['Total', 'Days', 'Weeks', 'Months', 'Years', 'Vehicle'];
  const possibleDisplayedYAxis = ['percent', 'hours'];

  useEffect(() => {
    // Reset selection if compare by is toggled
    if (compareByToggle && timeAxes.includes(displayedAxis)) {
      setDisplayedAxis('Total');
    }
  }, [compareByToggle]);

  useEffect(() => {
    if (operationsData != null) {
      generateData();
    }
  }, [
    operationsData,
    vehicleSNDict,
    selectedMachineType,
    filters,
    compareByToggle,
    compareByOperationsData,
    compareByInOutTime,
    displayedYAxis,
  ]);

  useEffect(() => {
    if (props.pageStates?.selectedMachineType) {
      let machineTypeVal = props.pageStates?.selectedMachineType;
      if (machineTypeVal == 'All') {
        machineTypeVal = [];
      } else if (typeof machineTypeVal === 'string') {
        const vehicleLabelIdByName = {};
        vehicleLabels.forEach((vehicleLabel) => {
          return (vehicleLabelIdByName[vehicleLabel.name] = vehicleLabel.groupId);
        });

        if (Object.keys(machineTypeReverseMapping).includes(machineTypeVal)) {
          machineTypeVal = machineTypeReverseMapping[machineTypeVal].toString();
        } else if (Object.keys(vehicleLabelIdByName).includes(machineTypeVal)) {
          machineTypeVal = vehicleLabelIdByName[machineTypeVal];
        }
        machineTypeVal = [machineTypeVal];
      }
      setSelectedMachineType(machineTypeVal);
    } else {
      setSelectedMachineType([]);
    }
    if (props.pageStates?.filters) {
      setFilters(props.pageStates.filters);
    }
    if (props.pageStates?.compareByDateRange && props.pageStates?.compareByToggle) {
      dispatch(setCompareByDatesCache(props.pageStates?.compareByDateRange));
    }
    if (props.pageStates?.compareByToggle) {
      dispatch(setCompareByToggle(props.pageStates?.compareByToggle));
    }
    // If we are in a scorecard but compareByToggle is not set. Then we need to set it to false
    else if (props.hasOwnProperty('pageStates')) {
      dispatch(setCompareByToggle(false));
    }

    if (props.pageStates?.displayedAxis && possibleDisplayedXAxis.includes(props.pageStates?.displayedAxis)) {
      setDisplayedAxis(props.pageStates?.displayedAxis);
    } else {
      setDisplayedAxis('Total');
    }

    if (props.pageStates?.displayedYAxis && possibleDisplayedYAxis.includes(props.pageStates?.displayedYAxis)) {
      setDisplayedYAxis(props.pageStates?.displayedYAxis);
    } else {
      setDisplayedYAxis('percent');
    }
  }, [props.pageStates]);

  useEffect(() => {
    if (addToScorecardOpened == null) {
      setAddToScorecardOpened(false);
    } else {
      setAddToScorecardOpened(true);
    }
  }, [addToScorecardTrigger]);

  function handleFilters(value) {
    setFilters({
      'oppEffVehSNs': value,
    });
  }

  function handleVehicleLabelSelect(value) {
    setSelectedMachineType(value);
  }

  function generateData() {
    // Build table & targets
    const [tableData, tempTargetsObject, inFieldTarget] = generateTableData();

    // Generate filters & in vs out data
    const [inFieldSum, outFieldSum] = genFilteredInOutFieldTimes(operationsData);

    // Generate chart data
    const inVsOutContentFiltered = inVsOutFieldData(inFieldSum, outFieldSum, displayedYAxis);

    // if compare by, add compare by data
    if (compareByToggle && compareByOperationsData != null) {
      const [inFieldSumCompareBy, outFieldSumCompareBy] = genFilteredInOutFieldTimes(compareByOperationsData, true);
      const inVsOutContentCompareBy = inVsOutFieldData(inFieldSumCompareBy, outFieldSumCompareBy, displayedYAxis, true);

      // Combine the datasets
      inVsOutContentFiltered.datasets = [...inVsOutContentFiltered.datasets, ...inVsOutContentCompareBy.datasets];
    }

    // Add target line seperately to ensure
    // there is only 1 copy in the dataset
    inVsOutContentFiltered.datasets.push({
      type: 'line',
      label: 'Target',
      backgroundColor: [customChartProperties.colorRed],
      data: [],
      yAxisID: 'TargetAxis',
    });

    // Set data
    setTableData(tableData);
    setTargetsObject(tempTargetsObject);
    setTargetInFieldPercentage(inFieldTarget);
    if (inVsOutContentFiltered) {
      setInVsOutContent(inVsOutContentFiltered);
    } else {
      setInVsOutContent(null);
    }
  }

  function generateTableData() {
    // Targets data
    const inFieldTarget = generateTargetsData();
    const tempTargetsObject = {
      'All': inFieldTarget,
    };

    // Table data
    const tableData = [];
    const vehicleKeysFiltered = Object.keys(operationsData.vehiclesObject).filter((key) => {
      return filters['oppEffVehSNs'].includes(key) || filters['oppEffVehSNs'].length == 0;
    });
    vehicleKeysFiltered.forEach((vehicleSN) => {
      const vehicle = operationsData.vehiclesObject[vehicleSN];
      const inFieldDenominator = vehicle.inFieldTime + vehicle.outOfFieldTime;

      let inFieldPercent = 0;
      if (inFieldDenominator != 0) {
        inFieldPercent = Math.round((vehicle.inFieldTime / inFieldDenominator) * 100);
      }

      let passFilter = false;

      if (filters['oppEffVehSNs'].length == 0 && selectedMachineType.length == 0) {
        // No filters
        passFilter = true;
      } else if (filters['oppEffVehSNs'].length > 0 && selectedMachineType.length > 0) {
        const vehicleLabelsFiltered = vehicleLabels.filter((label) => {
          return selectedMachineType.includes(label.groupId);
        });
        const inLabel = vehicleLabelsFiltered.some((label) => {
          return label.vehicleSNs.includes(vehicleSN);
        });

        // Both filters
        passFilter =
          filters['oppEffVehSNs'].includes(vehicleSN) &&
          (selectedMachineType.includes(vehicleSNDict[vehicleSN].machineType.toString()) || inLabel);
      } else if (filters['oppEffVehSNs'].length > 0) {
        // Vehicle filterpassFilter =
        passFilter = filters['oppEffVehSNs'].includes(vehicleSN);
      } else if (selectedMachineType.length > 0) {
        const vehicleLabelsFiltered = vehicleLabels.filter((label) => {
          return selectedMachineType.includes(label.groupId);
        });
        const inLabel = vehicleLabelsFiltered.some((label) => {
          return label.vehicleSNs.includes(vehicleSN);
        });

        // Vehicle Lable filter
        passFilter = selectedMachineType.includes(vehicleSNDict[vehicleSN].machineType.toString()) || inLabel;
      }

      if (Object.prototype.hasOwnProperty.call(vehicleSNDict, vehicleSN) && passFilter) {
        let vehicleInFieldTarget = inFieldTarget;
        if (customerSettings?.targets?.typeInFieldPercent[vehicleSNDict[vehicleSN].machineType]) {
          vehicleInFieldTarget = customerSettings?.targets?.typeInFieldPercent[vehicleSNDict[vehicleSN].machineType];
        }
        tableData.push({
          vehicleName: vehicle.vehicleName,
          inFieldPercent: inFieldPercent,
          type: machineTypeMapping[vehicleSNDict[vehicleSN].machineType],
          inFieldTarget:
            props.hasOwnProperty('pageStates') &&
            props.pageStates != undefined &&
            props.pageStates.hasOwnProperty('pageTargets') &&
            props.pageStates.pageTargets.hasOwnProperty(vehicleSN)
              ? props.pageStates.pageTargets[vehicleSN]
              : vehicleInFieldTarget,
        });
        tempTargetsObject[vehicleSN] = vehicleInFieldTarget;
      }
    });

    return [tableData, tempTargetsObject, inFieldTarget];
  }

  function generateTargetsData() {
    // Get targets
    let inFieldTarget = customerSettings?.targets?.totalInFieldPercent ?? 0;

    if (selectedMachineType.length == 1 && Object.keys(machineTypeMapping).includes(selectedMachineType[0])) {
      inFieldTarget = customerSettings?.targets?.typeInFieldPercent[parseInt(selectedMachineType[0])];
      if (typeof inFieldTarget === 'undefined') {
        if (customerSettings?.targets?.totalInFieldPercent) {
          inFieldTarget = customerSettings?.targets?.totalInFieldPercent;
        } else {
          inFieldTarget = 0;
        }
      }
    }

    if (
      props.hasOwnProperty('pageStates') &&
      props.pageStates != undefined &&
      props.pageStates.hasOwnProperty('pageTargets')
    ) {
      inFieldTarget = props.pageStates.pageTargets['All'];
    }

    return inFieldTarget;
  }

  function genFilteredInOutFieldTimes(opsData, compareBy = false) {
    // Get in vs out data based on filters
    let inFieldSum = 0; // Reset to re-sum based on filters
    let outFieldSum = 0; // Reset to re-sum based on filters

    Object.keys(opsData.vehiclesObject).forEach((vehicleSN) => {
      let passFilter = false;

      if (filters['oppEffVehSNs'].length == 0 && selectedMachineType.length == 0) {
        // No filters
        passFilter = true;
      } else if (filters['oppEffVehSNs'].length > 0 && selectedMachineType.length > 0) {
        const vehicleLabelsFiltered = vehicleLabels.filter((label) => {
          return selectedMachineType.includes(label.groupId);
        });
        const inLabel = vehicleLabelsFiltered.some((label) => {
          return label.vehicleSNs.includes(vehicleSN);
        });

        // Both filters
        passFilter =
          filters['oppEffVehSNs'].includes(vehicleSN) &&
          (selectedMachineType.includes(vehicleSNDict[vehicleSN].machineType.toString()) || inLabel);
      } else if (filters['oppEffVehSNs'].length > 0) {
        // Vehicle filterpassFilter =
        passFilter = filters['oppEffVehSNs'].includes(vehicleSN);
      } else if (selectedMachineType.length > 0) {
        const vehicleLabelsFiltered = vehicleLabels.filter((label) => {
          return selectedMachineType.includes(label.groupId);
        });
        const inLabel = vehicleLabelsFiltered.some((label) => {
          return label.vehicleSNs.includes(vehicleSN);
        });

        // Vehicle Lable filter
        passFilter = selectedMachineType.includes(vehicleSNDict[vehicleSN].machineType.toString()) || inLabel;
      }

      if (passFilter) {
        inFieldSum += opsData.vehiclesObject[vehicleSN]['inFieldTime'];
        outFieldSum += opsData.vehiclesObject[vehicleSN]['outOfFieldTime'];
      }
    });

    return [inFieldSum, outFieldSum];
  }

  function operationalEfficiencyGraphData() {
    let inFieldList = [];
    let outFieldList = [];
    let labelsFormatted = [];

    // Union keys between compare by and non compare by
    // for vehicles if compare by is toggled
    const opsDataCopy = deepCopy(operationsData);
    const compareByOpsDataCopy = deepCopy(compareByOperationsData);
    if (compareByToggle) {
      const [newOpsVehiclesObject, newCompareByOpsVehiclesObject] = unionLabelsAndDataVehicles(
        operationsData.vehiclesObject,
        compareByOperationsData.vehiclesObject,
        ['type', 'vehicleName']
      );
      opsDataCopy.vehiclesObject = newOpsVehiclesObject;
      compareByOpsDataCopy.vehiclesObject = newCompareByOpsVehiclesObject;
    }

    // Populate data based on time axis
    if (timeAxes.includes(displayedAxis)) {
      [inFieldList, outFieldList, labelsFormatted] = operationalEfficiencyTimeData(operationsData);
    }
    // Populate data based on vehicle
    else if (displayedAxis === 'Vehicle') {
      [inFieldList, outFieldList, labelsFormatted] = operationalEfficiencyVehicleData(opsDataCopy.vehiclesObject);
    }

    // Build the graph datasets
    const graphData = generateGraphDatasets(inFieldList, outFieldList, labelsFormatted);

    // if compare by, add compare by data
    if (compareByToggle && compareByOperationsData != null) {
      const [inFieldListCompareBy, outFieldListCompareBy, labelsFormattedCompareBy] = operationalEfficiencyVehicleData(
        compareByOpsDataCopy.vehiclesObject
      );
      const graphDataCompareBy = generateGraphDatasets(
        inFieldListCompareBy,
        outFieldListCompareBy,
        labelsFormattedCompareBy,
        true
      );

      // Combine the datasets
      graphData.datasets = [...graphData.datasets, ...graphDataCompareBy.datasets];
    }

    return graphData;
  }

  function operationalEfficiencyTimeData(opsData) {
    const inFieldList = [];
    const outFieldList = [];
    const labelsFormatted = [];
    let labels = [];

    // Populate data based on time axis
    const timeData = {
      'Days': opsData.opsByDay,
      'Weeks': opsData.opsByWeek,
      'Months': opsData.opsByMonth,
      'Years': opsData.opsByYear,
    };
    const selectedTimeData = timeData[displayedAxis];
    labels = Object.keys(selectedTimeData);
    labels.forEach((label) => {
      const vehObject = selectedTimeData[label]['operationsReport']['vehiclesObject'];

      let periodInFieldTotal = 0;
      let periodOutFieldTotal = 0;

      Object.keys(vehObject).forEach((vehicleSN) => {
        let passFilter = false;
        if (filters['oppEffVehSNs'].length == 0 && selectedMachineType.length == 0) {
          // No filters
          passFilter = true;
        } else if (filters['oppEffVehSNs'].length > 0 && selectedMachineType.length > 0) {
          const vehicleLabelsFiltered = vehicleLabels.filter((label) => {
            return selectedMachineType.includes(label.groupId);
          });
          const inLabel = vehicleLabelsFiltered.some((label) => {
            return label.vehicleSNs.includes(vehicleSN);
          });

          // Both filters
          passFilter =
            filters['oppEffVehSNs'].includes(vehicleSN) &&
            (selectedMachineType.includes(vehicleSNDict[vehicleSN].machineType.toString()) || inLabel);
        } else if (filters['oppEffVehSNs'].length > 0) {
          // Vehicle filterpassFilter =
          passFilter = filters['oppEffVehSNs'].includes(vehicleSN);
        } else if (selectedMachineType.length > 0) {
          const vehicleLabelsFiltered = vehicleLabels.filter((label) => {
            return selectedMachineType.includes(label.groupId);
          });
          const inLabel = vehicleLabelsFiltered.some((label) => {
            return label.vehicleSNs.includes(vehicleSN);
          });

          // Vehicle Lable filter
          passFilter = selectedMachineType.includes(vehicleSNDict[vehicleSN].machineType.toString()) || inLabel;
        }

        if (passFilter) {
          periodInFieldTotal += vehObject[vehicleSN].inFieldTime;
          periodOutFieldTotal += vehObject[vehicleSN].outOfFieldTime;
        }
      });

      inFieldList.push(periodInFieldTotal);
      outFieldList.push(periodOutFieldTotal);
      labelsFormatted.push(label.slice(0, 10)); // Format labels to only be in YYYY-MM-DD format
    });

    return [inFieldList, outFieldList, labelsFormatted];
  }

  function operationalEfficiencyVehicleData(vehiclesObject) {
    const inFieldList = [];
    const outFieldList = [];
    const labelsFormatted = [];

    // Populate data based on time axis
    Object.keys(vehiclesObject).forEach((vehicleSN) => {
      let passFilter = false;
      if (filters['oppEffVehSNs'].length == 0 && selectedMachineType.length == 0) {
        // No filters
        passFilter = true;
      } else if (filters['oppEffVehSNs'].length > 0 && selectedMachineType.length > 0) {
        const vehicleLabelsFiltered = vehicleLabels.filter((label) => {
          return selectedMachineType.includes(label.groupId);
        });
        const inLabel = vehicleLabelsFiltered.some((label) => {
          return label.vehicleSNs.includes(vehicleSN);
        });

        // Both filters
        passFilter =
          filters['oppEffVehSNs'].includes(vehicleSN) &&
          (selectedMachineType.includes(vehicleSNDict[vehicleSN].machineType.toString()) || inLabel);
      } else if (filters['oppEffVehSNs'].length > 0) {
        // Vehicle filterpassFilter =
        passFilter = filters['oppEffVehSNs'].includes(vehicleSN);
      } else if (selectedMachineType.length > 0) {
        const vehicleLabelsFiltered = vehicleLabels.filter((label) => {
          return selectedMachineType.includes(label.groupId);
        });
        const inLabel = vehicleLabelsFiltered.some((label) => {
          return label.vehicleSNs.includes(vehicleSN);
        });

        // Vehicle Lable filter
        passFilter = selectedMachineType.includes(vehicleSNDict[vehicleSN].machineType.toString()) || inLabel;
      }

      if (passFilter) {
        inFieldList.push(vehiclesObject[vehicleSN].inFieldTime);
        outFieldList.push(vehiclesObject[vehicleSN].outOfFieldTime);
        labelsFormatted.push(vehiclesObject[vehicleSN].vehicleName);
      }
    });

    return [inFieldList, outFieldList, labelsFormatted];
  }

  function generateGraphDatasets(inFieldList, outFieldList, labelsFormatted, compareBy = false) {
    // Get percent values
    const inFieldPercentList = [];
    const outFieldPercentList = [];
    inFieldList.forEach((inFieldVal, idx) => {
      const outFieldVal = outFieldList[idx];
      const totalTime = inFieldVal + outFieldVal;

      const inFieldPercent = ((inFieldVal / totalTime) * 100).toFixed(2);
      const outFieldPercent = ((outFieldVal / totalTime) * 100).toFixed(2);
      inFieldPercentList.push(inFieldPercent);
      outFieldPercentList.push(outFieldPercent);
    });

    // Generate graph data
    const graphData = {
      labels: labelsFormatted,
      datasets: [
        {
          type: 'bar',
          label: compareBy ? `Comparison In Field Time` : `In Field Time`,
          data: displayedYAxis == 'percent' ? inFieldPercentList : inFieldList,
          id: 'inField',
          borderWidth: 1,
          order: 2,
          backgroundColor: !compareBy ? [customChartProperties.colorGreen] : [customChartProperties.colorBlue],
          borderColor: !compareBy ? [customChartProperties.colorGreen] : [customChartProperties.colorBlue],
          yAxisID: 'leftAxis',
          stack: compareBy ? 'Compare Stack' : 'Regular Stack',
          tooltip: {
            callbacks: {
              label: (context) => {
                const totalRow = `Total: ${roundDecimalPlaces(inFieldList[context.dataIndex], 2)} Hours`;
                const percentRow = `Percentage: ${inFieldPercentList[context.dataIndex]}%`;
                const tooltipRows = ['In Field: ', totalRow, percentRow];
                return tooltipRows;
              },
            },
          },
        },
        {
          type: 'bar',
          label: compareBy ? `Comparison Out of Field Time` : `Out of Field Time`,
          data: displayedYAxis == 'percent' ? outFieldPercentList : outFieldList,
          id: 'outField',
          borderWidth: 1,
          order: 2,
          backgroundColor: !compareBy ? [customChartProperties.colorGrey] : [customChartProperties.colorLightGrey],
          borderColor: !compareBy ? [customChartProperties.colorGrey] : [customChartProperties.colorLightGrey],
          yAxisID: 'leftAxis',
          stack: compareBy ? 'Compare Stack' : 'Regular Stack',
          tooltip: {
            callbacks: {
              label: (context) => {
                const totalRow = `Total: ${roundDecimalPlaces(outFieldList[context.dataIndex], 2)} Hours`;
                const percentRow = `Percentage: ${outFieldPercentList[context.dataIndex]}%`;
                const tooltipRows = ['Out of Field: ', totalRow, percentRow];
                return tooltipRows;
              },
            },
          },
        },
      ],
    };

    return graphData;
  }

  function operationalEfficiencyBarGraph() {
    if (displayedAxis !== 'Total') {
      // Get data
      const graphData = operationalEfficiencyGraphData();

      return (
        <div style={{height: '50vh', maxHeight: '400px'}}>
          <BarChart
            data={graphData}
            options={{
              maintainAspectRatio: false,
              plugins: {
                title: {
                  text: 'In Field vs. Out of Field Time',
                  display: true,
                  font: {
                    size: customChartProperties.titleFontSize,
                    lineHeight: customChartProperties.lineHeight,
                  },
                },
                legend: {
                  onClick: (e) => {
                    return false;
                  },
                  labels: {
                    font: {
                      size: customChartProperties.legendFontSize,
                    },
                  },
                },
              },
              scales: {
                leftAxis: {
                  stacked: true,
                  type: 'linear',
                  position: 'left',
                  ticks: {
                    count: 5,
                    min: 0, // Minimum value
                    max: displayedYAxis == 'percent' ? 100 : null, // Maximum value
                    beginAtZero: true,
                    autoSkip: false,
                    stepSize: 20,
                    callback: function (val, index) {
                      return displayedYAxis == 'percent' ? `${val} %` : val;
                    },
                  },
                },
                x: {
                  stacked: true,
                  ticks: {
                    autoSkip: graphData.labels.length > customChartProperties.autoSkipLimit,
                    maxRotation: 90,
                    minRotation: 0,
                    font: {
                      size: customChartProperties.xLabelFontSize,
                      lineHeight: customChartProperties.lineHeight,
                    },
                  },
                  title: {
                    display: false,
                  },
                },
              },
            }}
          />
        </div>
      );
    } else return null;
  }

  function operationalEfficiencyTotalBarGraph() {
    return (
      <div style={{height: '50vh', maxHeight: '400px', alignItems: 'center'}}>
        {/* Chart */}
        <BarChart
          data={inVsOutContent}
          options={{
            maintainAspectRatio: false,
            plugins: {
              title: {
                text: 'In Field vs. Out of Field Time',
                display: true,
                font: {
                  size: customChartProperties.titleFontSize,
                  lineHeight: customChartProperties.lineHeight,
                },
              },
              legend: {
                onClick: (e) => {
                  return false;
                },
                labels: {
                  // Filter out the target legend item if the target is 0
                  filter: function (legendItem, data) {
                    return legendItem.text != 'Target' || (displayedYAxis == 'percent' && targetInFieldPercentage);
                  },
                  font: {
                    size: customChartProperties.legendFontSize,
                  },
                },
              },

              // Target Line
              annotation: {
                annotations: {
                  line1: {
                    type: 'line',
                    scaleID: 'y',
                    value: targetInFieldPercentage,
                    endValue: targetInFieldPercentage,
                    borderColor: customChartProperties.colorRed,
                    borderWidth: 2,
                    display: displayedYAxis == 'percent' && targetInFieldPercentage && targetInFieldPercentage !== 0,
                  },
                },
              },
            },
            scales: {
              y: {
                type: 'linear',
                position: 'left',
                stacked: true,
                ticks: {
                  count: 5,
                  min: 0, // Minimum value
                  max: displayedYAxis == 'percent' ? 100 : null, // Maximum value
                  beginAtZero: true,
                  autoSkip: false,
                  stepSize: 20,
                  callback: function (val, index) {
                    return displayedYAxis == 'percent' ? `${val} %` : val;
                  },
                },
              },
              x: {
                stacked: true,
                display: false,
              },

              // Don't show a seperate axis for the target line
              TargetAxis: {
                title: {
                  text: '',
                  display: false,
                },
                display: false,
              },
            },
          }}
        />
      </div>
    );
  }

  function filterControlComponents() {
    return [
      <IntelliSearchSelect
        key='operationEfficiencyVehicleFilter'
        id='op-eff-vehicle-name-select'
        name={'oppEffVehSNs'}
        allNoneEnabled
        multiple
        label='Vehicles'
        size='small'
        displayEmpty
        value={filters['oppEffVehSNs']}
        options={addCompareByVehicles(filterOptions.vehicles.vehicleSNs).map((opt) => {
          return {'label': opt.text, 'value': opt.value};
        })}
        onChange={(e) => {
          handleFilters(e);
        }}
      />,
      <IntelliSearchSelect
        label='Vehicle Labels'
        allNoneEnabled
        key='operationEfficiencyVehicleTypeFilter'
        multiple={true}
        search={true}
        value={selectedMachineType}
        onChange={(e) => {
          handleVehicleLabelSelect(e);
        }}
        options={filterOptions.vehicles.vehicleType.map((opt) => {
          return {'label': opt.text, 'value': opt.value};
        })}
      />,
    ];
  }

  function addCompareByVehicles(ogVehicles) {
    let combined = deepCopy(ogVehicles);
    if (compareByToggle && compareByOperationsData != null) {
      const mainSetVehicleSN = ogVehicles.map((option) => {
        return option.value;
      });
      combined = [
        ...ogVehicles,
        ...Object.keys(compareByOperationsData.vehiclesObject)
          .filter((key) => {
            return !mainSetVehicleSN.includes(key);
          })
          .map((opt) => {
            return {'text': vehicleSNDict[opt].name, 'value': opt};
          }),
      ];
      combined = [...combined].sort((optA, optB) => {
        return optA.text.localeCompare(optB.text);
      });
    }
    return combined;
  }

  // Check if loading
  if (loading || (comparisonLoading && compareByToggle)) {
    return <CircularProgress className='mt-4 mx-auto d-block' size={200} />;
  }

  if (props.graphOnly) {
    return (
      <React.Fragment>
        {/* Tooltip */}
        <div className='col-12 flex-col px-0'>
          <KpiTooltip selectedSubset={selectedSubset} />
        </div>
        {!inVsOutContent ? (
          <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
        ) : (
          <React.Fragment>
            <div className='col-lg-6 flex-col text-center px-0 align-items-center mb-auto pb-3'>
              {displayedAxis === 'Total' ? operationalEfficiencyTotalBarGraph() : operationalEfficiencyBarGraph()}
            </div>
            <div className='col-lg-6 flex-col text-center px-0 align-items-center mb-auto pb-3'>
              <KpiTable pageStates={props.pageStates} tableData={tableData} type={'operationalEff'} />
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  return (
    <Box sx={StatsViewReportMainWrapperStyle}>
      {/* Hidden Add to Scorecard*/}
      <ScorecardSelectHolder
        autofillPageName={'Farming Operational Efficiency'}
        pageStates={{
          pageTargets: targetsObject,
          selectedMachineType: selectedMachineType,
          filters: filters,
          compareByToggle: compareByToggle,
          compareByDateRange: compareByDatesCache,
          displayedAxis: displayedAxis,
          displayedYAxis: displayedYAxis,
        }}
        hidden={true}
        opened={addToScorecardOpened}
        updateExternalOpenedState={setAddToScorecardOpened}
      />

      {/* In Small screen mode Filters and Sort by display */}
      {smallScreen && (
        <StatsViewReportFiltersWrapper key={`operationEfficiencyFilter`}>
          {filterControlComponents()}
        </StatsViewReportFiltersWrapper>
      )}
      {!inVsOutContent ? (
        <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
      ) : (
        <React.Fragment>
          {/* Graph and XY Axis Control */}
          <Grid container spacing={2} sx={{padding: 1}}>
            <Grid item xs={smallScreen ? 12 : 7} md={8} lg={9}>
              <Box sx={StatsViewReportGraphAndControlStyle}>
                {/* Y-Axis Controls */}
                <Grid
                  item
                  xs={1}
                  lg={1}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      '.MuiInputBase-input': {
                        padding: '5px',
                      },
                      display: 'flex',
                      flexDirection: 'row',
                      position: 'relative',
                      transformOrigin: 'center',
                      transform: 'rotate(270deg)',
                      margin: 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <FormControl sx={{m: 1, display: 'flex'}}>
                      <InputLabel sx={{maxWidth: 'none'}}>Measure by</InputLabel>
                      <Select
                        sx={{padding: '3px'}}
                        size='small'
                        label='Measure by'
                        value={displayedYAxis}
                        onChange={(e) => {
                          setDisplayedYAxis(e.target.value);
                        }}
                      >
                        <MenuItem value={'percent'}>{'% In Field'}</MenuItem>
                        <MenuItem value={'hours'}>{`Hours`}</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                {/* Graph */}
                <Grid item xs={10} md={6} sx={{textAlign: 'center', alignItems: 'center'}}>
                  {displayedAxis === 'Total' ? operationalEfficiencyTotalBarGraph() : operationalEfficiencyBarGraph()}
                  <FormControl sx={{m: 1}}>
                    <InputLabel sx={{maxWidth: 'none'}}>View by</InputLabel>
                    <Select
                      sx={{padding: '3px'}}
                      size='small'
                      label='View by'
                      value={displayedAxis}
                      onChange={(e) => {
                        setDisplayedAxis(e.target.value);
                      }}
                    >
                      <MenuItem value={'Total'}>{'Total'}</MenuItem>
                      <MenuItem disabled={compareByToggle} value={'Days'}>
                        {'Days'}
                      </MenuItem>
                      <MenuItem disabled={compareByToggle} value={'Weeks'}>
                        {'Weeks'}
                      </MenuItem>
                      <MenuItem disabled={compareByToggle} value={'Months'}>
                        {'Months'}
                      </MenuItem>
                      <MenuItem disabled={compareByToggle} value={'Years'}>
                        {'Years'}
                      </MenuItem>
                      <MenuItem value={'Vehicle'}>{'Vehicle'}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={5} sx={{textAlign: 'center', alignItems: 'center'}}>
                  <KpiTable pageStates={props.pageStates} tableData={tableData} type={'operationalEff'} />
                </Grid>
              </Box>
            </Grid>
            {!smallScreen && (
              <Grid
                item
                xs={5}
                md={4}
                lg={3}
                sx={{
                  display: smallScreen ? 'none' : 'block',
                }}
              >
                <StatsViewReportSideControlWrapper filterControlComponents={filterControlComponents()} />
              </Grid>
            )}
          </Grid>
        </React.Fragment>
      )}
    </Box>
  );
}

export {KpiOperationalEfficiency};
