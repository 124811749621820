import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {InspectionReportsTable} from './InspectionReportsTable';
import {InspectionReportsModal} from './InspectionReportsModal';
import {DateTime} from 'luxon';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {sortVehicleNamesHelper} from '../../../app/utils';
import {cloneDeep} from 'lodash';

function InspectionReportsTab(props) {
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const vehiclesLookUp = useSelector((state) => {
    return state.shopview.vehiclesLookUp;
  });
  const inspectionItemsLookUp = useSelector((state) => {
    return state.shopview.inspectionItemsLookUp;
  });
  const inspectionReportNameInputRequired = useSelector((state) => {
    return state.app.customerSettings.inspection.inspectionReportNameInput;
  });

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dates, setDates] = useState({
    start: DateTime.local().set({hour: 0, minute: 0, second: 0, millisecond: 0}),
    end: DateTime.local().set({hour: 23, minute: 59, second: 59, millisecond: 999}),
  });
  const [filterOptions, setFilterOptions] = useState({
    vehicles: [],
    status: [],
  });

  // Get new inspection reports data when date range changes
  useEffect(() => {
    getInspectionReports();
  }, [dates, vehiclesLookUp]);

  function dateSelection(event, picker) {
    const startDate = DateTime.fromISO(picker.startDate.toISOString());
    const endDate = DateTime.fromISO(picker.endDate.toISOString()).set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 999,
    });
    setDates({
      start: startDate,
      end: endDate,
    });
  }

  async function getInspectionReports() {
    // Fetch data based on data range picker and update component states
    setLoading(true);

    const startDateObj = cloneDeep(dates.start);
    const startDateObjCust = startDateObj.setZone(customerSettings.general.timeZone, {
      keepLocalTime: true,
    });
    const endDateObj = cloneDeep(dates.end);
    const endDateObjCust = endDateObj.setZone(customerSettings.general.timeZone, {
      keepLocalTime: true,
    });
    const startDateObjCustIso = startDateObjCust.toISO();
    const endDateObjCustIso = endDateObjCust.toISO();

    // Get Inspection report data
    const getInspectionReportsResponse = await fetch(
      '/getInspectionReportsByDateTime?' +
        new URLSearchParams({startTime: startDateObjCustIso, endTime: endDateObjCustIso}, {cache: 'no-store'})
    );
    const getInspectionReportsResult = await getInspectionReportsResponse.json();

    const rowList = [];
    const filterOptionsVehicle = [];
    const filterOptionsStatus = [
      {text: 'Passed', value: 'passed'},
      {text: 'Failed', value: 'failed'},
      {text: 'Closed', value: 'closed'},
    ];
    for (let i = 0; i < getInspectionReportsResult.length; i++) {
      const report = getInspectionReportsResult[i].data;
      const reportDocId = getInspectionReportsResult[i].uid;
      const vehicle = vehiclesLookUp[report.selectedVeh];
      if (!vehicle) {
        continue;
      }

      const vehicleInspectionActive = !(vehicle?.inspectionActive === false);
      // Skip Vehicle if vehicle not set to active for inspection
      if (!vehicleInspectionActive) {
        continue;
      }

      let failCount = 0;
      for (let index = 0; index < report.items.length; index++) {
        if (report.items[index].passed === false) {
          failCount++;
        }
      }

      let reportStatus = 'passed';
      if (failCount > 0) {
        reportStatus = 'failed';
        if (report.closed) {
          reportStatus = 'closed';
        }
      }

      // Parse reports time to correct user time
      const inspectedDate = DateTime.fromISO(report.date_time).setZone(customerSettings.general.timeZone);
      const inspectedDateString = inspectedDate.toFormat('yyyy-MM-dd hh:mm a');

      let closedDateString = '';
      if (report.closedDatetime) {
        const closedDate = DateTime.fromISO(report.closedDatetime).setZone(customerSettings.general.timeZone);
        closedDateString = closedDate.toFormat('yyyy-MM-dd hh:mm a');
      }

      rowList.push({
        vehicle: vehicle.name,
        items: report.items,
        inspectedDate: inspectedDateString,
        closedDate: closedDateString,
        date_time: report.date_time,
        status: reportStatus,
        failCount: failCount,
        inspectedByUser: report.inspectedByUser,
        closedByUser: report.closedByUser,
        comment: report.comment,
        reportDocId: reportDocId,
        inspectionNameInput: report.inspectionNameInput,
      });

      // Update filter options
      filterOptionsVehicle.push(vehicle.name);
    }

    // Add filter options to dropdowns
    filterOptionsVehicle.sort((a, b) => {
      return sortVehicleNamesHelper(a, b);
    });
    setFilterOptions((values) => {
      return {...values, vehicles: [...new Set(filterOptionsVehicle)], status: filterOptionsStatus};
    });

    // Sort rows based on datetime newest > oldest
    rowList.sort((a, b) => {
      return new Date(a.date_time) < new Date(b.date_time)
        ? 1
        : new Date(b.date_time) < new Date(a.date_time)
        ? -1
        : 0;
    });

    setTableData(rowList);
    setLoading(false);
  }

  function downloadInspectionSummaryCSV() {
    const reportHeader = [
      'Vehicle Name',
      'Inspected Date',
      'Inspected By User',
      'Closed Date',
      'Closed By User',
      'Report Status',
      'Report Comments',
      ...(inspectionReportNameInputRequired ? ['Inpsection Name Entered'] : []),
    ];
    const reportData = [];

    const reportInspectionItemsIdArray = [];

    const tableDataReversed = tableData.slice().reverse(); // Reverse data so csv is chronological

    tableDataReversed.forEach((rowData) => {
      const tempData = [
        `\"${rowData.vehicle}\"`,
        `\"${rowData.inspectedDate}\"`,
        `\"${rowData.inspectedByUser}\"`,
        `\"${rowData.closedDate}\"`,
        `\"${rowData.closedByUser}\"`,
        `\"${rowData.status}\"`,
        `\"${rowData.comment}\"`, // Used to ignore commas and breaks
        ...(inspectionReportNameInputRequired ? [`${rowData.inspectionNameInput || ''}`] : []),
      ];

      // Determine status of each item
      const inspectionItemsStatusObj = {};
      rowData.items.forEach((inspectionItem) => {
        if (!reportInspectionItemsIdArray.includes(inspectionItem.inspectionItemId)) {
          reportInspectionItemsIdArray.push(inspectionItem.inspectionItemId);
        }
        inspectionItemsStatusObj[inspectionItem.inspectionItemId] = {
          status: inspectionItem.passed ? 'passed' : 'failed',
          comments: `\"${inspectionItem.comments}\"`, // Used to ignore commas and breaks
        };
      });

      // Add status of items to data
      for (let i = 0; i < reportInspectionItemsIdArray.length; i++) {
        if (reportInspectionItemsIdArray[i] in inspectionItemsStatusObj) {
          tempData.push(inspectionItemsStatusObj[reportInspectionItemsIdArray[i]].status);
          tempData.push(inspectionItemsStatusObj[reportInspectionItemsIdArray[i]].comments);
        } else {
          tempData.push('');
          tempData.push('');
        }
      }

      reportData.push(tempData);
    });

    // Add item names to header
    reportInspectionItemsIdArray.forEach((inspectionItemId) => {
      reportHeader.push(inspectionItemsLookUp[inspectionItemId].name);
      reportHeader.push(`${inspectionItemsLookUp[inspectionItemId].name} - comments`);
    });

    reportData.unshift(reportHeader); // Add header to start of reportData array
    let csv = [];
    reportData.forEach(function (row) {
      csv += row.join(',');
      csv += '\n';
    });

    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.href = hiddenElement.href.replace(/#/g, '%23');
    hiddenElement.target = '_blank';
    hiddenElement.download = 'inspection_summary.csv';
    hiddenElement.click();
  }

  return (
    <div className='tab-wrapper'>
      <div className='row my-2'>
        <div className='row col-md-5 col-xl-3 mx-0'>
          <DateRangePicker
            onApply={dateSelection}
            initialSettings={{
              startDate: dates.start.toFormat('MM/dd/yyyy'),
              endDate: dates.start.toFormat('MM/dd/yyyy'),
              maxDate: DateTime.local(),
              autoApply: true,
            }}
          >
            <button className='btn border-dark btn-light col-12'>
              <FontAwesomeIcon icon='fas fa-calendar-alt' />
              {` ${dates.start.toLocaleString(DateTime.DATE_MED)} - ${dates.end.toLocaleString(DateTime.DATE_MED)} `}
              <FontAwesomeIcon icon='fas fa-caret-down' />
            </button>
          </DateRangePicker>
        </div>
        <div className='row ml-auto mx-0 col-md-4 col-xl-3'>
          <button type='button' className='btn border-dark btn-light col-12' onClick={downloadInspectionSummaryCSV}>
            Download Inspection Summary
          </button>
        </div>
      </div>
      <InspectionReportsTable tableData={tableData} filterOptions={filterOptions} loading={loading} />
      <InspectionReportsModal getInspectionReports={getInspectionReports} />
    </div>
  );
}

export {InspectionReportsTab};
