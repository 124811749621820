import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IntelliSearchSelect} from './IntelliSearchSelect';
import {sortVehicleNamesHelper, machineTypeMapping, fetchPostAuthSafe, sendGAEvent} from '../app/utils';

import {FormControl, Typography, Modal, Box, Button} from '@mui/material';
import {updateAppFiltersActive} from '../appSlice';

const plotLinesDisplayBoxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 525,
  maxWidth: '90vw',
  borderRadius: '16px', // Added rounded corners
  p: 3,
  backdropFilter: 'blur(8px)',
  backgroundColor: 'rgba(255, 255, 255, 0.9)', // Slight transparency
  border: 'none',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.12), 0 2px 8px rgba(0, 0, 0, 0.04)', // Layered shadow
};

function GlobalFilterModal(props) {
  const dispatch = useDispatch();

  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const appFilters = useSelector((state) => {
    return state.app.appFilters;
  });
  const vehicles = useSelector((state) => {
    return state.app.vehicles;
  });
  const vehicleLabels = useSelector((state) => {
    return state.app.vehicleLabels;
  });

  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [selectedVehicleLabels, setSelectedVehicleLabels] = useState([]);
  const [vehicleOptions, setVehicleOptions] = useState([]);
  const [vehicleLabelOptions, setVehicleLabelOptions] = useState([]);

  useEffect(() => {
    if (props.open) {
      setCurrentSelectedvalues();
      getVehicleOptions();
    }
  }, [props.open]);

  async function setCurrentSelectedvalues() {
    let tempVehicleFilters = [];
    let tempVehicleLabelFilters = [];
    if (Object.keys(appFilters).length) {
      tempVehicleFilters = appFilters.vehicles;
      tempVehicleLabelFilters = appFilters.vehicleLabels;
    }

    setSelectedVehicles(tempVehicleFilters);
    setSelectedVehicleLabels(tempVehicleLabelFilters);
  }

  async function getVehicleOptions() {
    // Collect how many vehicles in each vehicle type
    const typeVehicleCntDict = {};
    Object.keys(machineTypeMapping).forEach((machineType) => {
      typeVehicleCntDict[machineType] = 0;
    });
    vehicles.forEach((vehicle) => {
      if (typeof vehicle.machineType !== 'undefined') {
        typeVehicleCntDict[vehicle.machineType] += 1;
      }
    });

    // Format options
    const tempVehicleOptions = vehicles.map((vehicle) => {
      return {value: vehicle.serialNumber, label: vehicle.name};
    });
    const tempVehicleTypeOptions = Object.keys(machineTypeMapping).map((machineType) => {
      return {value: machineType, label: `${machineTypeMapping[machineType]} (${typeVehicleCntDict[machineType]})`};
    });
    const tempVehicleLabelOptions = vehicleLabels.map((label) => {
      return {value: label.groupId, label: `${label.name} (${label.vehicleSNs.length})`};
    });

    setVehicleOptions(tempVehicleOptions);
    setVehicleLabelOptions(tempVehicleTypeOptions.concat(tempVehicleLabelOptions));
  }

  async function handleSubmit() {
    const newAppFilters = {
      vehicles: selectedVehicles,
      vehicleLabels: selectedVehicleLabels,
    };

    // Update backend session
    const postObj = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({appFilters: newAppFilters}),
    };

    await fetchPostAuthSafe('/updateFilterSessionData', postObj, userSettings.username, userSettings.databaseName);

    // Send GA event
    sendGAEvent('global_filter', 'GlobalFilter', 'globalFilter');

    // Reload page
    window.location.reload(true);
  }

  function handleClose() {
    props.setFiltersModalOpen(false);
  }

  return (
    <Modal open={props.open} onClose={handleClose}>
      <Box sx={plotLinesDisplayBoxStyle}>
        <Typography variant='h5' gutterBottom style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
          Vehicle Filters
        </Typography>

        <FormControl fullWidth margin='normal'>
          <Typography variant='body1' sx={{marginBottom: '8px'}}>
            Vehicle Labels:
          </Typography>
          <IntelliSearchSelect
            multiple={true}
            value={selectedVehicleLabels}
            onChange={(newVal) => {
              setSelectedVehicleLabels(newVal);
            }}
            options={vehicleLabelOptions}
            allNoneEnabled
            optionDisplayLimit={2}
            search={true}
          />
        </FormControl>

        <FormControl fullWidth margin='normal'>
          <Typography variant='body1' sx={{marginBottom: '8px'}}>
            Vehicles:
          </Typography>
          <IntelliSearchSelect
            multiple={true}
            value={selectedVehicles}
            onChange={(newVal) => {
              setSelectedVehicles(newVal);
            }}
            options={vehicleOptions}
            allNoneEnabled
            optionDisplayLimit={2}
            search={true}
          />
        </FormControl>

        <div className='mt-5' style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
          <Button variant='ic-button' color='secondary' onClick={handleClose}>
            Cancel
          </Button>
          <Button variant='ic-button' color='primary' onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export {GlobalFilterModal};
