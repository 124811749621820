import ReactGA from 'react-ga4';
import {cloneDeep} from 'lodash';

export const MS_PER_VEHICLEDAY = 0.5;
export const KM_TO_MI = 0.621371;
export const M_TO_FT = 3.28084; // May change to FT_TO_M = 0.3048 since more exact 3.28084
export const AC_TO_HA = 0.404686;
export const HA_TO_AC = 2.47105;
export const AC_TO_METERS2 = 4046.856422;
export const HA_TO_METERS2 = 10000;
export const PATH_VEHICLE_DAYS_MAP_RENDER_THRESHOLD = 3500;

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * Round a number to a specified number of decimal places, returns a number type,
 * works for floats cause javascript is weird & annoying like that
 *
 * @param {Number} value Value to round
 * @param {Number} decimalPlaces Number of decimal places to round to
 * @return {Number} Rounded value
 */
export function floatRound(value, decimalPlaces) {
  // Using Number.EPSILON to avoid floating point errors
  if (decimalPlaces > 0) {
    return Math.round((value + Number.EPSILON) * (10 * decimalPlaces)) / (10 * decimalPlaces);
  } else {
    Math.round(value);
  }
}

export function hasDuplicates(arr) {
  const noDupsArr = [];
  let foundDup = false;
  debugger;
  for (let i = 0; i < arr.length; i++) {
    if (noDupsArr.includes(arr[i])) {
      foundDup = true;
      break;
    } else {
      noDupsArr.push(arr[i]);
    }
  }
  return foundDup;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export async function fetchPostAuthSafe(url, options, sessionSyncUserName, sessionSyncDb, cache = null) {
  options.headers['sessionSyncDb'] = sessionSyncDb;
  options.headers['sessionSyncUserName'] = sessionSyncUserName;
  // If we make a post request to the backend and the sessionSyncDb or
  // sessionSyncUserName is null or not matching the request will be rejected,
  // by the requireLogin middleware and you will be logged out and an error will be displayed
  const result = await fetch(url, options, cache);
  return result;
}

export async function fetchPostAuthSafeWithTimeout(
  url,
  options,
  sessionSyncUserName,
  sessionSyncDb,
  timeout = 10000,
  cache = null
) {
  options.headers['sessionSyncDb'] = sessionSyncDb;
  options.headers['sessionSyncUserName'] = sessionSyncUserName;

  // Check if online
  if (!navigator.onLine) {
    return {requestSuccess: false};
  }

  // Create timeout signal
  options.signal = AbortSignal.timeout(timeout);

  // Attempt request
  let result;
  try {
    result = await fetch(url, options, cache);
  } catch (err) {
    return {requestSuccess: false};
  }

  return {requestSuccess: true, result: result};
}

export function generateGeoFencesDict(geoFences) {
  const geoFencesDict = {
    'fieldMapping': {},
    'regionMapping': {},
    '-1': {
      'rowPassEnabled': false,
    },
  };
  geoFences.forEach((geoFence) => {
    geoFencesDict[geoFence.properties.intelliblock_num.toString()] = geoFence.properties;

    // Add mapping of regions/fields to their child blocks
    if (!Object.prototype.hasOwnProperty.call(geoFencesDict['fieldMapping'], geoFence.properties.field_id)) {
      geoFencesDict['fieldMapping'][geoFence.properties.field_id] = [];
    }
    geoFencesDict['fieldMapping'][geoFence.properties.field_id].push({
      block_name: geoFence.properties.block_name,
      block_id: geoFence.properties.block_id,
      intelliblock_num: geoFence.properties.intelliblock_num,
    });

    if (!Object.prototype.hasOwnProperty.call(geoFencesDict['regionMapping'], geoFence.properties.region_id)) {
      geoFencesDict['regionMapping'][geoFence.properties.region_id] = [];
    }
    geoFencesDict['regionMapping'][geoFence.properties.region_id].push({
      block_name: geoFence.properties.block_name,
      block_id: geoFence.properties.block_id,
      intelliblock_num: geoFence.properties.intelliblock_num,
    });
  });

  return geoFencesDict;
}

export function searchFind(item, search) {
  if (search.length < 2) {
    // Minimum search length
    return true;
  }
  if (Array.isArray(item.props.children)) {
    for (let i = 0; i < item.props.children.length; i++) {
      if (typeof item.props.children[i] === 'object') {
        if (searchFind(item.props.children[i], search)) {
          return true;
        }
      }
    }
  } else if (typeof item.props.children === 'object') {
    return searchFind(item.props.children, search);
  } else if (typeof item.props.children === 'string') {
    return item.props.children.trim().toLowerCase().includes(search);
  } else if (item.props.hasOwnProperty('dangerouslySetInnerHTML')) {
    // Change script to not use dangerouslySetInnerHTML
    return (
      item.props.dangerouslySetInnerHTML.__html.trim().toLowerCase().includes(search) &&
      !(search.includes('<') || search.includes('>')) &&
      !(item.props.dangerouslySetInnerHTML.__html.trim().toLowerCase().includes('<br>') && search.includes('br'))
    );
  }
  return false;
}

export function arrayToggle(arr, item) {
  return arr.includes(item)
    ? arr.filter((i) => {
        return i !== item;
      })
    : [...arr, item];
}

export function sendGAEvent(eventName, itemName, contentType) {
  ReactGA.event(eventName, {
    category: 'engagement',
    label: itemName,
    content_type: contentType,
  });
}

export function sendGAPageview(page, forceGAEnabled = false) {
  if (forceGAEnabled) {
    initGA();
  }
  ReactGA.send({hitType: 'pageview', page: page});
}

// Enable and Disable Google Analytics object. If disabled functions will still run, analytics won't be submitted
export function initGA() {
  if (window.location.host == 'icserver.co') {
    ReactGA.initialize('G-1MMZ0GHQPW');
  } else {
    ReactGA.initialize('G-Y4M9FWF6SP');
  }
}

export function disableGA() {
  ReactGA.reset();
}

// Returns value units in meters
export function unitsLengthSubmitConversion(value, inputUnits) {
  // Returns value in km or m. inputUnits can be 'km', 'mi', 'm', 'ft'
  if (value == '') {
    return value;
  } else if (inputUnits == 'mi' || inputUnits == 'mph') {
    return value / KM_TO_MI;
  } else if (inputUnits == 'km' || inputUnits == 'kph') {
    return value;
  } else if (inputUnits == 'ft') {
    return parseFloat((value / M_TO_FT).toFixed(6));
  } else if (inputUnits == 'm') {
    return value;
  } else {
    console.error('inputUnits can be either "km", "mi", "m", "ft", "mph", "kph"');
  }
}

// Return value units specified in outputUnits
export function unitsLengthDisplayConversion(value, outputUnits) {
  // Returns value in OutputUnits. OutputUnits can be 'km', 'mi', 'm', 'ft'
  if (value == '') {
    return value;
  } else if (outputUnits == 'mi' || outputUnits == 'mph') {
    return parseFloat(value * KM_TO_MI);
  } else if (outputUnits == 'km' || outputUnits == 'kph') {
    return parseFloat(value);
  } else if (outputUnits == 'ft') {
    return parseFloat(value * M_TO_FT);
  } else if (outputUnits == 'm') {
    return parseFloat(value);
  } else {
    console.error('outputUnits can be either "km", "mi", "m", "ft", "mph", "kph"');
  }
}

/**
 * Function to convert area units
 *
 * @param {Number} value value to be converted
 * @param {String} outputUnits either 'ha', 'ac', 'meters2'
 * @param {String} inputUnits either 'ha', 'ac', 'meters2'
 * @return {Number} converted value
 */
export function unitsAreaConversion(value, outputUnits, inputUnits = 'meters2') {
  // Check if value is valid
  if (value == '' || value == null || value == undefined || typeof value != 'number') {
    return value;
  }

  // Check if outputUnits is valid
  if (!(outputUnits == 'ha' || outputUnits == 'ac' || outputUnits == 'meters2')) {
    console.error('unitsAreaConversion: output units are not valid, can only be "ha", "ac", "meters2"');
    return value;
  }

  // convert ha input
  if (inputUnits == 'ha') {
    if (outputUnits == 'ac') {
      return value * HA_TO_AC;
    } else if (outputUnits == 'meters2') {
      return value * HA_TO_METERS2;
    } else {
      return value; // already in ha
    }
  }

  // convert ac input
  else if (inputUnits == 'ac') {
    if (outputUnits == 'ha') {
      return value * AC_TO_HA;
    } else if (outputUnits == 'meters2') {
      return value * AC_TO_METERS2;
    } else {
      return value; // already in ac
    }
  }

  // convert meters2 input
  else if (inputUnits == 'meters2') {
    if (outputUnits == 'ha') {
      return value / HA_TO_METERS2;
    } else if (outputUnits == 'ac') {
      return value / AC_TO_METERS2;
    } else {
      return value; // already in meters2
    }
  } else {
    console.error('invalid inputUnits, can be "ha", "ac", "meters2"');
  }
}

export function formatTime(time) {
  const hours = Math.floor(time / 60);
  const min = Math.round(time - hours * 60);

  if (time >= 60) {
    return hours + ' h ' + min + ' min';
  } else if (time > 0) {
    return min + ' min';
  } else {
    return 0 + ' min';
  }
}

export function distanceBetweenPoints(lng, lat, lngPrev, latPrev) {
  // Calculate distance in meters between points for separating trips and bearing
  lng = parseFloat(lng);
  lat = parseFloat(lat);
  lngPrev = parseFloat(lngPrev);
  latPrev = parseFloat(latPrev);

  const x = latPrev * (Math.PI / 180);
  const y = lat * (Math.PI / 180);

  const dlat = (lat - latPrev) * (Math.PI / 180);
  const dlng = (lng - lngPrev) * (Math.PI / 180);
  const a = Math.pow(Math.sin(dlat / 2), 2) + Math.cos(x) * Math.cos(y) * Math.pow(Math.sin(dlng / 2), 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const dist = c * 6371 * 1000;
  return dist;
}

export function stringToArray(string, delimeter = ',') {
  const array = string.split(delimeter);
  const trimed = array.map((item) => {
    return item.trim();
  });
  const filtered = trimed.filter(Boolean);
  return filtered;
}

export function generateCsv(filename, csvHeaders, csvList) {
  // Construct the final csv by delimiting the data array
  let csv = csvHeaders.join(',') + '\n';
  csvList.forEach(function (row) {
    csv += row.join(',');
    csv += '\n';
  });

  // Create hidden element and click to download CSV
  const hiddenElement = document.createElement('a');
  hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
  hiddenElement.href = hiddenElement.href.replace(/#/g, '%23');
  hiddenElement.target = '_blank';
  hiddenElement.download = `${filename}.csv`;
  hiddenElement.click();
}

// ENUMS should prob be <Name>: <Number>. May change later
export const machineTypeMapping = {
  0: 'Not Assigned',
  1: 'Road Vehicle',
  2: 'Tractor',
  3: 'Trailer',
  4: 'ATV',
  5: 'Platform',
  6: 'Construction',
};

export const machineTypeReverseMapping = {
  'Not Assigned': 0,
  'Road Vehicle': 1,
  'Tractor': 2,
  'Trailer': 3,
  'ATV': 4,
  'Platform': 5,
  'Construction': 6,
};

export const rowApplicationTypeMapping = {
  0: 'Width',
  1: 'Limited',
  2: 'Rows',
};

export const roleValueToDisplayNameMapping = {
  'ic_admin': 'IC Admin',
  'customer_admin': 'Admin',
  'user': 'User',
};

export const accessLevels = Object.freeze({
  user: 0,
  customer_admin: 1,
  ic_admin: 2,
});

export const modalBoxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '60%',
  width: {
    xs: '90%',
    sm: '60%',
  },
  maxHeight: '100%',
  height: '90vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
  overflow: 'scroll',
};

export const hideCell = {
  display: {
    xs: 'none',
    sm: 'revert',
  },
};

export const squareButton = {
  minWidth: 0,
  paddingLeft: 1.8,
  paddingRight: 1.8,
  paddingTop: 1.2,
  paddingBottom: 1.2,
};

export const customTheme = {
  components: {
    MuiButton: {
      variants: [
        {
          props: {variant: 'ic-button', color: 'primary'},
          style: {
            textTransform: 'none',
            color: '#fff',
            'backgroundColor': '#4e73df',
            'borderColor': '#4e73df',
            '&:hover': {
              'backgroundColor': '#2e59d9',
              'borderColor': '#2653d4',
            },
            '&:focus': {
              'boxShadow': `0 0 0 0.2rem rgba(105, 136, 228, .5)`,
              'outline': '0',
            },
          },
        },
        {
          props: {variant: 'ic-button', color: 'secondary'},
          style: {
            textTransform: 'none',
            color: '#fff',
            'backgroundColor': '#858796',
            'borderColor': '#858796',
            '&:hover': {
              'backgroundColor': '#717384',
              'borderColor': '#6b6d7d',
            },
            '&:focus': {
              'boxShadow': `0 0 0 0.2rem rgba(151, 153, 166, .5)`,
              'outline': '0',
            },
          },
        },
        {
          props: {variant: 'ic-button', color: 'danger'},
          style: {
            textTransform: 'none',
            color: '#fff',
            'backgroundColor': '#e74a3b',
            'borderColor': '#e74a3b',
            '&:hover': {
              'backgroundColor': '#e02d1b',
              'borderColor': '#d52a1a',
            },
            '&:focus': {
              'boxShadow': `0 0 0 0.2rem rgba(235, 101, 88, .5)`,
              'outline': '0',
            },
          },
        },
        {
          props: {variant: 'ic-button', color: 'success'},
          style: {
            textTransform: 'none',
            color: '#fff',
            'backgroundColor': '#1cc88a',
            'borderColor': '#1cc88a',
            '&:hover': {
              'backgroundColor': '#218838',
              'borderColor': '#1e7e34',
            },
            '&:focus': {
              'boxShadow': `0 0 0 0.2rem rgba(78, 115, 223, .25)`,
              'outline': '0',
            },
          },
        },
      ],
    },
  },
  typography: {
    fontFamily:
      '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif',
  },
};

export const intRegex = /^[0-9]*$/;
export const floatRegex = /^(\d*([.]\d*)?|[.]\d+)$/;
export const dollarRegex = /^(\d+([.]\d{0,2})?|[.]\d{0,2})$/;
export const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const decimalLimitRegex = (decimalLimit) => {
  let re;
  if (decimalLimit < 1) {
    re = /^[0-9]*$/;
  } else {
    re = new RegExp(String.raw`^(\d+([.]\d{0,${decimalLimit}})?|[.]\d{0,${decimalLimit}})$`);
  }

  return re;
};

export const customChartProperties = {
  titleFontSize: 18,
  legendFontSize: 14,
  axisTitleFontSize: 12,
  axisTitleFontWeight: 600,
  xLabelFontSize: 10,
  lineHeight: 0.5,
  autoSkipLimit: 20,
  colorGreen: '#00e676',
  colorYellow: '#ffc107',
  colorBlue: '#2979ff',
  colorRed: '#ff5252',
  colorGrey: '#c4c4c4',
  colorLightGrey: '#D3D3D3',
  colorClear: '#F4FAFC',
  colorLimeGreen: '#00ff00',
  colorOffYellow: '#fff68f',
  colorWarning: '#F6C23E',
  colorDanger: '#E74A3B',
  colorOrange: '#ff8000',
  colorBlack: '#000000',
  colorWhite: '#ffffff',
};

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function deepCopy(obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    const newArray = [];
    for (const item of obj) {
      newArray.push(deepCopy(item));
    }
    return newArray;
  }

  const newObj = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      newObj[key] = deepCopy(obj[key]);
    }
  }
  return newObj;
}

export function formatString(inputString) {
  // Split the input string into an array of words
  const words = inputString.split('_');

  // Capitalize each word
  const capitalizedWords = words.map(function (word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  // Join the words back into a single string
  const formattedString = capitalizedWords.join(' ');

  return formattedString;
}

export function sortVehicleNamesHelper(vehicleAName, vehicleBName) {
  // Sort serial numbers to back
  const vehicleAIsDeviceSerial =
    ['G9', 'GA'].includes(vehicleAName.slice(0, 2)) && vehicleAName.length == 12 && /^[a-zA-Z0-9]+$/.test(vehicleAName);
  const vehicleBIsDeviceSerial =
    ['G9', 'GA'].includes(vehicleBName.slice(0, 2)) && vehicleBName.length == 12 && /^[a-zA-Z0-9]+$/.test(vehicleBName);

  if (vehicleAIsDeviceSerial && !vehicleBIsDeviceSerial) {
    return 1;
  } else if (!vehicleAIsDeviceSerial && vehicleBIsDeviceSerial) {
    return -1;
  } else {
    return vehicleAName.localeCompare(vehicleBName);
  }
}

export function trimObjectStrings(inputObj) {
  const obj = cloneDeep(inputObj);

  if (typeof obj === 'object' && obj !== null) {
    for (const key in obj) {
      if (typeof obj[key] === 'string') {
        obj[key] = obj[key].trim();
      }
    }
  }

  return obj;
}

export async function* streamAsyncIterator(stream) {
  // Get a lock on the stream
  const reader = stream.getReader();

  try {
    while (true) {
      // Read from the stream
      const {done, value} = await reader.read();
      // Exit if we're done
      if (done) return;
      // Else yield the chunk
      yield value;
    }
  } finally {
    reader.releaseLock();
  }
}

// Firefox and safari with input type 'number' do not prevent non-numbers from being entered,
// but also do not trigger an onChange event for non-numbers
// Use function in combination with input type 'text' to trigger onChange event for all characters
export function handleAllowOnlyNumbers(event) {
  const newKeyValue = event.target.value + event.key;
  if (event.key.length == 1 && !event.altKey && !event.ctrlKey && !newKeyValue.match(floatRegex)) {
    event.preventDefault();
  }
}

export function polygonArea(vertices) {
  let area = 0;
  for (let i = 0; i < vertices.length; i++) {
    const j = (i + 1) % vertices.length;
    area += vertices[i][0] * vertices[j][1];
    area -= vertices[j][0] * vertices[i][1];
  }
  return area / 2;
}

export function roundDecimalPlaces(inputValue, decimalPlaces) {
  let value = inputValue;
  if (typeof value == 'string') {
    value = parseFloat(value.replace(/,/g, ''));
  }

  const roundingval = 100 * decimalPlaces;
  return (Math.round(value * roundingval) / roundingval).toFixed(decimalPlaces);
}

/**
 * Determine if the geotab device is a BeSol device
 *
 * @param {Object} vehicleDocument Fauna vehicle document
 * @param {Object} activeDevices Dictionary of geotab active devices
 * @return {boolean} boolean
 */
export function checkIfDeviceIsBesol(vehicleDocument, activeDevices) {
  let beSolType = false;
  if (vehicleDocument?.geotabDevice?.serialNumber) {
    // BeWhere Serial in lower case
    const bewhereSerialRegex = /btsae[0-9]{14}/g;
    const geotabComment = activeDevices.geotab[vehicleDocument?.geotabDevice?.id]?.comment;
    if (geotabComment) {
      // convert comment to all lowercase to avoid missing serial due to case sensitivity
      const beWhereSerialFound = geotabComment.toLowerCase().match(bewhereSerialRegex);
      if (beWhereSerialFound) {
        beSolType = true;
      }
    }
  }
  return beSolType;
}

export function unionLabelsAndDataZones(opsData, compareByOpsData, excludedKeys = []) {
  const opsDataCopy = deepCopy(opsData);
  const compareByOpsDataCopy = deepCopy(compareByOpsData);
  // Do for Vehicles
  const opsDataKeys = Object.keys(opsDataCopy);
  const compareByOpsDataKeys = Object.keys(compareByOpsDataCopy);
  opsDataKeys.forEach((opsDataKey) => {
    if (!compareByOpsDataKeys.includes(opsDataKey)) {
      const objZeros = deepCopy(opsDataCopy[opsDataKey]);
      Object.keys(objZeros).forEach((innerObjKey) => {
        if (!excludedKeys.includes(innerObjKey)) {
          objZeros[innerObjKey] = 0;
        }
      });
      compareByOpsDataCopy[opsDataKey] = objZeros;
    }
  });
  compareByOpsDataKeys.forEach((opsDataKey) => {
    if (!opsDataKeys.includes(opsDataKey)) {
      const objZeros = deepCopy(compareByOpsDataCopy[opsDataKey]);
      Object.keys(objZeros).forEach((innerObjKey) => {
        if (!excludedKeys.includes(innerObjKey)) {
          objZeros[innerObjKey] = 0;
        }
      });
      opsDataCopy[opsDataKey] = objZeros;
    }
  });

  return [opsDataCopy, compareByOpsDataCopy];
}

export function unionLabelsAndDataTasks(opsData, compareByOpsData, excludedKeys = []) {
  const opsDataCopy = deepCopy(opsData);
  const compareByOpsDataCopy = deepCopy(compareByOpsData);
  // Do for Vehicles
  const opsDataKeys = Object.keys(opsDataCopy);
  const compareByOpsDataKeys = Object.keys(compareByOpsDataCopy);

  opsDataKeys.forEach((opsDataKey) => {
    if (!compareByOpsDataKeys.includes(opsDataKey)) {
      const objZeros = deepCopy(opsDataCopy[opsDataKey]);
      // In this case innerObjKey is the zone key
      Object.keys(objZeros).forEach((innerObjKey) => {
        Object.keys(objZeros[innerObjKey]).forEach((secondInnerObjKey) => {
          Object.keys(objZeros[innerObjKey][secondInnerObjKey]).forEach((thirdInnerObjKey) => {
            objZeros[innerObjKey][secondInnerObjKey][thirdInnerObjKey] = 0;
          });
        });
      });
      compareByOpsDataCopy[opsDataKey] = objZeros;
    } else {
      const objZeros = deepCopy(opsDataCopy[opsDataKey]);
      const compareByObjZeros = deepCopy(compareByOpsDataCopy[opsDataKey]);
      Object.keys(objZeros).forEach((innerObjKey) => {
        // Second inner obj key is the zone name
        Object.keys(objZeros[innerObjKey]).forEach((secondInnerObjKey) => {
          // If the task exists on the compare by but not for this specific zone name
          if (!Object.keys(compareByObjZeros[innerObjKey]).includes(secondInnerObjKey)) {
            // We create that zone on this object and then set it all to 0
            compareByObjZeros[innerObjKey][secondInnerObjKey] = {};
            Object.keys(objZeros[innerObjKey][secondInnerObjKey]).forEach((thirdInnerObjKey) => {
              compareByObjZeros[innerObjKey][secondInnerObjKey][thirdInnerObjKey] = 0;
            });
          }
        });
        compareByOpsDataCopy[opsDataKey] = compareByObjZeros;
      });
    }
  });

  compareByOpsDataKeys.forEach((opsDataKey) => {
    if (!opsDataKeys.includes(opsDataKey)) {
      const objZeros = deepCopy(compareByOpsDataCopy[opsDataKey]);
      // In this case innerObjKey is the zone key
      Object.keys(objZeros).forEach((innerObjKey) => {
        Object.keys(objZeros[innerObjKey]).forEach((secondInnerObjKey) => {
          Object.keys(objZeros[innerObjKey][secondInnerObjKey]).forEach((thirdInnerObjKey) => {
            objZeros[innerObjKey][secondInnerObjKey][thirdInnerObjKey] = 0;
          });
        });
      });
      opsDataCopy[opsDataKey] = objZeros;
    } else {
      const objZeros = deepCopy(opsDataCopy[opsDataKey]);
      const compareByObjZeros = deepCopy(compareByOpsDataCopy[opsDataKey]);
      Object.keys(compareByObjZeros).forEach((innerObjKey) => {
        // Second inner obj key is the zone name
        Object.keys(compareByObjZeros[innerObjKey]).forEach((secondInnerObjKey) => {
          // If the task exists on the og set but not for this specific zone name
          if (!Object.keys(objZeros[innerObjKey]).includes(secondInnerObjKey)) {
            // We create that zone on this object and then set it all to 0
            objZeros[innerObjKey][secondInnerObjKey] = {};
            Object.keys(compareByObjZeros[innerObjKey][secondInnerObjKey]).forEach((thirdInnerObjKey) => {
              objZeros[innerObjKey][secondInnerObjKey][thirdInnerObjKey] = 0;
            });
          }
        });
        opsDataCopy[opsDataKey] = objZeros;
      });
    }
  });

  return [opsDataCopy, compareByOpsDataCopy];
}

export function unionLabelsAndDataVehicles(opsData, compareByOpsData, excludedKeys = []) {
  const opsDataCopy = deepCopy(opsData);
  const compareByOpsDataCopy = deepCopy(compareByOpsData);

  // Do for Vehicles
  const opsDataKeys = Object.keys(opsDataCopy);
  const compareByOpsDataKeys = Object.keys(compareByOpsDataCopy);

  opsDataKeys.forEach((opsDataKey) => {
    if (!compareByOpsDataKeys.includes(opsDataKey)) {
      const objZeros = deepCopy(opsDataCopy[opsDataKey]);
      Object.keys(objZeros).forEach((innerObjKey) => {
        if (!excludedKeys.includes(innerObjKey)) {
          objZeros[innerObjKey] = 0;
        }
      });
      compareByOpsDataCopy[opsDataKey] = objZeros;
    }
  });
  compareByOpsDataKeys.forEach((opsDataKey) => {
    if (!opsDataKeys.includes(opsDataKey)) {
      const objZeros = deepCopy(compareByOpsDataCopy[opsDataKey]);
      Object.keys(objZeros).forEach((innerObjKey) => {
        if (!excludedKeys.includes(innerObjKey)) {
          objZeros[innerObjKey] = 0;
        }
      });
      opsDataCopy[opsDataKey] = objZeros;
    }
  });

  return [opsDataCopy, compareByOpsDataCopy];
}

export function countDecimals(number) {
  if (Math.floor(number.valueOf()) === number.valueOf()) return 0;

  const str = number.toString();
  if (str.indexOf('.') !== -1 && str.indexOf('-') !== -1) {
    return str.split('-')[1] || 0;
  } else if (str.indexOf('.') !== -1) {
    return str.split('.')[1].length || 0;
  }
  return str.split('-')[1] || 0;
}
