import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {CoverPageCard} from '../components/CoverPageCard';
import {Framework} from '../components/Framework';
import {sendGAPageview, fetchPostAuthSafe} from '../app/utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import {IntelliSearchSelect} from '../components/IntelliSearchSelect';
import {
  setTaskActive,
  setSelectedVehicle,
  setSelectedTask,
  updateTasks,
  updateImplements,
  updateCabviewVehicles,
} from './Cabview/cabviewSlice';

function Landing() {
  const dispatch = useDispatch();

  const authenticationStatus = useSelector((state) => {
    return state.app.authenticationStatus;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const vehicleData = useSelector((state) => {
    return state.cabview.vehicles;
  });
  const taskData = useSelector((state) => {
    return state.cabview.tasks;
  });
  const implementData = useSelector((state) => {
    return state.cabview.implements;
  });
  const selectedTask = useSelector((state) => {
    return state.cabview.selectedTask;
  });
  const selectedVehicle = useSelector((state) => {
    return state.cabview.selectedVehicle;
  });
  const taskActive = useSelector((state) => {
    return state.cabview.taskActive;
  });
  const [scanResultsMsg, setScanResultsMsg] = useState();
  const [error, setError] = useState(false);
  const [submitWarning, setSubmitWarning] = useState(false);
  const [permanentTaskActive, setPermanentTaskActive] = useState(false);
  const [selectOptions, setSelectOptions] = useState({
    vehicles: [],
    tasks: [],
  });

  useEffect(async () => {
    document.title = 'IntelliCulture | Landing';
    sendGAPageview(document.title);
    await initVehicleSelect();
  }, []);

  useEffect(() => {
    // Update selector options if new data comes in
    updateSelectOptions();
  }, [vehicleData, taskData, selectedVehicle, selectedTask]);

  useEffect(() => {
    // Select permanent task
    forcePermanentTask();
  }, [selectedVehicle, taskActive]);

  async function initVehicleSelect() {
    // Get init cabview data package
    const initDataResponse = await fetch('/cabview/getLandingInitData', {cache: 'no-store'});
    const initData = await initDataResponse.json();

    // Set message of scanResults
    setScanResultsMsg(initData.scanResults.msg);

    // Update store with data
    dispatch(updateCabviewVehicles(initData.vehicleDataPack));
    dispatch(updateTasks(initData.taskConfigs));
    dispatch(updateImplements(initData.implements));

    // Check if task is being tracked
    if (initData.trackingPack.vehicleSN != '' && initData.trackingPack.taskId != '') {
      dispatch(setSelectedVehicle(initData.trackingPack.vehicleSN));
      dispatch(setSelectedTask(initData.trackingPack.taskId));
      dispatch(setTaskActive(true));
    } else {
      let vehicleSN = '';
      let taskId = '';
      // Attempt to find the selected vehicle if its available in cabview
      if (initData.selectionPack.vehicleSN != '') {
        vehicleSN = initData.selectionPack.vehicleSN;

        let implementObj;
        const vehicleObj = initData.vehicleDataPack.find((vehicle) => {
          return vehicle.serialNumber == vehicleSN;
        });
        if (vehicleObj) {
          implementObj = initData.implements.find((implement) => {
            return implement.serialNumber == vehicleObj.linkedImplementSN;
          });
        }

        // Task
        if (implementObj && implementObj.linkedTaskId != '') {
          taskId = implementObj.linkedTaskId;
        } else if (vehicleObj && vehicleObj.linkedTaskId != '') {
          taskId = vehicleObj.linkedTaskId;
        }
      }

      if (taskId == '' && initData.selectionPack.taskId != '') {
        taskId = initData.selectionPack.taskId;
      }

      if (customerSettings.landing.scanAutoStartEnabled && !initData.selectionPack.vehicleScanned) {
        selectVehicle('');
      } else {
        dispatch(setSelectedVehicle(vehicleSN));
      }
      if (customerSettings.landing.scanAutoStartEnabled && !initData.selectionPack.implementScanned) {
        selectTask('');
      } else {
        dispatch(setSelectedTask(taskId));
      }
      dispatch(setTaskActive(false));
    }
  }

  function updateSelectOptions() {
    // Update selector options based on data
    const vehicleOptions = [];
    const taskOptions = [];
    for (let i = 0; i < vehicleData.length; i++) {
      vehicleOptions.push({value: vehicleData[i].serialNumber, label: vehicleData[i].name});
    }
    for (let i = 0; i < taskData.length; i++) {
      taskOptions.push({value: taskData[i].taskId, label: taskData[i].name});
    }
    setSelectOptions({
      vehicles: vehicleOptions,
      tasks: taskOptions,
    });
  }

  async function toggleStartTask(event) {
    event.preventDefault();

    if (!taskActive) {
      // Set warning if vehicle or task empty
      if (selectedTask === '' || selectedVehicle === '') {
        setSubmitWarning(true);
        return;
      }

      const response = await fetch(
        `/cabview/landingStartTracking?vehicleSN=${selectedVehicle}&taskId=${selectedTask}`,
        {cache: 'no-store'}
      );

      try {
        const result = await response.json();
        setSubmitWarning(false);
        if (typeof result.success != 'undefined' && result.success === true) {
          dispatch(setTaskActive(true));
        } else {
          setError(true);
        }
      } catch (error) {
        setError(true);
        console.info('Failed to landingStartTracking', error);
      }
    } else {
      await fetch('/cabview/landingStopTracking', {cache: 'no-store'});
      dispatch(setTaskActive(false));
      setScanResultsMsg();
      if (customerSettings.landing.scanAutoStartEnabled) {
        selectVehicle('');
        selectTask('');
      }
    }
  }

  async function selectVehicle(vehicleSN) {
    dispatch(setSelectedVehicle(vehicleSN));
    const options = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
    };
    await fetchPostAuthSafe(
      `/cabview/landingSelectVehicle?vehicleSN=${vehicleSN}`,
      options,
      userSettings.username,
      userSettings.databaseName
    );
  }

  async function selectTask(taskId) {
    dispatch(setSelectedTask(taskId));
    const options = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
    };
    await fetchPostAuthSafe(
      `/cabview/landingSelectTask?taskId=${taskId}`,
      options,
      userSettings.username,
      userSettings.databaseName
    );
  }

  function forcePermanentTask() {
    // Enable task select
    setPermanentTaskActive(false);

    // Find vehicle object given serialNumber
    const vehicleObj = vehicleData.find((vehicle) => {
      return vehicle.serialNumber == selectedVehicle;
    });
    if (vehicleObj) {
      if (vehicleObj.linkedImplementSN) {
        // Find implement object given serialNumber
        const implementObj = implementData.find((implement) => {
          return implement.serialNumber == vehicleObj.linkedImplementSN;
        });
        if (implementObj && implementObj.linkedTaskId) {
          // Find task object given id
          const taskObj = taskData.find((task) => {
            return task.taskId == implementObj.linkedTaskId;
          });
          if (taskObj && typeof taskObj.archived != undefined && taskObj.archived === false) {
            dispatch(setSelectedTask(implementObj.linkedTaskId));
            setPermanentTaskActive(true);
          }
        }
      } else if (vehicleObj.linkedTaskId) {
        // Find task object given id
        const taskObj = taskData.find((task) => {
          return task.taskId == vehicleObj.linkedTaskId;
        });
        if (taskObj && typeof taskObj.archived != undefined && taskObj.archived === false) {
          dispatch(setSelectedTask(vehicleObj.linkedTaskId));
          setPermanentTaskActive(true);
        }
      }
    }
  }

  const customSelectStyle = {
    menuList: (base) => {
      return {
        ...base,
        height: 200,
      };
    },
  };

  return (
    <Framework activePage='landing' pageName='' topBarSearch={false}>
      <div className='tab-wrapper'>
        <CoverPageCard>
          <div className='text-center align-self-stretch'>
            {scanResultsMsg ? (
              <div className='col-12 align-self-stretch'>
                <div className='h2 text-gray-900 mb-4 mt-4 align-self-stretch'>{scanResultsMsg}</div>
              </div>
            ) : (
              <div className='col-12 align-self-stretch'>
                <div className='h2 text-gray-900 mb-6 mt-6 align-self-stretch'>Welcome to IntelliCulture</div>
                {customerSettings.landing.scanAutoStartEnabled ? (
                  <div className='h5 text-gray-900 mb-3 mt-4 align-self-stretch'>Please scan your QR codes</div>
                ) : (
                  <div className='h5 text-gray-900 mb-3 mt-4 align-self-stretch'>Please enter your activity</div>
                )}
              </div>
            )}
          </div>
          <div>
            <div className='text-center align-self-stretch'>
              <h6 className='h5 text-gray-900 mb-2 mt-2'>
                Vehicle
                {selectedVehicle == '' ? (
                  <FontAwesomeIcon icon='far fa-circle-xmark' className='ml-1' style={{color: '#FF0004'}} />
                ) : (
                  <FontAwesomeIcon icon='far fa-check-circle' className='ml-1' style={{color: '#00A41E'}} />
                )}
              </h6>
            </div>
            <div className='row pb-2 px-2'>
              <IntelliSearchSelect
                id='landing-vehicle-select'
                name={'landing-vehicle-select'}
                disabled={taskActive || customerSettings.landing.scanAutoStartEnabled}
                value={selectedVehicle}
                options={selectOptions.vehicles}
                onChange={(value) => {
                  return selectVehicle(value);
                }}
              />
            </div>
            <div className='text-center'>
              <h6 className='h5 text-gray-900 mb-2 mt-2'>
                Task
                {selectedTask == '' ? (
                  <FontAwesomeIcon icon='far fa-circle-xmark' className='ml-1' style={{color: '#FF0004'}} />
                ) : (
                  <FontAwesomeIcon icon='far fa-check-circle' className='ml-1' style={{color: '#00A41E'}} />
                )}
              </h6>
            </div>
            <div className='row pb-2 px-2'>
              <IntelliSearchSelect
                id='landing-task-select'
                name={'landing-task-select'}
                disabled={permanentTaskActive || taskActive || customerSettings.landing.scanAutoStartEnabled}
                options={selectOptions.tasks}
                onChange={(value) => {
                  return selectTask(value);
                }}
                value={selectedTask}
              />
            </div>
            <div className='col-12 pb-3 align-self-center'>
              {submitWarning && (
                <div className='text-s text-danger font-italic'>Please select both vehicle and task.</div>
              )}
              {error && <div className='text-s text-danger font-italic'>Error Starting Task</div>}
            </div>

            {!taskActive ? (
              <button
                type='button'
                className='btn btn-success col-12'
                onClick={toggleStartTask}
                disabled={selectedVehicle == '' || selectedTask == '' || customerSettings.landing.scanAutoStartEnabled}
              >
                Submit Task <FontAwesomeIcon icon='fas fa-play' />
              </button>
            ) : (
              <button type='button' className='btn btn-primary col-12' onClick={toggleStartTask}>
                <FontAwesomeIcon icon='fas fa-arrow-left' /> Next Task
              </button>
            )}
          </div>
          <hr></hr>
          <div className='text-center'>
            {/* Nav Item - CabView */}
            {authenticationStatus.cabviewEnabled && customerSettings.landing.landingPageRedirects.cabview && (
              <Link className='btn btn-primary small m-1' style={{width: 'calc(50% - .5rem)'}} to='/cabview'>
                <i className='fas fa-fw fa-tractor'></i> CabView
              </Link>
            )}
            {/* Nav Item - CropView */}
            {authenticationStatus.cropviewEnabled && customerSettings.landing.landingPageRedirects.cropview && (
              <Link className='btn btn-primary small m-1' style={{width: 'calc(50% - .5rem)'}} to='/cropview'>
                <i className='fas fa-fw fa-seedling'></i> CropView
              </Link>
            )}
            {/* Nav Item - ShopView */}
            {authenticationStatus.shopviewEnabled && customerSettings.landing.landingPageRedirects.shopview && (
              <Link className='btn btn-primary small m-1' style={{width: 'calc(50% - .5rem)'}} to='/shopview'>
                <i className='fas fa-fw fa-wrench'></i> ShopView
              </Link>
            )}
            {/* Nav Item - Inspection Report */}
            {authenticationStatus.inspectionEnabled && customerSettings.landing.landingPageRedirects.inspection && (
              <Link className='btn btn-primary small m-1' style={{width: 'calc(50% - .5rem)'}} to='/inspectionForm'>
                <i className='fas fa-fw fa-list-check'></i> Inspection
              </Link>
            )}
            {/* Nav Item - ZoneInfo */}
            {authenticationStatus.cropviewEnabled &&
              customerSettings.general.geoFencesProvided &&
              authenticationStatus.zoneInfoEnabled &&
              customerSettings.landing.landingPageRedirects.zoneInfo && (
                <Link className='btn btn-primary small m-1' style={{width: 'calc(50% - .5rem)'}} to='/mapview'>
                  <i className='fas fa-fw fa-map-location-dot'></i> MapView
                </Link>
              )}
          </div>
        </CoverPageCard>
      </div>
    </Framework>
  );
}

export {Landing};
