import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {DateTime} from 'luxon';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CircularProgress from '@mui/material/CircularProgress';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import AddIcon from '@mui/icons-material/Add';
import ChatIcon from '@mui/icons-material/Chat';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import isEqual from 'lodash/isEqual';

import {
  ModalSubmitting,
  ViewOnlyText,
  CollapsableSection,
  GeneralSearchSelect,
} from '../../../../components/GeneralComponents';
import {Tabs as ICTabs} from '../../../../components/Tabs';
import {TabContent} from '../../../../components/TabContent';

import {getCustomerAndUserInfo, getGlobalVehicleData} from '../../../../appSlice';
import {updateLoading} from './vehicleInfoSlice';
import {triggerGetSettingsData, getUpdatedVehicles} from '../../settingsSlice';
import {
  machineTypeMapping,
  unitsLengthDisplayConversion,
  unitsLengthSubmitConversion,
  fetchPostAuthSafe,
  accessLevels,
  trimObjectStrings,
  handleAllowOnlyNumbers,
  decimalLimitRegex,
} from '../../../../app/utils';

import {Switch, Select, MenuItem} from '@mui/material';

function VehicleEditModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  $('[data-toggle="tooltip"]').tooltip();

  const unitsLengthSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsLength;
  });
  const taskConfigDict = useSelector((state) => {
    return state.settings.taskConfigDict;
  });
  const implementSNDict = useSelector((state) => {
    return state.settings.implementSNDict;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const vehicleData = useSelector((state) => {
    return state.vehicleinfo.vehicleData;
  });
  const activeDevices = useSelector((state) => {
    return state.settings.activeDevices;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const deviceStatuses = useSelector((state) => {
    return state.vehicleinfo.deviceStatuses;
  });
  const btHubs = useSelector((state) => {
    return state.settings.btHubs;
  });
  const vehicleLabelsDict = useSelector((state) => {
    return state.settings.vehicleLabels;
  });
  const vehicleLabelsByVehicleSN = useSelector((state) => {
    return state.settings.vehicleLabelsByVehicleSN;
  });
  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });

  const [entryVehicle, setEntryVehicle] = useState(props.entryVehicle);
  const [editVehicle, setEditVehicle] = useState(props.editVehicle);
  const [submitStatus, setSubmitStatus] = useState('pending');
  const [updateVehicleStatus, setUpdateVehicleStatus] = useState(false);
  // submitStatus:  "pending" : Filling form
  //                "submitting" : Submitting
  //                "success" : Submission Completed
  //                "error" : Error submission
  const [modalError, setModalError] = useState('');
  const [showSwapDeviceWarning, setShowSwapDeviceWarning] = useState(false);
  const [deviceSwapTargetDeviceId, setDeviceSwapTargetDeviceId] = useState('');
  const [deviceSwapTargetVehicleSN, setDeviceSwapTargetVehicleSN] = useState('');
  const [deviceSwapTargetName, setDeviceSwapTargetName] = useState('');
  const [enableVehicleNameEdit, setEnableVehicleNameEdit] = useState(false);
  const [enableDeviceEdit, setEnableDeviceEdit] = useState(false);
  const [warningBubbleVisible, setWarningBubbleVisible] = useState(false);
  const [geotabEditTab, setGeotabEditTab] = useState('nameChange');
  const [btHubInputEnabled, setBtHubInputEnabled] = useState(false);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const [toggleConfigureGroup, setToggleConfigureGroup] = useState(false);

  const displayedTable = props.displayedTable;
  const editModalOpen = props.editModalOpen;

  useEffect(() => {
    const entryVehicleIn = props.entryVehicle;
    if (entryVehicleIn.machineType == 3) {
      // Trailer type
      entryVehicleIn.engineHours = '';
      entryVehicleIn.odometer = '';
    }
    // Reset State of Edit Modal
    setSubmitStatus('pending');
    setGeotabEditTab('nameChange');
    setShowSwapDeviceWarning(false);
    setEntryVehicle(entryVehicleIn);
    setEditVehicle(props.editVehicle);
    setUpdateVehicleStatus(false);
    setDeviceSwapTargetDeviceId('');
    setDeviceSwapTargetVehicleSN('');
    setDeviceSwapTargetName('');
    setEnableVehicleNameEdit(false);
    setEnableDeviceEdit(false);
    setWarningBubbleVisible(false);
    setModalError('');

    if (props.editVehicle.btHubId) {
      setBtHubInputEnabled(true);
    } else {
      setBtHubInputEnabled(false);
    }
  }, [props.editVehicle, props.entryVehicle]);

  const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
  };
  const attributeBoxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: smallScreen ? '100%' : '80vw',
    maxWidth: '800px',
    maxHeight: smallScreen ? '100%' : 'auto',
    minHeight: '480px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    display: 'flex',
    overflowY: 'auto',
    p: 2,
  };

  function serialNumberToVehicleMapping(serialNums) {
    const result = {'': 'Unassigned', undefined: 'Unassigned'};
    serialNums.forEach((sn) => {
      result[sn.serialNumber] = sn.name;
    });
    return result;
  }

  function renderGeotabTabs() {
    function handleGeotabEditTabChange(e, value) {
      setWarningBubbleVisible(true);
      setGeotabEditTab(value);
      setEditVehicle({...editVehicle, geotabDeviceSerial: props.editVehicle.geotabDeviceSerial});
      if (value == 'nameChange') {
        setEnableVehicleNameEdit(true);
      } else if (value == 'add') {
        setEnableDeviceEdit(true);
        setEnableVehicleNameEdit(false);
      } else if (value == 'unplug') {
        setEnableVehicleNameEdit(false);
      }

      setDeviceSwapTargetDeviceId('');
      setDeviceSwapTargetVehicleSN('');
    }

    if (props.editVehicle.geotabDeviceSerial != '') {
      return (
        <Tabs
          sx={{width: '100%', fontSize: '8px'}}
          variant='fullWidth'
          value={geotabEditTab}
          onChange={handleGeotabEditTabChange}
          centered
        >
          <Tab icon={<ChatIcon />} value='nameChange' label='Change Name' />
          {/* <Tab icon={<SwapHorizIcon />} value='add' label='Change Device' />*/}
          <Tab icon={<PowerOffIcon />} value='unplug' label='Unplug Device' />
        </Tabs>
      );
    } else {
      return (
        <Tabs
          sx={{width: '100%'}}
          variant='fullWidth'
          value={geotabEditTab}
          onChange={handleGeotabEditTabChange}
          centered
        >
          <Tab icon={<ChatIcon />} value='nameChange' label='Change Name' />
          <Tab icon={<AddIcon />} value='add' label='Add device' />
        </Tabs>
      );
    }
  }

  function renderGeotabEditPanel(deviceSerialOptions, deviceSerialMapping, vehicleHasGeotabDevice) {
    return (
      <React.Fragment>
        {renderGeotabTabs()}

        {geotabEditTab == 'add' && (
          <React.Fragment>
            <div className={showSwapDeviceWarning && props.editVehicle.geotabDeviceSerial ? 'col-sm-4' : 'col-sm-6'}>
              <label className='mb-0 mt-2'>
                <strong>Currently Assigned to: </strong>
              </label>
              <div className='mb-0 mt-2'>
                {typeof deviceSerialMapping[editVehicle.geotabDeviceSerial] != 'undefined'
                  ? deviceSerialMapping[editVehicle.geotabDeviceSerial]
                  : 'Unassigned'}
              </div>
            </div>
            <div className='col-sm-6'>
              <label className='mb-0 mt-2'>
                <strong>Device: </strong>
              </label>
              <Autocomplete
                freeSolo
                id='edit-geotab-device-serial'
                options={deviceSerialOptions}
                getOptionLabel={(option) => {
                  return option?.serial;
                }}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.serial}>
                      {option.label}
                    </li>
                  );
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      style={{textAlign: 'center'}}
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        type: 'search',
                      }}
                    />
                  );
                }}
                size='small'
                inputValue={editVehicle.geotabDeviceSerial}
                onInputChange={(e, value) => {
                  setEditVehicle({...editVehicle, geotabDeviceSerial: value});
                  checkIfDeviceSerialAssigned(editVehicle.geotabDeviceId, value);
                }}
              />
            </div>

            {showSwapDeviceWarning && vehicleHasGeotabDevice && (
              <div className='col-sm-2 d-flex'>
                <div className='align-self-end'>
                  <label className='mb-0 mt-2'>
                    <strong>Swap Device: </strong>
                  </label>
                  <Checkbox
                    key={editVehicle.swapDevice}
                    checked={editVehicle?.swapDevice}
                    onChange={(e) => {
                      setEditVehicle({...editVehicle, swapDevice: e.target.checked});
                    }}
                  />
                </div>
              </div>
            )}
            {showSwapDeviceWarning && !editVehicle.swapDevice && props.editVehicle.geotabDeviceSerial && (
              <div className='col-sm-12 text-warning'>
                Warning: This Device is currently assigned to another vehicle. Would you like to swap devices?
              </div>
            )}
          </React.Fragment>
        )}
        {geotabEditTab == 'nameChange' && (
          <React.Fragment>
            <div className='col-sm-3'></div>
            <div className='col-sm-6'>
              <label className='mb-0 mt-2'>
                <strong>Vehicle Name </strong>
              </label>
              <input
                name='name'
                className='form-control'
                value={editVehicle.name}
                onChange={(e) => {
                  setEditVehicle({...editVehicle, name: e.target.value});
                }}
              />
            </div>
            <div className='col-sm-3'></div>
          </React.Fragment>
        )}
        {geotabEditTab == 'unplug' && (
          <React.Fragment>
            <div style={{marginTop: '5px'}} className='col-sm-3'></div>
            <div style={{marginTop: '5px'}} className='col-sm-6'>
              {editVehicle.geotabDeviceSerial == '' ? (
                'Unplugging device with serial number: '
              ) : (
                <button
                  type='button'
                  className='btn btn-danger'
                  onClick={() => {
                    setEditVehicle({...editVehicle, geotabDeviceSerial: ''});
                  }}
                >
                  Unplug - {props.editVehicle.beWhereSerial ?? props.editVehicle.geotabDeviceSerial}
                </button>
              )}
            </div>
            <div style={{marginTop: '5px'}} className='col-sm-3'></div>
            <div style={{marginTop: '5px'}} className='col-sm-3'></div>
            <div style={{marginTop: '5px'}} className='col-sm-6'>
              {editVehicle.geotabDeviceSerial == '' && (
                <strong>{props.editVehicle.beWhereSerial ?? props.editVehicle.geotabDeviceSerial}</strong>
              )}
            </div>
            <div style={{marginTop: '5px'}} className='col-sm-3'></div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  async function handleEditSubmit() {
    const originalVehicleData = props.editVehicle;
    const newVehicleData = editVehicle;
    const shopviewOnly = originalVehicleData.geotabDeviceId == '';
    let createNew = false;
    let changeUnassigned = false;
    let updateAsyncData = false;

    // Clear errors
    setModalError('');

    // Ensure that for unplugging, the target myyGT device SN matches between fauna and myGT
    if (newVehicleData.geotabDeviceSerial == '' && originalVehicleData.geotabDeviceSerial != '') {
      // Query to find the latest device serial
      const newActiveDevices = await getDevicesData();

      const geotabTrackedDeviceSerial = newActiveDevices.geotab[originalVehicleData.geotabDeviceId].serialNumber;
      const originalVehicleGeotabDeviceSerial = originalVehicleData.geotabDeviceSerial;
      if (originalVehicleGeotabDeviceSerial != geotabTrackedDeviceSerial) {
        setModalError('Device serial number mismatch. \nError please see your system administrator for assistance');
        return;
      }
    }

    // Ensure that for archiving, the vehicle must have its gt device unplugged
    if (newVehicleData.archived && !originalVehicleData.archived && newVehicleData.geotabDeviceSerial != '') {
      setModalError('Please unplug device before archiving vehicle');
      return;
    }

    if (!newVehicleData.name) {
      setModalError('Please enter a Vehicle Name');
      return;
    }
    if (!newVehicleData.vehicleSN) {
      setModalError('Vehicle Serial error. \nError please see your system administrator for assistance');
      return;
    }
    if (!entryVehicle.vehicleSN) {
      setModalError('Vehicle Serial error. \nError please see your system administrator for assistance');
      return;
    }

    setSubmitStatus('submitting');

    const updateDocs = [];
    const vehicleDiff = {serialNumber: newVehicleData.vehicleSN};
    const keysDiffDoc = {};

    // Value based keys
    const vehicleEditKeys = [
      'icActive',
      'cabviewActive',
      'coverageActive',
      'shopActive',
      'inspectionActive',
      'archived',
      'permanentTaskImpExpiryDate',
    ];
    // Only Edit vehicle implement/task if no error
    if (!(newVehicleData['linkedImplementSN'] != '' && newVehicleData['linkedTaskId'] == '')) {
      vehicleEditKeys.push('name', 'linkedTaskId', 'linkedImplementSN');
    }
    for (let i = 0; i < vehicleEditKeys.length; i++) {
      if (
        typeof originalVehicleData[vehicleEditKeys[i]] == 'undefined' ||
        newVehicleData[vehicleEditKeys[i]] != originalVehicleData[vehicleEditKeys[i]]
      ) {
        keysDiffDoc[vehicleEditKeys[i]] = newVehicleData[vehicleEditKeys[i]];
      }
    }
    // Clear Permanent Task / Implement Expiry Date when updating Vehicle if linkedTaskId and linkedImplementSN are NOT assigned
    if (newVehicleData['linkedImplementSN'] == '' && newVehicleData['linkedTaskId'] == '') {
      keysDiffDoc['permanentTaskImpExpiryDate'] = '';
    }
    // Type parsing keys
    const vehicleEditParseIntKeys = ['machineType', 'rowApplicationType'];
    for (let i = 0; i < vehicleEditParseIntKeys.length; i++) {
      if (
        typeof originalVehicleData[vehicleEditParseIntKeys[i]] == 'undefined' ||
        parseInt(newVehicleData[vehicleEditParseIntKeys[i]]) != originalVehicleData[vehicleEditParseIntKeys[i]]
      ) {
        if (typeof newVehicleData[vehicleEditParseIntKeys[i]] == 'undefined') {
          keysDiffDoc[vehicleEditParseIntKeys[i]] = 0;
        } else {
          keysDiffDoc[vehicleEditParseIntKeys[i]] = parseInt(newVehicleData[vehicleEditParseIntKeys[i]]);
        }
      }
    }

    // Text parsing keys
    const vehicleEditParseStringKeys = ['vin', 'make', 'model', 'notes'];
    for (let i = 0; i < vehicleEditParseStringKeys.length; i++) {
      if (
        typeof originalVehicleData[vehicleEditParseStringKeys[i]] == 'undefined' ||
        newVehicleData[vehicleEditParseStringKeys[i]].trim() != originalVehicleData[vehicleEditParseStringKeys[i]]
      ) {
        if (typeof newVehicleData[vehicleEditParseStringKeys[i]] == 'undefined') {
          keysDiffDoc[vehicleEditParseStringKeys[i]] = '';
        } else {
          keysDiffDoc[vehicleEditParseStringKeys[i]] = newVehicleData[vehicleEditParseStringKeys[i]].trim();
        }
      }
    }

    // Process Width Changes
    let dimensions = {
      width: 0,
      height: 0,
      length: 0,
    };
    if (originalVehicleData?.dimensions != undefined) {
      dimensions = originalVehicleData.dimensions;
    }
    if (newVehicleData.width != originalVehicleData.width) {
      // WIP - need to confirm why we have width declared in two different places
      dimensions.width = newVehicleData.width;
      keysDiffDoc.width = newVehicleData.width;
      keysDiffDoc['dimension'] = dimensions;
    }

    if (!isEqual(editVehicle.vehicleLabels, vehicleLabelsByVehicleSN[editVehicle.vehicleSN] || [])) {
      // Check if Vehicle's group is changed
      await props.postVehicleLabelsUpdateByVehicles([
        {
          vehicleSN: editVehicle.vehicleSN,
          vehicleLabels: editVehicle.vehicleLabels,
        },
      ]);
    }

    // Apply the keys changes directly to backend standalone to not be affected by detection of device change requests
    if (Object.keys(keysDiffDoc).length > 0) {
      const keysDiffDocTrimmed = trimObjectStrings(keysDiffDoc);
      vehicleDiff.diffDoc = keysDiffDocTrimmed;
      const generalKeysUpdate = [vehicleDiff];
      generalKeysUpdate.forEach((vehDiffDoc) => {
        if (vehDiffDoc.hasOwnProperty('diffDoc') && vehDiffDoc.diffDoc.hasOwnProperty('name')) {
          updateAsyncData = true;
        }
      });
      const vehicleDocUpdateResult = await props.postVehicleUpdates(generalKeysUpdate);
    }

    // ------------------------------------------------------------------------------------------------------
    // Process Device Updates
    let deviceUpdated = false;
    // If Device Serial is taken from another geotab Device
    let movingDeviceSerial = deviceSwapTargetDeviceId != '';
    let swapTargetDeviceSerial = '';

    // Unplug device
    if (newVehicleData.geotabDeviceSerial == '' && originalVehicleData.geotabDeviceSerial != '') {
      // We are operating on the device that WAS on the vehicle before unplug
      const mockVehicleDoc = {
        geotabDevice: {
          id: originalVehicleData.geotabDeviceId,
        },
        deviceSerialBeingUnplugged: originalVehicleData.geotabDeviceSerial,
        vehicleBeingUnpluggedFrom: originalVehicleData,
      };
      const unassignedDeviceDiffDoc = {
        serialNumber: '',
      };
      mockVehicleDoc.diffDoc = unassignedDeviceDiffDoc;
      await postUpdateDevice([mockVehicleDoc]);
      deviceUpdated = true;
      updateAsyncData = true;
    }

    // Add device that is currently unassigned
    if (
      newVehicleData.geotabDeviceSerial != originalVehicleData.geotabDeviceSerial &&
      newVehicleData.geotabDeviceSerial != '' &&
      deviceSwapTargetDeviceId == ''
    ) {
      // Set createNew to true, this will trigger creation of a new geotab asset if it's a shopview only vehicle
      createNew = true;
      // We are operating on the device ID that is on the vehicle (device ID doesn't change only serial does)
      const mockVehicleDoc = {
        geotabDevice: {
          id: originalVehicleData.geotabDeviceId,
        },
      };
      // Add the new
      const newDevice = {
        name: newVehicleData.name.trim(),
        serialNumber: newVehicleData.geotabDeviceSerial,
      };
      mockVehicleDoc.diffDoc = newDevice;
      // If we're shopviewonly we're not ready to make this call yet,
      // Will be handled by postVehicleUpdates so we can create a geotab asset first
      if (!shopviewOnly) {
        await postUpdateDevice([mockVehicleDoc]);
      }
      deviceUpdated = true;
      updateAsyncData = true;
    }

    // Add device that is currently assigned
    if (
      newVehicleData.geotabDeviceSerial != originalVehicleData.geotabDeviceSerial &&
      newVehicleData.geotabDeviceSerial != '' &&
      deviceSwapTargetDeviceId != ''
    ) {
      const deviceUpdates = [];
      // First remove the device from the other vehicle
      const removeVehicleDoc = {
        geotabDevice: {
          id: deviceSwapTargetDeviceId,
        },
      };

      const removeDevice = {
        serialNumber: '',
      };
      removeVehicleDoc.diffDoc = removeDevice;
      deviceUpdates.push(removeVehicleDoc);
      // Now add the device SN you just removed to the new vehicle

      const addVehicleDoc = {
        geotabDevice: {
          id: originalVehicleData.geotabDeviceId,
        },
      };

      const addDevice = {
        serialNumber: newVehicleData.geotabDeviceSerial,
      };
      if (newVehicleData.name != originalVehicleData.name) {
        addDevice.name = newVehicleData.name.trim();
      }
      addVehicleDoc.diffDoc = addDevice;

      // Store so we can retrieve custom params
      addVehicleDoc.idOfAssetDeviceIsRemovedFrom = deviceSwapTargetDeviceId;
      deviceUpdates.push(addVehicleDoc);

      if (newVehicleData.swapDevice) {
        swapTargetDeviceSerial = originalVehicleData.geotabDeviceSerial;
        const addOtherVehicleDoc = {
          geotabDevice: {
            id: deviceSwapTargetDeviceId,
          },
        };

        // Store so we can retrieve custom params
        addOtherVehicleDoc.idOfAssetDeviceIsRemovedFrom = originalVehicleData.geotabDeviceId;
        const addOtherDevice = {
          serialNumber: swapTargetDeviceSerial,
        };
        addOtherVehicleDoc.diffDoc = addOtherDevice;
        deviceUpdates.push(addOtherVehicleDoc);
      }
      // if we're not swapping we need to put the old device on an unassigned asset
      // Adding these keys will trigger the back end to do that
      else {
        addVehicleDoc.deviceSerialBeingUnplugged = originalVehicleData.geotabDeviceSerial;
        addVehicleDoc.vehicleBeingUnpluggedFrom = originalVehicleData;
      }

      deviceUpdated = true;
      updateAsyncData = true;
      // If the device were taking is on an unassigned holding asset we
      // can repurpose the asset for the shopview only vehicle
      if (shopviewOnly && activeDevices.geotab[deviceSwapTargetDeviceId].name.toLowerCase().includes('unassigned')) {
        changeUnassigned = true;
        // If we're dong changeUnassigned, we don't need to make a postUpdateDevice call.
      }
      // If the device were taking is on an active vehicle we want to create a new asset for our shopviewonly vehicle
      else if (shopviewOnly) {
        createNew = true;
        // If were taking device from active vehicle we should remove it from that active vehicle first,
        // The postVehicleUpdates will handle the creation of new geotab asset and pointing our fauna veh doc at it
        movingDeviceSerial = true;
        await postUpdateDevice([removeVehicleDoc]);
      } else {
        await postUpdateDevice(deviceUpdates);
      }
    }

    // Process Assign Geotab Device to fauna vehicle
    // Use a different diffDoc to have a clean slate on update device assignements
    const deviceDiffDoc = {};
    deviceDiffDoc.geotabDevice = null;
    // TODO need to handle here the proper creation of geotab data on the fauna document
    if (newVehicleData.geotabDeviceId != '') {
      deviceDiffDoc.geotabDevice = {...activeDevices.geotab[newVehicleData.geotabDeviceId]};
      // Get the correct serial number if an update has been made
      deviceDiffDoc.geotabDevice.serialNumber = newVehicleData.geotabDeviceSerial;
    }

    // These booleans get set based on device change need evaluations above.
    // Based on these booleans existing on the vehicleDiff the backend will create/repurpose geotab assets as necessary
    // To convert a shopviewOnly vehicle into a real one
    else if (shopviewOnly && createNew) {
      deviceDiffDoc.shopviewOnly = false;
      vehicleDiff.createNew = true;
      vehicleDiff.newGeotabSerial = newVehicleData.geotabDeviceSerial;
    } else if (shopviewOnly && changeUnassigned) {
      deviceDiffDoc.shopviewOnly = false;
      vehicleDiff.changeUnassigned = true;
      vehicleDiff.unAssignedAssetId = deviceSwapTargetDeviceId;
      vehicleDiff.newGeotabSerial = newVehicleData.geotabDeviceSerial;
    }

    vehicleDiff.diffDoc = deviceDiffDoc;

    // We want to update the fauna doc if there is a geotab serial change but this would have keys.length < 1
    if (
      Object.keys(deviceDiffDoc).length > 1 ||
      originalVehicleData.geotabDeviceSerial != newVehicleData.geotabDeviceSerial
    ) {
      // Since geotabDevice is always assigned to diffDoc, only perform update if keys > 1
      if (newVehicleData.geotabDeviceSerial == '') {
        vehicleDiff.diffDoc.cabviewActive = false;
        vehicleDiff.diffDoc.shopviewOnly = true;
      } else {
        vehicleDiff.diffDoc.cabviewActive = true;
        vehicleDiff.diffDoc.coverageActive = true;
        vehicleDiff.diffDoc.shopActive = true;
        vehicleDiff.diffDoc.inspectionActive = true;
        vehicleDiff.diffDoc.icActive = true;
        vehicleDiff.diffDoc.shopviewOnly = false;
      }
      updateDocs.push(vehicleDiff);
    }

    // Update Vehicle Doc of Vehicle that has the Device with Device Serial Taken
    if (movingDeviceSerial && deviceSwapTargetVehicleSN != newVehicleData.vehicleSN) {
      const deviceSwappedVehicle = {serialNumber: deviceSwapTargetVehicleSN};
      // update fauna doc with new geotabDevice
      const deviceSwappedVehicleDiffDoc = {
        geotabDevice: {...activeDevices.geotab[deviceSwapTargetDeviceId]},
      };
      // Set the diffdoc to our stored value
      if (swapTargetDeviceSerial == '') {
        deviceSwappedVehicleDiffDoc.cabviewActive = false;
        deviceSwappedVehicleDiffDoc.shopviewOnly = true;
      } else {
        deviceSwappedVehicleDiffDoc.cabviewActive = true;
        deviceSwappedVehicleDiffDoc.coverageActive = true;
        deviceSwappedVehicleDiffDoc.shopActive = true;
        deviceSwappedVehicleDiffDoc.inspectionActive = true;
        deviceSwappedVehicleDiffDoc.icActive = true;
        deviceSwappedVehicleDiffDoc.shopviewOnly = false;
      }
      deviceSwappedVehicleDiffDoc.geotabDevice.serialNumber = swapTargetDeviceSerial;
      deviceSwappedVehicle.diffDoc = deviceSwappedVehicleDiffDoc;
      // We don't need to make any fauna updates to a vehicle that doesn't exist in fauna
      // Since we're taking the device from an unassigned geotab asset
      if (!changeUnassigned) {
        updateDocs.push(deviceSwappedVehicle);
      }
    }

    let vehicleDocUpdateSuccess = true;
    let engineHourUpdateSuccess = true;
    let odoValueUpdateSuccess = true;

    // Handle bt hub changes
    let btHubChanges = false;
    if (newVehicleData.btHubId != newVehicleData.btHubIdCurr) {
      btHubChanges = true;
      // Clear vehicle from prevous hub
      if (newVehicleData.btHubIdCurr) {
        const updateDoc = {
          id: newVehicleData.btHubIdCurr,
          vehicleSN: '',
        };
        const options = {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(updateDoc),
        };
        await fetchPostAuthSafe('/settings/updateBtHub', options, userSettings.username, userSettings.databaseName);
      }

      // Add vehicle to new hub
      if (newVehicleData.btHubId) {
        const updateDoc = {
          id: newVehicleData.btHubId,
          vehicleSN: newVehicleData.vehicleSN,
        };
        const options = {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(updateDoc),
        };
        await fetchPostAuthSafe('/settings/updateBtHub', options, userSettings.username, userSettings.databaseName);
      }
      dispatch(triggerGetSettingsData());
    }

    if (updateDocs.length > 0) {
      const vehicleDocUpdateResult = await props.postVehicleUpdates(updateDocs);
      if (vehicleDocUpdateResult?.status == 401) {
        navigate('/error', {state: {errorMsg: 'Unauthorized Access or Action Detected, Please try again'}});
      }
      vehicleDocUpdateSuccess = vehicleDocUpdateResult['success'];
    }

    if (
      entryVehicle.dateTime &&
      entryVehicle.vehicleSN &&
      entryVehicle.engineHours &&
      userSettings.roleAccess['vehicleStatusManagement'] &&
      updateVehicleStatus
    ) {
      setSubmitStatus('submitting');
      const engUpdateResult = await submitEngineHourRecord();
      if (engUpdateResult?.status == 401) {
        navigate('/error', {state: {errorMsg: 'Unauthorized Access or Action Detected, Please try again'}});
      }
      engineHourUpdateSuccess = engUpdateResult['success'];
    }

    if (
      entryVehicle.dateTime &&
      entryVehicle.vehicleSN &&
      entryVehicle.odometer &&
      userSettings.roleAccess['vehicleStatusManagement'] &&
      updateVehicleStatus
    ) {
      setSubmitStatus('submitting');
      const odoValueUpdateResult = await submitOdoValueUpdate();
      if (odoValueUpdateResult?.status == 401) {
        navigate('/error', {state: {errorMsg: 'Unauthorized Access or Action Detected, Please try again'}});
      }
      odoValueUpdateSuccess = odoValueUpdateResult['success'];
    }

    dispatch(updateLoading(true));

    // Update data
    dispatch(getUpdatedVehicles());
    dispatch(getCustomerAndUserInfo());
    dispatch(getGlobalVehicleData());
    props.handleUpdateData();
    if (updateAsyncData) {
      props.handleUpdateAsyncData();
    }

    setSubmitStatus(vehicleDocUpdateSuccess && engineHourUpdateSuccess && odoValueUpdateSuccess ? 'success' : 'error');

    // Refetch settings data
    if (deviceUpdated || btHubChanges) {
      dispatch(triggerGetSettingsData());
    }

    setTimeout(() => {
      props.handleEditModalOpen(false);
    }, 1000);
  }

  async function getDevicesData() {
    const getDevicesRequest = fetch('/getDevices', {cache: 'no-store'});
    const getDevicesResponse = await getDevicesRequest;
    const devicesActive = await getDevicesResponse.json();

    return devicesActive;
  }

  function modalClose() {
    setSubmitStatus('pending');
    setEditVehicle({});
    setEntryVehicle({
      vehicleName: '',
      vehicleSN: '',
      engineHours: 0,
      dateTime: DateTime.now().setZone(customerSettings.general.timeZone).toISODate(),
      odometer: 0,
      dateTime: DateTime.now().toISO(),
      deviceStatus: {},
    });
    setModalError('');
    return props.handleEditModalOpen(false);
  }

  async function submitEngineHourRecord() {
    const submitParam = {
      vehicleSN: entryVehicle.vehicleSN.trim(),
      engineHours: parseFloat(entryVehicle.engineHours),
      dateTime: DateTime.fromISO(entryVehicle.dateTime).setZone(customerSettings.general.timeZone).toISO(),
      name: entryVehicle.vehicleName.trim(),
    };
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(submitParam),
    };
    const addEntryReq = await fetchPostAuthSafe(
      '/shopview/createEngineRecord',
      options,
      userSettings.username,
      userSettings.databaseName
    );
    const addEntryRepData = await addEntryReq.json();
    if (addEntryRepData.errorMsg) {
      navigate('/error', {state: {errorMsg: addEntryRepData.errorMsg}});
    }
    return addEntryRepData;
  }

  async function submitOdoValueUpdate() {
    const submitParam = {
      vehicleSN: entryVehicle.vehicleSN,
      odometer: unitsLengthSubmitConversion(
        parseFloat(entryVehicle.odometer),
        unitsLengthSystem == 'imperial' ? 'mi' : 'km'
      ),
      dateTime: DateTime.fromISO(entryVehicle.dateTime).setZone(customerSettings.general.timeZone).toISO(),
      name: entryVehicle.vehicleName.trim(),
    };
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(submitParam),
    };
    const updateOdoReq = await fetchPostAuthSafe(
      '/shopview/createOdoRecord',
      options,
      userSettings.username,
      userSettings.databaseName
    );
    const updateOdoRepData = await updateOdoReq.json();
    if (updateOdoRepData.errorMsg) {
      navigate('/error', {state: {errorMsg: updateOdoRepData.errorMsg}});
    }
    return updateOdoRepData;
  }

  /**
   *
   * @param {*} updateDoc
   */
  async function postUpdateDevice(updateDoc) {
    if (Object.keys(updateDoc).length > 0) {
      const postData = updateDoc;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        body: JSON.stringify(postData),
      };
      const url = '/settings/updateDevice';

      const response = await fetchPostAuthSafe(url, options, userSettings.username, userSettings.databaseName);
      const result = await response.json();
      if (result.errorMsg) {
        navigate('/error', {state: {errorMsg: result.errorMsg}});
      }
      return response;
    } else {
      return {success: false, status: 400, error: 'Request body is empty'};
    }
  }

  function renderTaskOptions() {
    const options = [];
    const optionsKeyValue = [];
    Object.keys(taskConfigDict).forEach((taskId) => {
      if (taskConfigDict[taskId]?.archived != true) {
        optionsKeyValue.push({
          taskId: taskId,
          name: taskConfigDict[taskId].name,
        });
      } else if (taskId == editVehicle.linkedTaskId) {
        optionsKeyValue.push({
          taskId: taskId,
          name: `${taskConfigDict[taskId].name} - Archived`,
        });
      }
    });
    optionsKeyValue.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    for (let i = 0; i < optionsKeyValue.length; i++) {
      options.push({
        value: optionsKeyValue[i].taskId,
        label: optionsKeyValue[i].name,
      });
    }

    options.unshift({
      value: '',
      label: '----',
    });
    options.sort;

    return options;
  }

  function renderImplementOptions() {
    const options = [];
    const optionsKeyValue = [];
    Object.keys(implementSNDict).forEach((implementSN) => {
      if (!implementSNDict[implementSN].archived) {
        optionsKeyValue.push({
          implementSN: implementSN,
          name: implementSNDict[implementSN].name,
        });
      } else if (implementSN == editVehicle.linkedImplementSN) {
        optionsKeyValue.push({
          implementSN: implementSN,
          name: `${implementSNDict[implementSN].name} - Archived`,
        });
      }
    });

    optionsKeyValue.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    for (let i = 0; i < optionsKeyValue.length; i++) {
      options.push({
        value: optionsKeyValue[i].implementSN,
        label: optionsKeyValue[i].name,
      });
    }

    options.unshift({
      value: '',
      label: '----',
    });
    return options;
  }

  function checkIfDeviceSerialAssigned(currentDeviceId, deviceSerial) {
    let deviceAssigned = false;
    setDeviceSwapTargetDeviceId('');
    setDeviceSwapTargetVehicleSN('');
    Object.keys(activeDevices.geotab).forEach((deviceId) => {
      const isCurrentDevice = currentDeviceId == deviceId;

      if (deviceSerial != '' && activeDevices.geotab[deviceId].serialNumber == deviceSerial && !isCurrentDevice) {
        deviceAssigned = true;
        // Don't need to offer vehicle swap option if we're on an 'unassigned' asset
        if (activeDevices.geotab[deviceId].name.toLowerCase().includes('unassigned')) {
          deviceAssigned = false;
        }
        setDeviceSwapTargetDeviceId(deviceId);
        const deviceSwapTargetVehicle = vehicleData.find((vehicle) => {
          return vehicle?.geotabDevice?.id == deviceId;
        });
        if (deviceSwapTargetVehicle) {
          setDeviceSwapTargetName(deviceSwapTargetVehicle.name);
          setDeviceSwapTargetVehicleSN(deviceSwapTargetVehicle.serialNumber);
        }
      }
    });
    setShowSwapDeviceWarning(deviceAssigned);
  }

  function renderDeviceOptions() {
    const options = [];
    const deviceAssigned = vehicleData.map((vehicle) => {
      return vehicle?.geotabDevice?.id;
    });
    Object.keys(activeDevices.geotab).forEach((deviceId) => {
      if (!deviceAssigned.includes(deviceId) || deviceId == props.editVehicle.geotabDeviceId) {
        options.push(
          <option key={deviceId} value={deviceId}>
            {activeDevices.geotab[deviceId].name}
          </option>
        );
      }
    });
    options.push(
      <option key={''} value={''}>
        No Geotab Device Assigned
      </option>
    );
    return options.map((option) => {
      return option;
    });
  }

  function renderDeviceSerialOptions() {
    const options = [];
    const mappingList = [];
    Object.keys(activeDevices.geotab).forEach((deviceId) => {
      const deviceSerial = activeDevices.geotab[deviceId].serialNumber;
      if (deviceSerial != '' && deviceSerial != undefined) {
        options.push({serial: deviceSerial, label: deviceSerial + ' (' + activeDevices.geotab[deviceId].name + ')'});
        mappingList.push({serialNumber: deviceSerial, name: activeDevices.geotab[deviceId].name});
      }
    });

    const mapping = serialNumberToVehicleMapping(mappingList);

    const sortedOptions = options.sort((a, b) => {
      const labelA = a.label.toLowerCase();
      const labelB = b.label.toLowerCase();

      if (labelA.includes('unassigned') && !labelB.includes('unassigned')) {
        return -1; // "a" comes before "b"
      } else if (!labelA.includes('unassigned') && labelB.includes('unassigned')) {
        return 1; // "a" comes after "b"
      } else {
        return 0; // no change in order
      }
    });

    return [sortedOptions, mapping];
  }

  if (submitStatus != 'pending') {
    return (
      <Modal
        open={editModalOpen}
        onClose={modalClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={boxStyle}>
          <div className='flex-row mb-3 col-12 px-1 text-center'>
            {submitStatus == 'submitting' && <ModalSubmitting />}
            {submitStatus == 'success' && (
              <div>
                <div style={{color: 'green', fontSize: '5vw'}}>
                  <FontAwesomeIcon icon='fa-sharp fa-solid fa-circle-check' />
                </div>
                <span>Update Completed.</span>
              </div>
            )}
            {submitStatus == 'error' && (
              <div>
                <div style={{color: 'red', fontSize: '5vw'}}>
                  <FontAwesomeIcon icon='fa-sharp fa-solid fa-circle-xmark' />
                </div>
                <span>An error has occured, please try again.</span>
              </div>
            )}
          </div>
          {submitStatus != 'submitting' && (
            <div style={{marginTop: '20px'}} className='flex-row mb-3 col-12 px-1'>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                <Button variant='ic-button' color='secondary' onClick={modalClose}>
                  Close
                </Button>
              </div>
            </div>
          )}
        </Box>
      </Modal>
    );
  }

  const vehicleHasGeotabDevice = props.editVehicle?.geotabDeviceSerial != '';
  const [deviceSerialOptions, deviceSerialMapping] = renderDeviceSerialOptions();
  const viewOnlyAttributes = !userSettings.roleAccess['vehicleAttributesManagement'] || props.editVehicle.archived;
  const viewOnlyStatus = !userSettings.roleAccess['vehicleStatusManagement'] || props.editVehicle.archived;
  const viewOnlyFeatures = !userSettings.roleAccess['vehicleFeaturesManagement'] || props.editVehicle.archived;

  const tabs = [];
  const tabContents = [];
  /* Attributes Tab */
  if (userSettings.roleViewAccess['vehicleAttributesManagement']) {
    tabs.push('Attributes');
    const attributesTab = (
      <React.Fragment key='Attributes'>
        {viewOnlyAttributes && <ViewOnlyText />}
        <fieldset disabled={viewOnlyAttributes}>
          <div className='row'>
            <div className='col-sm-4'>
              <label className='mb-0 mt-2'>
                <strong>VIN</strong>
              </label>
              <div>
                <TextField
                  disabled={viewOnlyAttributes}
                  size='small'
                  fullWidth
                  value={editVehicle.vin}
                  onChange={(e) => {
                    setEditVehicle({...editVehicle, vin: e.target.value});
                  }}
                />
              </div>
            </div>
            <div className='col-sm-4'>
              <label className='mb-0 mt-2'>
                <strong>Make</strong>
              </label>
              <div>
                <TextField
                  disabled={viewOnlyAttributes}
                  size='small'
                  fullWidth
                  value={editVehicle.make}
                  onChange={(e) => {
                    setEditVehicle({...editVehicle, make: e.target.value});
                  }}
                />
              </div>
            </div>
            <div className='col-sm-4'>
              <label className='mb-0 mt-2'>
                <strong>Model</strong>
              </label>
              <div>
                <TextField
                  disabled={viewOnlyAttributes}
                  size='small'
                  fullWidth
                  value={editVehicle.model}
                  onChange={(e) => {
                    setEditVehicle({...editVehicle, model: e.target.value});
                  }}
                />
              </div>
            </div>
            {editVehicle.width != 0 && editVehicle.width && (
              <div className='col-sm-4'>
                {/* Don't forget when submitting the width we need
              to convert it back into meters if the unit system is imperial */}
                <label className='mb-0 mt-2'>
                  <strong>Width {unitsLengthSystem == 'metric' ? '(M)' : '(ft)'}</strong>
                </label>
                <div>
                  {unitsLengthDisplayConversion(
                    editVehicle.width,
                    unitsLengthSystem == 'imperial' ? 'ft' : 'm'
                  ).toFixed(2)}
                </div>
              </div>
            )}
            <div className='col-sm-4'>
              <label className='mb-0 mt-2'>
                <strong>Type</strong>
              </label>
              <div className='mt-1'>
                <Select
                  disabled={viewOnlyAttributes}
                  size='small'
                  fullWidth
                  value={editVehicle.machineType}
                  onChange={(e) => {
                    setEditVehicle({...editVehicle, machineType: e.target.value});
                  }}
                >
                  {Object.keys(machineTypeMapping).map((i) => {
                    return (
                      <MenuItem key={i} value={i}>
                        {machineTypeMapping[i]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            </div>
            <div className='col-sm-4'>
              <label className='mb-0 mt-2'>
                <strong>Permanent Implement </strong>
                <Tooltip
                  title='Selecting a Permanent Implement would automatically select the task associated with the implement. 
                Valid Implement is required to have a linked task.'
                  arrow
                >
                  <IconButton size='small'>
                    <FontAwesomeIcon icon='fa-sharp fa-solid fa-circle-question' />
                  </IconButton>
                </Tooltip>
              </label>
              <Autocomplete
                size='small'
                disabled={viewOnlyAttributes}
                value={editVehicle.linkedImplementSN}
                options={renderImplementOptions()}
                getOptionLabel={(option) => {
                  if (typeof option == 'object') {
                    return option.label;
                  } else {
                    const optionObj = renderImplementOptions().find((opt) => {
                      return opt.value == option;
                    });
                    if (optionObj) {
                      return optionObj.label;
                    } else {
                      return '';
                    }
                  }
                }}
                renderInput={(params) => {
                  return <TextField {...params} placeholder='Search...' />;
                }}
                isOptionEqualToValue={(option, value) => {
                  return option.value === value;
                }}
                onChange={(event, value) => {
                  if (value === null) {
                    value = {value: ''};
                  }
                  if (
                    value.value &&
                    (!implementSNDict[value.value]?.linkedTaskId || implementSNDict[value.value].linkedTaskId == '')
                  ) {
                    setModalError(
                      `The selected implement [${
                        implementSNDict[value.value]?.name
                      }] does not have have a Task configured,
                  please configure it first or select another implement.`
                    );
                    setEditVehicle({
                      ...editVehicle,
                      linkedImplementSN: value.value,
                      linkedTaskId: '',
                    });
                  } else {
                    let newExpiryDate = editVehicle.permanentTaskImpExpiryDate;
                    if (!value.value) {
                      newExpiryDate = '';
                    }

                    setModalError('');
                    setEditVehicle({
                      ...editVehicle,
                      linkedImplementSN: value.value,
                      linkedTaskId: value.value ? implementSNDict[value.value].linkedTaskId : '',
                      permanentTaskImpExpiryDate: newExpiryDate,
                    });
                  }
                }}
              />
            </div>
            <div className='col-sm-4'>
              <label className='mb-0 mt-2'>
                <strong>Permanent Task </strong>
                <Tooltip
                  title='Selecting a Permanent Task would automatically deselect the Permanent Implement. '
                  arrow
                >
                  <IconButton size='small'>
                    <FontAwesomeIcon icon='fa-sharp fa-solid fa-circle-question' />
                  </IconButton>
                </Tooltip>
              </label>
              <Autocomplete
                size='small'
                disabled={viewOnlyAttributes}
                value={editVehicle.linkedTaskId}
                options={renderTaskOptions()}
                getOptionLabel={(option) => {
                  if (typeof option == 'object') {
                    return option.label;
                  } else {
                    const optionObj = renderTaskOptions().find((opt) => {
                      return opt.value == option;
                    });
                    if (optionObj) {
                      return optionObj.label;
                    } else {
                      return '';
                    }
                  }
                }}
                renderInput={(params) => {
                  return <TextField {...params} placeholder='Search...' />;
                }}
                isOptionEqualToValue={(option, value) => {
                  return option.value === value;
                }}
                onChange={(event, value) => {
                  if (value === null) {
                    value = {value: ''};
                  }

                  let newExpiryDate = editVehicle.permanentTaskImpExpiryDate;
                  if (!value.value) {
                    newExpiryDate = '';
                  }

                  setModalError('');
                  setEditVehicle({
                    ...editVehicle,
                    linkedTaskId: value.value,
                    linkedImplementSN: '',
                    permanentTaskImpExpiryDate: newExpiryDate,
                  });
                }}
              />
            </div>
            <div className='col-sm-4'>
              <div>
                <label className='mb-0 mt-2'>
                  <strong>Permanent Expiry Date</strong>
                  <Tooltip title='The Permanent Implement and Permament Task will be removed after this date.' arrow>
                    <IconButton size='small'>
                      <FontAwesomeIcon icon='fa-sharp fa-solid fa-circle-question' />
                    </IconButton>
                  </Tooltip>
                </label>
              </div>
              <TextField
                className='col-12'
                disabled={viewOnlyStatus || (!editVehicle.linkedTaskId && !editVehicle.linkedImplementSN)}
                size='small'
                type='date'
                name='expiryDate'
                InputProps={{
                  inputProps: {
                    min: DateTime.local({zone: customerSettings.general.timeZone}).plus({days: 1}).toISO().slice(0, 10),
                  },
                }}
                value={editVehicle.permanentTaskImpExpiryDate}
                onChange={(e) => {
                  setEditVehicle({...editVehicle, permanentTaskImpExpiryDate: e.target.value});
                }}
              />
            </div>
            <div className='col-12'>
              <label className='mb-0 mt-2'>
                <strong>Notes</strong>
              </label>
              <div>
                <TextField
                  disabled={viewOnlyAttributes}
                  size='small'
                  fullWidth
                  value={editVehicle.notes}
                  onChange={(e) => {
                    setEditVehicle({...editVehicle, notes: e.target.value});
                  }}
                />
              </div>
            </div>
            {customerSettings.general.enableBluetoothTags && (
              <div className='col-12'>
                <label className='mb-0 mt-2'>
                  <strong>BT Hub Installed</strong>
                </label>
                <div>
                  <Switch
                    className='col-2'
                    disabled={viewOnlyAttributes}
                    checked={btHubInputEnabled}
                    onChange={(e) => {
                      setBtHubInputEnabled(!btHubInputEnabled);
                      if (!e.target.checked) {
                        setEditVehicle({...editVehicle, btHubId: ''});
                      }
                    }}
                  />
                  <Select
                    disabled={viewOnlyAttributes || !btHubInputEnabled}
                    size='small'
                    className='col-10'
                    value={editVehicle.btHubId}
                    onChange={(e) => {
                      setEditVehicle({...editVehicle, btHubId: e.target.value});
                    }}
                  >
                    {btHubs.map((btHub) => {
                      let byHubDisplay = btHub.data.id;
                      if (btHub.data.vehicleSN && btHub.data.vehicleSN != editVehicle.vehicleSN) {
                        const vehicleMatch = vehicleData.find((veh) => {
                          return veh.serialNumber == btHub.data.vehicleSN;
                        });
                        if (vehicleMatch) {
                          byHubDisplay += ` (${vehicleMatch.name})`;
                        }
                      }
                      return (
                        <MenuItem key={btHub.data.id} value={btHub.data.id}>
                          {byHubDisplay}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              </div>
            )}
          </div>
          <div style={{textAlign: 'center'}} className='row'>
            {!enableDeviceEdit && (
              <React.Fragment>
                <div className='col-sm-12'>
                  <label className='mb-0 mt-2'>
                    <strong>GPS Device: </strong>

                    {customerSettings.general.deviceManagementEnabled &&
                      userSettings.roleAccess.deviceManagement &&
                      !viewOnlyAttributes && (
                        <button
                          onClick={() => {
                            setEnableDeviceEdit(true);
                            setWarningBubbleVisible(true);
                          }}
                          className='btn'
                        >
                          <FontAwesomeIcon icon='fas fa-edit' />
                        </button>
                      )}
                  </label>
                </div>
              </React.Fragment>
            )}
            {enableDeviceEdit ? (
              <React.Fragment>
                {renderGeotabEditPanel(deviceSerialOptions, deviceSerialMapping, vehicleHasGeotabDevice)}
              </React.Fragment>
            ) : (
              <div style={{textAlign: 'center'}} className='col-sm-12'>
                {editVehicle.geotabDeviceId != '' ? (
                  <div>
                    <div>
                      <sub>
                        <strong>Device Serial: </strong>
                        {props.editVehicle.geotabDeviceSerial != ''
                          ? props.editVehicle.beWhereSerial ?? props.editVehicle.geotabDeviceSerial
                          : 'Device not Installed'}
                      </sub>
                    </div>
                  </div>
                ) : (
                  'No GPS Device Assigned'
                )}
              </div>
            )}
          </div>
        </fieldset>
        <Box component='div' sx={{flexShrink: 0}}>
          <div style={{marginTop: '20px'}} className='flex-row mb-3 col-12 px-1'>
            <div style={{color: 'red', textAlign: 'center'}} className='flex-row mb-3 col-12 px-1'>
              {modalError}
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
              {warningBubbleVisible ? (
                <Collapse in={warningBubbleVisible}>
                  <Alert
                    action={
                      <IconButton
                        aria-label='close'
                        color='inherit'
                        size='small'
                        onClick={() => {
                          setWarningBubbleVisible(false);
                        }}
                      >
                        <CloseIcon fontSize='inherit' />
                      </IconButton>
                    }
                    variant='outlined'
                    severity='warning'
                  >
                    <AlertTitle sx={{textAlign: 'center'}}>{!smallScreen && 'Warning'}</AlertTitle>
                    {enableVehicleNameEdit && (
                      <div style={{textAlign: 'center'}}>
                        Changing vehicle name is cosmetic and should not be done when moving devices between vehicles.
                      </div>
                    )}
                    {enableDeviceEdit && !enableVehicleNameEdit && (
                      <div style={{textAlign: 'center'}}>
                        Changing gps device may have unintended effects on historical data
                      </div>
                    )}
                    {(enableVehicleNameEdit || enableDeviceEdit) && !smallScreen && (
                      <div style={{textAlign: 'center'}}>
                        Please advise our support team to perform this action if you are uncertain.
                      </div>
                    )}
                  </Alert>
                </Collapse>
              ) : (
                <React.Fragment>
                  <Button variant='ic-button' color='secondary' onClick={modalClose}>
                    Cancel
                  </Button>
                  {!viewOnlyAttributes && (
                    <Button variant='ic-button' color='primary' onClick={handleEditSubmit}>
                      Submit
                    </Button>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </Box>
      </React.Fragment>
    );
    tabContents.push(attributesTab);
  }
  /* Status Tab */
  if (userSettings.roleViewAccess['vehicleStatusManagement']) {
    const deviceStatus = deviceStatuses[props.editVehicle.vehicleSN];
    tabs.push('Status');

    const statusTab = (
      <React.Fragment key='Status'>
        {viewOnlyStatus && <ViewOnlyText />}
        {props.editVehicle.geotabDeviceSerial != undefined &&
          props.editVehicle.geotabDeviceSerial != '' &&
          props.editVehicle.geotabDeviceSerial != '000-000-0000' &&
          accessLevels[userSettings.role] >= accessLevels[customerSettings.general.vehicleStatusVisibility] && (
            <React.Fragment>
              <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
                <div>
                  <strong>Last Communicated: </strong>
                  {deviceStatus.lastCommunicatedTimeLocaleStr == null ? (
                    <CircularProgress size={10} />
                  ) : (
                    deviceStatus.lastCommunicatedTimeLocaleStr
                  )}
                </div>
              </div>
              <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
                <div>
                  <strong>Cellular Network: </strong>
                  {deviceStatus.cell == null ? (
                    <CircularProgress size={10} />
                  ) : (
                    <FontAwesomeIcon icon='fa-solid fa-square' color={deviceStatus.cell} />
                  )}
                </div>
              </div>
              <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
                <div>
                  <strong>GPS Network: </strong>
                  {deviceStatus.gps == null ? (
                    <CircularProgress size={10} />
                  ) : (
                    <FontAwesomeIcon icon='fa-solid fa-square' color={deviceStatus.gps} />
                  )}
                </div>
              </div>
              <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
                <div>
                  <strong>Harness Type: </strong>
                  {deviceStatus.harnessType == null ? <CircularProgress size={10} /> : deviceStatus.harnessType}
                </div>
              </div>
              <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
                <div>
                  <strong>Power Type: </strong>
                  {deviceStatus.powerType == null ? <CircularProgress size={10} /> : deviceStatus.powerType}
                </div>
              </div>
              <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
                <div>
                  <strong>Ignition Status: </strong>
                  {deviceStatus.ignState == null ? <CircularProgress size={10} /> : deviceStatus.ignState}
                </div>
              </div>
            </React.Fragment>
          )}
        <div className='flex-row mb-3 col-12 px-1'>
          <label className='mb-0 mt-2'>
            <strong>Engine Hours</strong>
          </label>
          <TextField
            disabled={viewOnlyStatus}
            size='small'
            fullWidth
            type='text'
            name='engineHours'
            value={entryVehicle.engineHours}
            InputProps={{inputProps: {step: 1}}}
            onChange={(e) => {
              if ((e.target.value && !decimalLimitRegex(2).test(e.target.value)) || e.target.value.includes('-')) {
                return;
              }
              setEntryVehicle({
                ...entryVehicle,
                engineHours: e.target.value,
              });
            }}
            onKeyDown={handleAllowOnlyNumbers}
          />
        </div>
        <div className='flex-row mb-3 col-12 px-1'>
          <label className='mb-0 mt-2'>
            <strong>Odometer ({unitsLengthSystem == 'imperial' ? 'mi' : 'km'})</strong>
          </label>
          <TextField
            disabled={viewOnlyStatus}
            size='small'
            fullWidth
            type='text'
            name='odometer'
            value={entryVehicle.odometer}
            InputProps={{inputProps: {step: 1}}}
            onChange={(e) => {
              if ((e.target.value && !decimalLimitRegex(2).test(e.target.value)) || e.target.value.includes('-')) {
                return;
              }
              setEntryVehicle({
                ...entryVehicle,
                odometer: e.target.value,
              });
            }}
            onKeyDown={handleAllowOnlyNumbers}
          />
        </div>
        <div className='flex-row mb-3 col-12 px-1'>
          <div>
            <label className='mb-0 mt-2'>
              <strong>Date</strong>
            </label>
          </div>
          <TextField
            className={'col-6 col-md-9'}
            disabled={viewOnlyStatus}
            size='small'
            type='date'
            name='date'
            value={entryVehicle.dateTime}
            onChange={(e) => {
              setEntryVehicle({...entryVehicle, dateTime: e.target.value});
            }}
          />
          <Button
            className='py-2 ml-2'
            disabled={viewOnlyStatus || !deviceStatus?.firstGpsTime}
            variant='ic-button'
            color='secondary'
            onClick={() => {
              setEntryVehicle({...entryVehicle, dateTime: deviceStatus?.firstGpsTime});
            }}
          >
            {deviceStatus?.firstGpsTime == null ? <CircularProgress size={22} /> : 'Use Install Date'}
          </Button>
        </div>
        <div className='flex-row mb-3 col-12 px-1'>
          <Switch
            name='updateStatus'
            checked={updateVehicleStatus}
            disabled={viewOnlyStatus}
            onChange={() => {
              setUpdateVehicleStatus(!updateVehicleStatus);
            }}
          />
          <label style={{fontWeight: 'bold'}}>
            Confirm Status Update Submission <span style={{color: 'red'}}>*</span>
          </label>
        </div>
        <Box component='div' sx={{flexShrink: 0}}>
          <div style={{marginTop: '20px'}} className='flex-row mb-3 col-12 px-1'>
            <div style={{color: 'red', textAlign: 'center'}} className='flex-row mb-3 col-12 px-1'>
              {modalError}
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
              <Button variant='ic-button' color='secondary' onClick={modalClose}>
                Cancel
              </Button>
              {!viewOnlyStatus && (
                <Button disabled={!updateVehicleStatus} variant='ic-button' color='primary' onClick={handleEditSubmit}>
                  Submit
                </Button>
              )}
            </div>
          </div>
        </Box>
      </React.Fragment>
    );
    tabContents.push(statusTab);
  }
  /* Features Tab */
  if (userSettings.roleViewAccess['vehicleFeaturesManagement']) {
    tabs.push('Features');
    const featuresTab = (
      <React.Fragment key='Features'>
        {viewOnlyFeatures && <ViewOnlyText />}
        {!props.editVehicle.archived && (
          <React.Fragment>
            <div className='row w-100 mx-auto px-0 py-2 justify-content-center'>
              <div className='col-sm-6 col-md-4 px-1'>
                <div className='d-flex align-items-center m-1 p-1 bg-light rounded border border-light shadow-sm'>
                  <div className='p-2 w-100'>
                    <strong>Archive Vehicle</strong>
                  </div>
                  <div className='p-2 flex-shrink-1'>
                    <Switch
                      checked={editVehicle.archived}
                      disabled={viewOnlyFeatures}
                      onChange={(e) => {
                        setEditVehicle({...editVehicle, archived: e.target.checked});
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Divider />
          </React.Fragment>
        )}
        {/* Advanced Settings Collapsable */}
        <CollapsableSection
          title='Advanced Settings'
          collapseOpen={showAdvancedSettings}
          setCollapseOpen={setShowAdvancedSettings}
          section='Advanced Settings'
          viewOnly={viewOnlyFeatures}
        >
          <div
            style={{marginTop: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}
            className='col-sm-12'
          >
            <div style={{textAlign: 'center', padding: '5px'}}>
              IC
              <Checkbox
                key={editVehicle.icActive}
                checked={editVehicle.icActive}
                disabled={viewOnlyFeatures}
                onChange={(e) => {
                  setEditVehicle({...editVehicle, icActive: e.target.checked});
                }}
              />
            </div>
            <div style={{textAlign: 'center', padding: '5px'}}>
              CabView
              <Checkbox
                key={editVehicle.cabviewActive}
                checked={editVehicle.cabviewActive}
                disabled={viewOnlyFeatures}
                onChange={(e) => {
                  setEditVehicle({...editVehicle, cabviewActive: e.target.checked});
                }}
              />
            </div>
            <div style={{textAlign: 'center', padding: '5px'}}>
              Coverage
              <Checkbox
                key={editVehicle.coverageActive}
                checked={editVehicle.coverageActive}
                disabled={viewOnlyFeatures}
                onChange={(e) => {
                  setEditVehicle({...editVehicle, coverageActive: e.target.checked});
                }}
              />
            </div>
            <div style={{textAlign: 'center', padding: '5px'}}>
              ShopView
              <Checkbox
                key={editVehicle.shopActive}
                checked={editVehicle.shopActive}
                disabled={viewOnlyFeatures}
                onChange={(e) => {
                  setEditVehicle({...editVehicle, shopActive: e.target.checked});
                }}
              />
            </div>
            <div style={{textAlign: 'center', padding: '5px'}}>
              Inspection
              <Checkbox
                key={editVehicle.inspectionActive}
                checked={editVehicle.inspectionActive}
                disabled={viewOnlyFeatures}
                onChange={(e) => {
                  setEditVehicle({...editVehicle, inspectionActive: e.target.checked});
                }}
              />
            </div>
          </div>
        </CollapsableSection>
        <Box component='div' sx={{flexShrink: 0}}>
          <div style={{marginTop: '20px'}} className='flex-row mb-3 col-12 px-1'>
            <div style={{color: 'red', textAlign: 'center'}} className='flex-row mb-3 col-12 px-1'>
              {modalError}
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
              <Button variant='ic-button' color='secondary' onClick={modalClose}>
                Cancel
              </Button>
              {!viewOnlyFeatures && (
                <Button variant='ic-button' color='primary' onClick={handleEditSubmit}>
                  Submit
                </Button>
              )}
            </div>
          </div>
        </Box>
      </React.Fragment>
    );
    tabContents.push(featuresTab);
  }
  /* Labels Tab */
  if (userSettings.roleViewAccess['vehicleAttributesManagement']) {
    tabs.push('Labels');
    const groupsTab = (
      <React.Fragment key='labels'>
        {viewOnlyAttributes && <ViewOnlyText />}
        <Grid container sx={{display: 'flex', width: '100%'}} spacing={1} paddingBottom={2}>
          <Grid item xs={12} md={4}>
            <label className='mb-0 mt-2'>
              <strong>Vehicle Type</strong>
            </label>
            <div className='mt-1'>
              <Select
                disabled={viewOnlyAttributes}
                size='small'
                fullWidth
                value={editVehicle.machineType}
                onChange={(e) => {
                  setEditVehicle({...editVehicle, machineType: e.target.value});
                }}
              >
                {Object.keys(machineTypeMapping).map((i) => {
                  return (
                    <MenuItem key={i} value={i}>
                      {machineTypeMapping[i]}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </Grid>
          <Grid item xs={12} md={8}>
            <label className='mb-0 mt-2'>
              <strong>Vehicle Labels</strong>
            </label>
            <div className='mt-1'>
              <GeneralSearchSelect
                name={`vehicleLabels`}
                value={editVehicle.vehicleLabels}
                options={Object.keys(vehicleLabelsDict).map((groupId) => {
                  return {value: groupId, label: vehicleLabelsDict[groupId].name};
                })}
                multiple
                allNoneEnabled
                handleChange={(e) => {
                  setEditVehicle((values) => {
                    return {...values, vehicleLabels: e.target.value};
                  });
                }}
              />
            </div>
          </Grid>
        </Grid>
        <Divider />
        <Box component='div' sx={{flexShrink: 0}}>
          <div style={{marginTop: '20px'}} className='flex-row mb-3 col-12 px-1'>
            <div style={{color: 'red', textAlign: 'center'}} className='flex-row mb-3 col-12 px-1'>
              {modalError}
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
              <Button variant='ic-button' color='secondary' onClick={modalClose}>
                Cancel
              </Button>
              {!viewOnlyAttributes && (
                <Button variant='ic-button' color='primary' onClick={handleEditSubmit}>
                  Submit
                </Button>
              )}
            </div>
          </div>
        </Box>
      </React.Fragment>
    );
    tabContents.push(groupsTab);
  }

  return (
    <Modal
      open={editModalOpen}
      onClose={modalClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={attributeBoxStyle}>
        <Box component='div' sx={{flexDirection: 'column', display: 'flex', width: '100%', height: '100%'}}>
          <Box component='div' sx={{flexShrink: 0}}>
            <Box component='div' sx={{flexDirection: 'row', display: 'flex', width: '100%'}}>
              <div className='flex-row mb-3 col-7 px-1'>
                <h3>
                  <strong>Update Vehicle</strong>
                  <div>
                    <sub>{props.editVehicle.name}</sub>
                  </div>
                </h3>
              </div>
            </Box>
            <Divider />
          </Box>
          <Box component='div'>
            <ICTabs tabs={tabs} activeTab={displayedTable} />
            <Divider />
            <TabContent content={tabContents} activeTab={displayedTable} defaultTabSelection={true} />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export {VehicleEditModal};
