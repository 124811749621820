import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Box, Button, Modal, Table, TableHead, TableRow, TableCell, TableBody, TextField, Grid} from '@mui/material';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import isEqual from 'lodash/isEqual';

import {ModalSubmitting, GeneralSearchSelect} from '../../../../components/GeneralComponents';
import {TableHeadFilterButtonTitleWrapper, TableHeadHiddenDropdownWrapper} from '../../../../components/Table';
import {Tailselect} from '../../../../components/Tailselect';
import {TabMenuTableWrapper} from '../../../../components/TabMenuTableWrapper';

import {getCustomerAndUserInfo, getGlobalVehicleData} from '../../../../appSlice';
import {updateLoading} from './vehicleInfoSlice';
import {modalBoxStyle, sortVehicleNamesHelper, trimObjectStrings, fetchPostAuthSafe} from '../../../../app/utils';

function VehicleLabelsModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  $('[data-toggle="tooltip"]').tooltip();

  const taskConfigDict = useSelector((state) => {
    return state.settings.taskConfigDict;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const vehicles = useSelector((state) => {
    return state.settings.vehicles;
  });
  const vehiclesIcActive = useSelector((state) => {
    return state.settings.vehiclesIcActive;
  });
  const vehicleLabelsDict = useSelector((state) => {
    return state.settings.vehicleLabels;
  });
  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });

  const [activeVehicles, setActiveVehicles] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    groupId: [],
  });

  const [filters, setFilters] = useState({
    groupId: [],
  });
  const [submitStatus, setSubmitStatus] = useState('pending');
  const [updateVehicleLabelStatus, setUpdateVehicleLabelStatus] = useState(false);
  // submitStatus:  "pending" : Filling form
  //                "submitting" : Submitting
  //                "success" : Submission Completed
  //                "error" : Error submission
  const [modalError, setModalError] = useState('');
  const defaultVehicleLabel = {
    groupId: '',
    name: '',
    vehicleSNs: [],
    archived: false,
  };
  const [editVehicleLabel, setEditVehicleLabel] = useState(defaultVehicleLabel);
  const [labelForDelete, setLabelForDelete] = useState('');

  useEffect(() => {}, []);

  useEffect(async () => {
    const activeVehTemp = [];
    for (let i = 0; i < vehiclesIcActive.length; i++) {
      if (vehiclesIcActive[i].icActive) {
        activeVehTemp.push(vehiclesIcActive[i]);
      }
    }

    activeVehTemp.sort((a, b) => {
      return sortVehicleNamesHelper(a.name, b.name);
    });

    setActiveVehicles(activeVehTemp);
  }, [vehiclesIcActive]);

  useEffect(() => {
    const vehicleLabelsOptions = Object.keys(vehicleLabelsDict).map((groupId) => {
      return {value: groupId, text: vehicleLabelsDict[groupId].name};
    });
    setFilterOptions({groupId: vehicleLabelsOptions});
  }, [vehicleLabelsDict]);

  useEffect(() => {
    setModalError('');
  }, [editVehicleLabel]);

  const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
  };

  const attributeBoxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: smallScreen ? '100%' : '90vw',
    maxWidth: '800px',
    maxHeight: smallScreen ? '100%' : 'auto',
    minHeight: '480px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    display: 'flex',
    overflowY: 'auto',
    p: 2,
  };

  /**
   *
   * @param {*} updateDoc
   */
  async function postVehicleLabelsUpdate(updateDoc) {
    if (Object.keys(updateDoc).length > 0) {
      trimObjectStrings(updateDoc);
      const postData = updateDoc;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        body: JSON.stringify(postData),
      };
      const url = '/settings/updateVehicleLabels';

      const response = await fetchPostAuthSafe(url, options, userSettings.username, userSettings.databaseName);
      const result = await response.json();
      if (result.errorMsg) {
        navigate('/error', {state: {errorMsg: result.errorMsg}});
      }
      return result;
    } else {
      return {success: false, status: 400, error: 'Request body is empty'};
    }
  }

  function handleFilters(select) {
    // Update filters state based on values selected in drop down selects
    const selected = [];
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].attributes.getNamedItem('selected')) {
        selected.push(select.options[i].value);
      }
    }
    setFilters((values) => {
      return {...values, [select.name]: selected};
    });
  }

  async function handleGroupModalSubmit() {
    // Clear errors
    setModalError('');

    if (!editVehicleLabel.name) {
      setModalError('Please enter a Label Name');
      return;
    }

    setSubmitStatus('submitting');

    const labelDoc = {groupId: editVehicleLabel.groupId};
    let diffDoc = {};

    // Value based keys
    const vehicleLabelEditKeys = ['name', 'vehicleSNs'];

    if (editVehicleLabel.groupId != 'newLabel' && Object.keys(vehicleLabelsDict).includes(editVehicleLabel.groupId)) {
      // Update Vehicle Label
      const originalLabelDoc = vehicleLabelsDict[editVehicleLabel.groupId];
      if (labelForDelete == '') {
        for (let i = 0; i < vehicleLabelEditKeys.length; i++) {
          if (
            typeof originalLabelDoc[vehicleLabelEditKeys[i]] == 'undefined' ||
            !isEqual(editVehicleLabel[vehicleLabelEditKeys[i]], originalLabelDoc[vehicleLabelEditKeys[i]])
          ) {
            diffDoc[vehicleLabelEditKeys[i]] = editVehicleLabel[vehicleLabelEditKeys[i]];
          }
        }
      } else {
        // Only update archived property and ignore other changes when deleted
        diffDoc['archived'] = true;
      }
    } else {
      // New Vehicle Label
      diffDoc = {...editVehicleLabel};
      delete diffDoc.groupId;
    }

    if (Object.keys(diffDoc).length > 0) {
      const diffDocTrimmed = trimObjectStrings(diffDoc);
      labelDoc.diffDoc = diffDocTrimmed;
    }

    const vehicleLabelUpdated = await postVehicleLabelsUpdate([labelDoc]);
    if (vehicleLabelUpdated?.success) {
      setSubmitStatus('success');
      dispatch(updateLoading(true));
      dispatch(getCustomerAndUserInfo());
      dispatch(getGlobalVehicleData());
      props.handleUpdateData();
    } else {
      setSubmitStatus('error');
      setModalError('Failed to update or create Vehicle Labels, Please try again.');
    }
  }

  function modalClose() {
    setSubmitStatus('pending');
    setEditVehicleLabel(defaultVehicleLabel);
    setLabelForDelete('');
    setModalError('');
    return props.toggleGroupModalOpened();
  }

  function generateVehicleLabelTableHead() {
    return (
      <TableHead>
        <TableRow style={{borderBottom: '1px solid rgba(224, 224, 224, 1)'}}>
          <TableCell colSpan={3}>
            <TableHeadFilterButtonTitleWrapper align='center' style={{textAlign: 'center'}} title={'Label Name'}>
              <button
                className='btn btn-transparent btn-sm'
                onClick={() => {
                  return tail.select('#settings-group-name-select').toggle();
                }}
              >
                <FontAwesomeIcon icon='fas fa-filter' style={{color: filters['groupId'].length > 0 && '#4e73df'}} />
              </button>
            </TableHeadFilterButtonTitleWrapper>
            <TableHeadHiddenDropdownWrapper align='center'>
              <Tailselect
                id='settings-group-name-select'
                name={'groupId'}
                multiple={true}
                search={true}
                value={filters['groupId']}
                options={filterOptions['groupId']}
                onChange={handleFilters}
              />
            </TableHeadHiddenDropdownWrapper>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  function generateVehicleLabelTableBody() {
    return (
      <TableBody>
        {Object.keys(vehicleLabelsDict).map((groupId) => {
          let groupInFilter = false;
          if (filters.groupId.length == 0 || filters.groupId.includes(groupId)) {
            groupInFilter = true;
          }
          if (groupInFilter) {
            return (
              <TableRow key={groupId}>
                <TableCell width='100%'>{vehicleLabelsDict[groupId].name}</TableCell>
                <TableCell>
                  <button
                    className='btn btn-light'
                    style={{backgroundColor: 'transparent', borderColor: 'transparent'}}
                    onClick={() => {
                      setEditVehicleLabel({
                        ...vehicleLabelsDict[groupId],
                        name: `${vehicleLabelsDict[groupId].name} - Copy`,
                        groupId: 'newLabel',
                      });
                      setLabelForDelete('');
                    }}
                  >
                    <FontAwesomeIcon icon='fa fa-copy' />
                  </button>
                </TableCell>
                <TableCell>
                  <button
                    className='btn btn-light'
                    style={{backgroundColor: 'transparent', borderColor: 'transparent'}}
                    onClick={() => {
                      setEditVehicleLabel(vehicleLabelsDict[groupId]);
                      setLabelForDelete('');
                    }}
                  >
                    <FontAwesomeIcon icon='fa fa-edit' />
                  </button>
                </TableCell>
              </TableRow>
            );
          }
        })}
      </TableBody>
    );
  }

  function generateVehicleLabelEditForm() {
    if (labelForDelete != '') {
      return (
        <Grid container>
          <Grid item xs={12}>
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
              <h4>
                <strong>Are you sure you wish to delete {vehicleLabelsDict[editVehicleLabel.groupId].name}?</strong>
              </h4>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
              <Button
                variant='ic-button'
                color='secondary'
                className='mx-1'
                onClick={() => {
                  setLabelForDelete('');
                }}
              >
                Cancel
              </Button>
              <Button variant='ic-button' color='danger' className='mx-1' onClick={handleGroupModalSubmit}>
                Delete
              </Button>
            </Box>
          </Grid>
        </Grid>
      );
    }
    return (
      <React.Fragment>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell width='25%'>
                <label className='mb-0 mt-2'>
                  <strong>Label Name</strong>
                </label>
              </TableCell>
              <TableCell>
                <div className='mt-1'>
                  <TextField
                    size='small'
                    fullWidth
                    type='text'
                    name='vehicle-group-name'
                    value={editVehicleLabel.name}
                    onChange={(e) => {
                      setEditVehicleLabel((value) => {
                        return {
                          ...value,
                          name: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <label className='mb-0 mt-2'>
                  <strong>Vehicles</strong>
                </label>
              </TableCell>
              <TableCell>
                <div className='mt-1'>
                  <GeneralSearchSelect
                    label='Vehicles in Group'
                    multiple
                    allNoneEnabled
                    value={editVehicleLabel.vehicleSNs}
                    options={activeVehicles.map((veh) => {
                      return {value: veh.serialNumber, label: veh.name + (veh.archived ? ' (Archived)' : '')};
                    })}
                    handleChange={(e) => {
                      setEditVehicleLabel((value) => {
                        return {
                          ...value,
                          vehicleSNs: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div style={{color: 'red', textAlign: 'center'}} className='flex-row mt-2 col-12 px-1'>
          {modalError}
        </div>
      </React.Fragment>
    );
  }

  function generateVehicleLabelsModalHeader() {
    let titlePrefix = '';
    if (editVehicleLabel.groupId != '') {
      titlePrefix = labelForDelete == '' ? (editVehicleLabel.groupId == 'newLabel' ? 'Create ' : 'Edit ') : 'Delete ';
    }

    return (
      <Box>
        {editVehicleLabel.groupId == '' ? (
          <Button
            variant='ic-button'
            color='success'
            className='mx-1'
            onClick={() => {
              setEditVehicleLabel({
                ...defaultVehicleLabel,
                groupId: 'newLabel',
              });
            }}
          >
            Create New Label
          </Button>
        ) : (
          <div style={{display: 'flex', flexDirection: 'rpw', textAlign: 'center', justifyContent: 'space-between'}}>
            <button
              className='btn btn-transparent btn-sm'
              onClick={() => {
                if (labelForDelete == '') {
                  setEditVehicleLabel(defaultVehicleLabel);
                }
                setLabelForDelete('');
              }}
            >
              <FontAwesomeIcon icon='fas fa-arrow-left' />
            </button>
            {editVehicleLabel.groupId != 'newLabel' && labelForDelete == '' && submitStatus == 'pending' && (
              <Button
                variant='ic-button'
                color='danger'
                className='mx-1'
                onClick={() => {
                  setLabelForDelete(editVehicleLabel.groupId);
                }}
              >
                Delete
              </Button>
            )}
          </div>
        )}
        <Box style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
          <h3>
            <strong>{titlePrefix}Vehicle Labels</strong>
          </h3>
        </Box>
      </Box>
    );
  }

  function generateVehicleLabelsModalFooter() {
    if (submitStatus != 'pending') {
      return null;
    }
    if (editVehicleLabel.groupId == '') {
      return (
        <Button variant='ic-button' color='secondary' className='mx-1' onClick={modalClose}>
          Exit
        </Button>
      );
    }
    if (labelForDelete == '') {
      return (
        <React.Fragment>
          <Button
            variant='ic-button'
            color='secondary'
            className='mx-1'
            onClick={() => {
              setEditVehicleLabel(defaultVehicleLabel);
              setLabelForDelete('');
            }}
          >
            Cancel
          </Button>

          <Button variant='ic-button' color='primary' className='mx-1' onClick={handleGroupModalSubmit}>
            Submit
          </Button>
        </React.Fragment>
      );
    }
  }

  function generateVehicleLabelModalBody() {
    if (submitStatus != 'pending') {
      return (
        <Box>
          <div className='flex-row mb-3 col-12 px-1 text-center'>
            {submitStatus == 'submitting' && <ModalSubmitting />}
            {submitStatus == 'success' && (
              <div>
                <div style={{color: 'green', fontSize: '5vw'}}>
                  <FontAwesomeIcon icon='fa-sharp fa-solid fa-circle-check' />
                </div>
                <span>Update Completed.</span>
              </div>
            )}
            {submitStatus == 'error' && (
              <div>
                <div style={{color: 'red', fontSize: '5vw'}}>
                  <FontAwesomeIcon icon='fa-sharp fa-solid fa-circle-xmark' />
                </div>
                <span>An error has occured, please try again.</span>
              </div>
            )}
          </div>
          {submitStatus != 'submitting' && (
            <div style={{marginTop: '20px'}} className='flex-row mb-3 col-12 px-1'>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                <Button variant='ic-button' color='secondary' onClick={modalClose}>
                  Close
                </Button>
              </div>
            </div>
          )}
        </Box>
      );
    }
    if (editVehicleLabel.groupId == '') {
      return (
        <Table align='center' size='small' stickyHeader sx={{'.MuiTableCell-root': {paddingX: 0.5}}}>
          {generateVehicleLabelTableHead()}
          {generateVehicleLabelTableBody()}
        </Table>
      );
    }
    return generateVehicleLabelEditForm();
  }

  const viewOnlyAttributes = !userSettings.roleAccess['vehicleAttributesManagement'];

  return (
    <Modal
      open={props.opened}
      onClose={modalClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={{...modalBoxStyle, overflow: 'hidden'}}>
        <TabMenuTableWrapper
          menu={generateVehicleLabelsModalHeader()}
          table={generateVehicleLabelModalBody()}
          pagination={generateVehicleLabelsModalFooter()}
        />
      </Box>
    </Modal>
  );
}

export {VehicleLabelsModal};
