// Import dependent packages
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {sendGAPageview, fetchPostAuthSafeWithTimeout, sortVehicleNamesHelper} from '../../app/utils';
// Import dependent components
import {Framework} from '../../components/Framework';

import {InspectionFormSelectModal} from './InspectionFormSelectModal';
import {InspectionFormTableModal} from './InspectionFormTableModal';
import {InspectionFormSubmissionConfirmModal} from './InspectionFormSubmissionConfirmModal';

import './InspectionForm.css';

// Import state managers slices
import {
  updateInspectionItems,
  setInspectionSelectedVehicle,
  setInspectionScannedVehicle,
  setInspectionFormSubmitted,
  setInspectionReport,
  setStoreInspectionItems,
  updateVehicles,
  updateVehicleLabels,
} from './inspectionFormSlice';

// Modal names to element id
const modalNames = {
  vehicleSelection: 'inspection-form-vehicle-select-modal',
  tableForm: 'inspection-form-table-modal',
  submissionConfirm: 'inspection-form-submission-confirm-modal',
};

function InspectionForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userName = useSelector((state) => {
    return state.app.userSettings.username;
  });
  const inspectionSelectedVehicle = useSelector((state) => {
    return state.inspectionForm.inspectionSelectedVehicle;
  });
  const inspectionFormSubmitted = useSelector((state) => {
    return state.inspectionForm.inspectionFormSubmitted;
  });
  const inspectionReport = useSelector((state) => {
    return state.inspectionForm.inspectionReport;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const inspectionNameInput = useSelector((state) => {
    return state.inspectionForm.inspectionNameInput;
  });
  const inspectionReportNameInputRequired = useSelector((state) => {
    return state.app.customerSettings.inspection.inspectionReportNameInput;
  });

  const [modalVisibility, setModalVisibility] = useState({
    vehicleSelection: true,
    tableForm: false,
    submissionConfirm: false,
  });
  const [submissionState, setSubmissionState] = useState('');

  // Set actions on initial load
  useEffect(() => {
    document.title = 'IntelliCulture | Inspection';
    sendGAPageview(document.title);
    initInspectionForm();
  }, []);

  // Set monitor on key form states to trigger visibility changes for transition
  useEffect(() => {
    determineModalVisibility();
  }, [inspectionSelectedVehicle, inspectionFormSubmitted]);

  // Trigger an API query to post inspection report when submitted status has changed
  useEffect(() => {
    sendInspectionReport();
  }, [inspectionFormSubmitted]);

  // Feature initialization
  async function initInspectionForm() {
    // Get inspectionItems docs
    const getInspectionItemsRequest = fetch('/getInspectionItems', {cache: 'no-store'});
    const getInspectionPackRequest = fetch('/getInspectionPack', {cache: 'no-store'});
    const getVehiclesRequest = fetch('/shopview/getVehicles', {cache: 'no-store'});
    const getVehicleLabelsRequest = fetch('/getVehicleLabels', {cache: 'no-store'});

    // Make all calls
    const [getInspectionItemsResponse, getInspectionPackResponse, getVehiclesResponse, getVehicleLabelsResponse] =
      await Promise.all([
        getInspectionItemsRequest,
        getInspectionPackRequest,
        getVehiclesRequest,
        getVehicleLabelsRequest,
      ]);

    // Inspection docs
    const inspectionItems = await getInspectionItemsResponse.json();

    // Get cabview & scanned vehicle docs
    /*
    Structure: (inspectionCabviewDoc)

    implementSN: ""
    implementScanned: false
    menuToggled: false
    scannedAssetType: ""
    taskId: ""
    vehicleSN: ""
    vehicleScanned: false
    */
    const inspectionSelectionPack = await getInspectionPackResponse.json();
    const vehicles = await getVehiclesResponse.json();
    const vehicleLabels = await getVehicleLabelsResponse.json();

    vehicles.sort((a, b) => {
      return sortVehicleNamesHelper(a.name, b.name);
    });

    // Update the vehicle list
    dispatch(updateVehicles(vehicles));
    dispatch(updateVehicleLabels(vehicleLabels));

    // Update store with latest inspection item info
    dispatch(updateInspectionItems(inspectionItems));

    // If there is a scanned vehicle set save to state cache
    if (inspectionSelectionPack.vehicleSN && inspectionSelectionPack.vehicleSN != '') {
      dispatch(setInspectionScannedVehicle(inspectionSelectionPack.vehicleSN));
    } else {
      dispatch(setInspectionScannedVehicle(''));
    }

    // Update store to clear the selection status upon loading the page
    dispatch(setInspectionSelectedVehicle({}));
    dispatch(setInspectionFormSubmitted(false));
    dispatch(setInspectionReport({}));
    dispatch(setStoreInspectionItems([]));

    // Set vehicleSelection as visible modal
    setModalVisibility({vehicleSelection: true});
  }

  // Determine the correct modal to render
  function determineModalVisibility() {
    // Main logic controlling the feature display state
    if (inspectionSelectedVehicle && Object.keys(inspectionSelectedVehicle).length === 0) {
      setModalVisibility({vehicleSelection: true, tableForm: false, submissionConfirm: false});
    } else if (Object.keys(inspectionSelectedVehicle).length !== 0 && !inspectionFormSubmitted) {
      setModalVisibility({vehicleSelection: false, tableForm: true, submissionConfirm: false});
    } else if (Object.keys(inspectionSelectedVehicle).length !== 0 && inspectionFormSubmitted) {
      setSubmissionState('pending');
      setModalVisibility({vehicleSelection: false, tableForm: false, submissionConfirm: true});
    }
  }

  // Make the API queries to enter a new
  async function sendInspectionReport() {
    if (inspectionFormSubmitted) {
      const postData = {
        selectedVeh: inspectionSelectedVehicle.serialNumber,
        comment: inspectionReport.comment,
        items: inspectionReport.items,
        inspectedByUser: userName,
        closedByUser: '',
        closed: false,
        closedDate: '',
        inspectionNameInput: inspectionReportNameInputRequired ? inspectionNameInput.trim() : '',
      };

      const options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(postData),
      };
      // Post inspectionReport data
      const resultObj = await fetchPostAuthSafeWithTimeout(
        '/createInspectionReport',
        options,
        userSettings.username,
        userSettings.databaseName
      );

      if (resultObj.requestSuccess) {
        const result = await resultObj.result.json();

        if (result.errorMsg) {
          navigate('/error', {state: {errorMsg: result.errorMsg}});
        } else {
          setSubmissionState('success');
        }
      } else {
        setSubmissionState('failed');
      }
    }
  }

  // Component render
  return (
    <Framework activePage='inspectionForm' pageName='Inspection' topBarSearch={false}>
      <div className='inspection-form-wrapper'>
        {modalVisibility.vehicleSelection && <InspectionFormSelectModal name={modalNames.vehicleSelection} />}
        {modalVisibility.tableForm && <InspectionFormTableModal name={modalNames.tableForm} />}
        {modalVisibility.submissionConfirm && (
          <InspectionFormSubmissionConfirmModal name={modalNames.submissionConfirm} submissionState={submissionState} />
        )}
      </div>
    </Framework>
  );
}

export {InspectionForm};
