import React, {useState, useEffect} from 'react';
import {createPortal} from 'react-dom';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
  sendGAEvent,
  unitsLengthSubmitConversion,
  machineTypeMapping,
  fetchPostAuthSafe,
  handleAllowOnlyNumbers,
} from '../app/utils';
import {
  ModalFramework,
  ModalHeader,
  ModalFooter,
  ModalRowSection,
  ModalColumnSection,
  ModalWideColumnSection,
  ModalInputSection,
  ModalBody,
  ModalWarning,
} from './Modal';
import {getServiceData} from '../features/Shopview/shopviewSlice';

const defaultInputs = {
  vehicle: '',
  vin: '',
  make: '',
  model: '',
  engineHours: '',
  odometer: '',
  machineType: '',
  notes: '',
};

function NewEquipmentModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const newEquipmentEnabled = useSelector((state) => {
    return state.app.customerSettings.shopview.nonGoDeviceVehiclesEnabled;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const unitsLengthSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsLength;
  });

  const [inputs, setInputs] = useState(defaultInputs);
  const [warnings, setWarnings] = useState({});
  const [unitsLength, setUnitsLength] = useState('km');
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    // Determine length units based on customer length units system
    if (unitsLengthSystem == 'imperial') {
      setUnitsLength('mi');
    } else {
      setUnitsLength('km');
    }
  }, [unitsLengthSystem]);

  useEffect(() => {
    function modalOpen() {
      sendGAEvent('modal_open', 'NewEquipment', 'shopview');
      setDefault();
    }
    // Set default values when modal opened
    $('#shopview-new-equipment-modal').on('show.bs.modal', modalOpen);
    return () => {
      // Remove event listener when component is unmounted
      $('#shopview-new-equipment-modal').off('show.bs.modal', modalOpen);
    };
  }, []);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => {
      return {...values, [name]: value};
    });
  };

  function setDefault() {
    setWarnings({});
    setInputs(defaultInputs);
  }

  async function submitModal() {
    const vehicleEmpty = inputs.vehicle.trim() == '';

    // Set any warnings
    setWarnings((values) => {
      return {
        ...values,
        warningVehicle: vehicleEmpty,
      };
    });

    if (!vehicleEmpty && !submitting) {
      setSubmitting(true);
      const postData = {
        name: inputs.vehicle.trim(),
        vin: inputs.vin.trim(),
        make: inputs.make.trim(),
        model: inputs.model.trim(),
        odometer: parseInt(unitsLengthSubmitConversion(inputs.odometer, unitsLength)) || 0,
        engineHours: parseInt(inputs.engineHours) || 0,
        machineType: parseInt(inputs.machineType),
        notes: inputs.notes.trim(),
      };
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      };

      const createNewEquipmentReq = await fetchPostAuthSafe(
        '/shopview/createNewEquipment',
        options,
        userSettings.username,
        userSettings.databaseName
      );
      const createNewEquipmentReqData = await createNewEquipmentReq.json();

      if (createNewEquipmentReqData.errorMsg) {
        $('#shopview-new-equipment-modal').modal('hide');
        navigate('/error', {state: {errorMsg: createNewEquipmentReqData.errorMsg}});
      }

      if (createNewEquipmentReqData.vehicleExists) {
        setWarnings((values) => {
          return {
            ...values,
            warningExists: true,
          };
        });
      } else {
        $('#shopview-new-equipment-modal').modal('hide');
      }

      sendGAEvent('modal_submit', 'NewEquipment', 'shopview');
      if (typeof props.vehicleInfoHandleUpdate != 'undefined') {
        props.vehicleInfoHandleUpdate();
      }
      setSubmitting(false);
    }
  }

  if (newEquipmentEnabled || userSettings.role == 'ic_admin') {
    return createPortal(
      <ModalFramework id='shopview-new-equipment-modal'>
        <ModalHeader title='New Equipment' />
        <ModalBody>
          {warnings.warningVehicle && <ModalWarning text='Enter an equipment name' />}
          {warnings.warningExists && <ModalWarning text='Equipment already exists' />}
          <ModalRowSection underline={false}>
            <ModalColumnSection>
              <ModalInputSection label='Name'>
                <input
                  type='text'
                  name='vehicle'
                  className='form-control flex-fill'
                  value={inputs.vehicle}
                  onChange={handleChange}
                />
              </ModalInputSection>
            </ModalColumnSection>
            <ModalColumnSection>
              <ModalInputSection label='VIN'>
                <input
                  type='text'
                  name='vin'
                  className='form-control flex-fill'
                  value={inputs.vin}
                  onChange={handleChange}
                />
              </ModalInputSection>
            </ModalColumnSection>
          </ModalRowSection>
          <ModalRowSection underline={true}>
            <ModalColumnSection>
              <ModalInputSection label='Make'>
                <input
                  type='text'
                  name='make'
                  className='form-control flex-fill'
                  value={inputs.make}
                  onChange={handleChange}
                />
              </ModalInputSection>
            </ModalColumnSection>
            <ModalColumnSection>
              <ModalInputSection label='Model'>
                <input
                  type='text'
                  name='model'
                  className='form-control flex-fill'
                  value={inputs.model}
                  onChange={handleChange}
                />
              </ModalInputSection>
            </ModalColumnSection>
          </ModalRowSection>
          <ModalRowSection underline={true}>
            <ModalColumnSection>
              <ModalInputSection label='Current Engine Hours'>
                <input
                  type='text'
                  name='engineHours'
                  className='form-control flex-fill'
                  value={inputs.engineHours}
                  step={0.01}
                  onChange={handleChange}
                  onKeyDown={handleAllowOnlyNumbers}
                />
              </ModalInputSection>
            </ModalColumnSection>
            <ModalColumnSection>
              <ModalInputSection label={`Current Odometer (${unitsLength})`}>
                <input
                  type='text'
                  name='odometer'
                  className='form-control flex-fill'
                  value={inputs.odometer}
                  step={0.01}
                  onChange={handleChange}
                  onKeyDown={handleAllowOnlyNumbers}
                />
              </ModalInputSection>
            </ModalColumnSection>
          </ModalRowSection>
          <ModalRowSection underline={false}>
            <ModalWideColumnSection>
              <ModalInputSection label='Equipment Type'>
                <select
                  name='machineType'
                  value={inputs.machineType}
                  onChange={handleChange}
                  className='form-control flex-fill'
                >
                  {Object.keys(machineTypeMapping).map((i) => {
                    return (
                      <option key={i} value={i}>
                        {machineTypeMapping[i]}
                      </option>
                    );
                  })}
                </select>
              </ModalInputSection>
            </ModalWideColumnSection>
          </ModalRowSection>
          <ModalRowSection underline={false}>
            <ModalWideColumnSection>
              <ModalInputSection label='Notes'>
                <input
                  type='text'
                  name='notes'
                  className='form-control flex-fill'
                  value={inputs.notes}
                  onChange={handleChange}
                />
              </ModalInputSection>
            </ModalWideColumnSection>
          </ModalRowSection>
        </ModalBody>
        <ModalFooter onSubmit={submitModal} />
      </ModalFramework>,
      document.getElementById('app')
    );
  } else {
    return null;
  }
}

export {NewEquipmentModal};
