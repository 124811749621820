import {useSelector} from 'react-redux';
import {Table} from '../../../components/Table';
import {Checkbox} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

function InspectionReportDetailTable(props) {
  const inspectionItemsLookUp = useSelector((state) => {
    return state.shopview.inspectionItemsLookUp;
  });

  const inspectionItemList = props.data.items;

  const columnClass = [
    'inspection-item-col',
    'inspection-pass-col',
    'inspection-fail-col',
    'inspection-fail-comment-box',
  ];

  function generateTableData() {
    return (
      <tbody id={props.id || 'inspection-report-table-body'}>
        {inspectionItemList.map((inspectionItem, index) => {
          return (
            <tr key={index} className={''}>
              <td className={columnClass[0]} width='60%'>
                <div className='mb-2'>
                  <div className='font-weight-bold'>{inspectionItemsLookUp[inspectionItem.inspectionItemId].name}</div>
                </div>
                {inspectionItem.passed === false ? (
                  <textarea className='read-only-textarea' value={inspectionItem.comments} readOnly />
                ) : null}
              </td>
              <td className={columnClass[1]} width='20%'>
                <Checkbox
                  icon={<FontAwesomeIcon icon='far fa-square' fontSize={'40px'} />}
                  checkedIcon={<FontAwesomeIcon icon='fas fa-check-square' fontSize={'40px'} />}
                  checked={inspectionItem.passed === true}
                  readOnly
                />
              </td>
              <td className={columnClass[2]} width='20%'>
                <Checkbox
                  color='error'
                  icon={<FontAwesomeIcon icon='far fa-square' fontSize={'40px'} />}
                  checkedIcon={<FontAwesomeIcon icon='fas fa-times-square' fontSize={'40px'} />}
                  checked={inspectionItem.passed === false}
                  readOnly
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  }

  function generateTableHeaders() {
    return (
      <tr>
        <th className={columnClass[0]} width='60%'>
          Inspection Item
        </th>
        <th className={columnClass[1]} width='20%'>
          Pass (Aprobar)
        </th>
        <th className={columnClass[2]} width='20%'>
          Fail (Fallar)
        </th>
      </tr>
    );
  }

  return (
    <div>
      <Table headers={generateTableHeaders()} body={generateTableData()} />
      <textarea className='read-only-textarea' value={props.data.comment} readOnly />
    </div>
  );
}

export {InspectionReportDetailTable};
