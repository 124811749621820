import React, {useState, useCallback, useRef, useEffect} from 'react';
import {intRegex, emailRegex, handleAllowOnlyNumbers} from '../app/utils';
import {IntelliSearchSelect} from './IntelliSearchSelect';

// MUI
import {MuiChipsInput} from 'mui-chips-input';
import Chip from '@mui/material/Chip';
import {styled} from '@mui/material/styles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  Collapse,
  CircularProgress,
  TableRow,
  TableCell,
  Switch,
  Select,
  MenuItem,
  TextField,
  Autocomplete,
  Tooltip,
  Paper,
  Box,
  Divider,
} from '@mui/material';

export const NoBorderTableRow = styled(TableRow)(({theme}) => {
  return {
    '&.MuiTableRow-root': {
      borderBottom: 'none', // Remove bottom border
    },
    '& .MuiTableCell-root': {
      borderBottom: 'none', // Remove border from cells within the row
    },
  };
});
export const NoBorderTableCell = styled(TableCell)`
  borderBottom: 'none' '& td': {
      borderBottom: 'none'
    }
`;

export function CollapsableSection(props) {
  const open = props.collapseOpen[props.section];
  return (
    <div>
      <h5 className='mt-2' style={{color: 'black'}}>
        {props.title}
        {props.viewOnly && <span style={{fontWeight: 'bold'}}> - View Only</span>}
        <span
          className='ml-2 hoverPls'
          onClick={() => {
            props.setCollapseOpen((values) => {
              return {...values, [props.section]: !props.collapseOpen[props.section]};
            });
          }}
        >
          {open ? <ExpandLess /> : <ExpandMore />}
        </span>
      </h5>
      <Collapse in={open} timeout='auto' unmountOnExit>
        {props.children}
      </Collapse>
    </div>
  );
}

export function ModalSubmitting(props) {
  return (
    <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
      <CircularProgress className='mt-4 mx-auto d-block' size={80} />
      <span className='mt-3'>Submitting ...</span>
    </div>
  );
}

export function ViewOnlyText(props) {
  return (
    <h5 className='mt-2' style={{color: 'blue'}}>
      <i>View Only</i>
    </h5>
  );
}

export function SettingsSwitch(props) {
  return (
    <TableRow>
      <TableCell>{props.label}</TableCell>
      <TableCell>
        <Box className='justify-content-center justify-content-md-start' sx={{display: 'flex', alignItems: 'center'}}>
          <Switch
            disabled={props.disabled}
            name={props.setting}
            checked={props.formValues[props.setting]}
            onChange={props.handleChange}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
}

export function TargetText(props) {
  let value = '';
  try {
    value = props.formValues[props.outerTarget][props.innerTarget][props.targetName];
  } catch (error) {
    value = '';
  }

  let type = props.type ?? 'text';
  if (type == 'number') {
    type = 'text';
  }

  return (
    <TableRow>
      {props.showLabel && <TableCell>{props.label}</TableCell>}
      <TableCell>
        <TextField
          disabled={props.disabled}
          size='small'
          fullWidth
          type={type}
          placeholder={props.placeholder}
          InputProps={{inputProps: props.inputProps}}
          name={props.innerTarget}
          value={value}
          onChange={props.handleChange}
          onKeyDown={props.type == 'number' ? handleAllowOnlyNumbers : null}
        />
      </TableCell>
    </TableRow>
  );
}

export function SettingsText(props) {
  let type = props.type ?? 'text';
  if (type == 'number') {
    type = 'text';
  }

  return (
    <TableRow>
      <TableCell>{props.label}</TableCell>
      <TableCell>
        <TextField
          disabled={props.disabled}
          size='small'
          fullWidth
          label={props.textBoxLabel}
          type={type}
          placeholder={props.placeholder}
          InputProps={{inputProps: props.inputProps}}
          name={props.setting}
          value={props.formValues[props.setting]}
          onChange={props.handleChange}
          onKeyDown={props.type == 'number' ? handleAllowOnlyNumbers : null}
        />
      </TableCell>
    </TableRow>
  );
}

export function SettingsTextAndToggle(props) {
  let type = props.type ?? 'text';
  if (type == 'number') {
    type = 'text';
  }

  return (
    <TableRow>
      <TableCell>{props.label}</TableCell>
      <TableCell>
        <Switch
          disabled={props.disabled}
          name={props.toggleSetting}
          checked={props.formValues[props.toggleSetting]}
          onChange={props.handleChange}
        />
        <TextField
          disabled={props.disabled || !props.formValues[props.toggleSetting]}
          size='small'
          type={type}
          placeholder={props.placeholder}
          InputProps={{inputProps: props.inputProps}}
          label={props.textBoxLabel}
          name={props.setting}
          value={props.formValues[props.setting]}
          onChange={props.handleChange}
          onKeyDown={props.type == 'number' ? handleAllowOnlyNumbers : null}
        />
      </TableCell>
    </TableRow>
  );
}

export function SettingsSelect(props) {
  return (
    <TableRow>
      <TableCell>{props.label}</TableCell>
      <TableCell>
        <Select
          multiple={props.multiple}
          disabled={props.disabled}
          size='small'
          fullWidth
          name={props.setting}
          value={props.formValues[props.setting]}
          onChange={props.handleChange}
        >
          {props.options.map((option, idx) => {
            return (
              <MenuItem key={idx} value={option.value}>
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
      </TableCell>
    </TableRow>
  );
}

export function SettingsSearchSelect(props) {
  return (
    <TableRow>
      <TableCell width='30%'>{props.label}</TableCell>
      <TableCell>
        <IntelliSearchSelect
          multiple={props.multiple}
          disabled={props.disabled}
          name={props.setting}
          value={props.formValues[props.setting]}
          onChange={props.handleChange}
          options={props.options}
          allNoneEnabled
          error={props.error}
          helperText={props.helperText}
          returnFullEvent
          optionDisplayLimit={1}
          search={true}
          placeholder={props.placeholder}
        />
      </TableCell>
    </TableRow>
  );
}

export function SettingsChipInput(props) {
  // Variables for text inputType
  let type = 'text';
  let varType = 'string';
  let regex = null;
  let errorMsg = '';

  if (props.inputType == 'integer') {
    // Variables for integer inputType
    type = 'number';
    varType = 'int';
    regex = intRegex;
    errorMsg = 'The value must be an integer';
  } else if (props.inputType == 'email') {
    // Variables for email inputType
    type = 'email';
    varType = 'string';
    regex = emailRegex;
    errorMsg = 'Please enter an email address';
  }

  return (
    <TableRow>
      <TableCell>{props.label}</TableCell>
      <TableCell>
        <MuiChipsInput
          disabled={props.disabled}
          size='small'
          type={type}
          fullWidth
          hideClearAll
          disableDeleteOnBackspace
          name={props.setting}
          value={props.formValues[props.setting]}
          onChange={(value) => {
            let tempValues = [...value];
            if (varType == 'int') {
              tempValues = tempValues.map((val) => {
                return parseInt(val);
              });
            }

            // Create mock event to pass to actual event handler
            const mockEvent = {
              target: {
                name: props.setting,
                value: tempValues,
              },
            };
            props.handleChange(mockEvent);
          }}
          validate={(newValue) => {
            if (regex === null) {
              return true;
            }
            return {
              isError: !regex.test(newValue),
              textError: errorMsg,
            };
          }}
        />
      </TableCell>
    </TableRow>
  );
}

export function GeneralSearchSelect(props) {
  const [hoverAll, setHoverAll] = useState(false);
  const [hoverNone, setHoverNone] = useState(false);
  const [inputValue, setInputValue] = useState('');

  function handleAll() {
    const filteredOptionValues = props.options
      .filter((item) => {
        return item.label.toLowerCase().includes(inputValue.toLowerCase());
      })
      .map((opt) => {
        return opt.value;
      });

    const unionOptions = [...new Set([...filteredOptionValues, ...props.value])];
    const mockEvent = {
      target: {
        name: props.name,
        value: unionOptions,
      },
    };
    setInputValue('');
    props.handleChange(mockEvent);
  }

  function handleNone() {
    let resultOptions = [];
    const filteredOption = props.options;
    if (inputValue) {
      filteredOption.filter((item) => {
        return item.label.toLowerCase().includes(inputValue.toLowerCase());
      });
    }
    const filteredOptionValues = filteredOption.map((opt) => {
      return opt.value;
    });
    resultOptions = props.value.filter((value) => {
      return !filteredOptionValues.includes(value);
    });
    const mockEvent = {
      target: {
        name: props.name,
        value: resultOptions,
      },
    };
    setInputValue('');
    props.handleChange(mockEvent);
  }

  const handleAllRef = useRef(handleAll);
  const handleNoneRef = useRef(handleNone);

  useEffect(() => {
    handleAllRef.current = handleAll;
    handleNoneRef.current = handleNone;
  });

  return (
    <Autocomplete
      multiple={props.multiple}
      disabled={props.disabled}
      size='small'
      fullWidth
      limitTags={2}
      disableCloseOnSelect={props.multiple}
      name={props.name}
      value={props.value}
      options={props.options}
      getOptionDisabled={(option) => {
        return option?.disabled == true;
      }}
      onChange={(event, value, reason) => {
        if (reason == 'clear' && props.multiple) {
          handleNoneRef.current();
          return;
        }
        // Autocomplete will return option object, but initial values will be return just the value
        let valueAdjusted = '';
        if (Array.isArray(value)) {
          valueAdjusted = value.map((option) => {
            if (typeof option == 'object') {
              return option.value;
            } else {
              return option;
            }
          });
        } else if (value !== null) {
          if (typeof value == 'object') {
            valueAdjusted = value.value;
          } else {
            valueAdjusted = value;
          }
        }

        // Create mock event to pass to actual event handler
        const mockEvent = {
          target: {
            name: props.name,
            value: valueAdjusted,
          },
        };
        props.handleChange(mockEvent);
      }}
      getOptionLabel={(option) => {
        if (typeof option == 'object') {
          return option.label;
        } else {
          const optionObj = props.options.find((opt) => {
            return opt.value == option;
          });
          if (optionObj) {
            return optionObj.label;
          } else {
            return '';
          }
        }
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            value={inputValue}
            placeholder='Search...'
            error={props.error}
            helperText={props.helperText}
          />
        );
      }}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((value, index) => {
          const {key, ...tagProps} = getTagProps({index});
          const targetOption = props.options.find((option) => {
            return option.value == value;
          });
          if (targetOption) {
            return (
              <Chip size='small' key={key} label={targetOption.label} {...tagProps} disabled={targetOption.disabled} />
            );
          } else {
            return null;
          }
        });
      }}
      PaperComponent={useCallback((paperProps) => {
        const {children, ...restPaperProps} = paperProps;
        return (
          <Paper {...restPaperProps}>
            {/* Add Box for All/None Buttons */}
            {props.multiple && props.allNoneEnabled && (
              <React.Fragment>
                <Box
                  onMouseDown={(e) => {
                    e.preventDefault();
                  }} // prevent blur
                  sx={{
                    padding: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <button
                    onClick={() => {
                      handleAllRef.current();
                    }}
                    onMouseOut={() => {
                      setHoverAll(false);
                    }}
                    onMouseOver={() => {
                      setHoverAll(true);
                    }}
                    style={{
                      textShadow: 'none',
                      margin: '0 2px',
                      padding: '2px 6px',
                      lineHeight: '14px',
                      fontSize: '10px',
                      borderWidth: '1px',
                      borderRadius: '3px',
                      color: !hoverAll ? '#aaa' : '#62c462',
                      backgroundColor: 'transparent',
                      borderColor: !hoverAll ? '#ccc' : '#62c462',
                    }}
                  >
                    All
                  </button>
                  <button
                    onClick={() => {
                      handleNoneRef.current();
                    }}
                    onMouseOut={() => {
                      setHoverNone(false);
                    }}
                    onMouseOver={() => {
                      setHoverNone(true);
                    }}
                    style={{
                      textShadow: 'none',
                      margin: '0 2px',
                      padding: '2px 6px',
                      lineHeight: '14px',
                      fontSize: '10px',
                      borderWidth: '1px',
                      borderRadius: '3px',
                      color: !hoverNone ? '#aaa' : '#ee5f5b',
                      backgroundColor: 'transparent',
                      borderColor: !hoverNone ? '#ccc' : '#ee5f5b',
                    }}
                  >
                    None
                  </button>
                </Box>
                <Divider />
              </React.Fragment>
            )}
            {children}
          </Paper>
        );
      }, [])}
      isOptionEqualToValue={(option, value) => {
        return option === value || option.value === value;
      }}
    />
  );
}

export function InlineTooltip(props) {
  return (
    <Tooltip
      arrow
      placement='bottom-start'
      PopperProps={{
        disablePortal: true,
        popperOptions: {
          positionFixed: false,
          modifiers: {
            preventOverflow: {
              enabled: true,
            },
          },
        },
      }}
      sx={{
        maxWidth: '300px',
        display: 'inline-block',
        padding: 0,
      }}
      title={props.title}
    >
      <button className='btn btn-sm d-inline-flex m-0 p-1'>{props.icon}</button>
    </Tooltip>
  );
}
