import React, {useState} from 'react';
import {Autocomplete, ClickAwayListener, Checkbox, Box, Popper, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const StyledAutocompletePopper = styled('div')(({theme}) => {
  return {
    [`& .${autocompleteClasses.paper}`]: {
      boxShadow: 'none',
      margin: 0,
      color: 'inherit',
      fontSize: 13,
    },
    [`& .${autocompleteClasses.listbox}`]: {
      backgroundColor: '#fff',
      padding: 0,
      [`& .${autocompleteClasses.option}`]: {
        minHeight: 'auto',
        alignItems: 'flex-start',
        padding: 8,
        borderBottom: `1px solid  ${' #eaecef'}`,
        '&[aria-selected="true"]': {
          backgroundColor: 'transparent',
        },
        [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]: {
          backgroundColor: theme.palette.action.hover,
        },
      },
    },
    [`&.${autocompleteClasses.popperDisablePortal}`]: {
      position: 'relative',
    },
  };
});

function PopperComponent(props) {
  const {disablePortal, anchorEl, open, ...other} = props;
  return <StyledAutocompletePopper {...other} />;
}

PopperComponent.propTypes = {
  anchorEl: PropTypes.any,
  disablePortal: PropTypes.bool,
  open: PropTypes.bool.isRequired,
};

function IntelliHiddenFilterSelect({
  name,
  id,
  value,
  onChange,
  options,
  multiple,
  showFilter,
  sortOnClickHandler = null,
  title,
  large = false,
  placeholder = 'Type in to search...',
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [hoverAll, setHoverAll] = useState(false);
  const [hoverNone, setHoverNone] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const open = Boolean(anchorEl);

  const handleInputChange = (event, newValue) => {
    setInputValue(newValue);
  };

  const handleClickAway = (event) => {
    if (event.target.id.includes(id)) {
      return;
    }
    setAnchorEl(null);
  };

  function handleAllButtonClicked() {
    const allSelected = options
      .filter((item) => {
        return item.text.toLowerCase().includes(inputValue.toLowerCase());
      })
      .map((opt) => {
        return opt.value;
      });
    const mockEvent = {
      target: {
        name: name,
        value: allSelected,
      },
    };
    onChange(mockEvent);
  }
  function handleNoneButtonClicked() {
    const mockEvent = {
      target: {
        name: name,
        value: [],
      },
    };
    onChange(mockEvent);
  }

  return (
    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        {showFilter && (
          <button
            className='btn btn-transparent btn-sm'
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
          >
            <FontAwesomeIcon
              icon='fas fa-filter'
              size={large ? '2x' : '1x'}
              transform={large ? 'shrink-3' : ''}
              style={{
                color: value.length && value.length > 0 ? '#4e73df' : '#888888',
              }}
            />
          </button>
        )}
        {title}
        {sortOnClickHandler && (
          <FontAwesomeIcon onClick={sortOnClickHandler} style={{'margin': 5, cursor: 'pointer'}} icon='fa fa-sort' />
        )}
        <Popper id={id} open={open} anchorEl={anchorEl} placement='bottom-start' sx={{backgroundColor: '#fff'}}>
          <ClickAwayListener onClickAway={handleClickAway}>
            <div>
              <Autocomplete
                fullWidth
                multiple={multiple}
                disableCloseOnSelect
                renderTags={() => {
                  return null;
                }}
                noOptionsText='No Options Available'
                id={id}
                options={options}
                getOptionLabel={(option) => {
                  if (typeof option == 'object') {
                    return option.text;
                  } else {
                    const optionObj = options.find((opt) => {
                      return opt.value == option;
                    });
                    if (optionObj) {
                      return optionObj.text;
                    } else {
                      return '';
                    }
                  }
                }}
                value={value}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                open={open}
                onChange={(event, value) => {
                  // Autocomplete will return option object, but initial values will be return just the value
                  let valueAdjusted = '';
                  if (Array.isArray(value)) {
                    valueAdjusted = value.map((option) => {
                      if (typeof option == 'object') {
                        return option.value;
                      } else {
                        return option;
                      }
                    });
                  } else if (value !== null) {
                    if (typeof value == 'object') {
                      valueAdjusted = value.value;
                    } else {
                      valueAdjusted = value;
                    }
                  }

                  // Create mock event to pass to actual event handler
                  const mockEvent = {
                    target: {
                      name: name,
                      value: valueAdjusted,
                    },
                  };
                  onChange(mockEvent);
                }}
                renderOption={(props, option) => {
                  return (
                    <li {...props}>
                      <Checkbox
                        id={id}
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8, padding: 2}}
                        size='small'
                        checked={value.includes(option.value)}
                      />
                      {option.text}
                    </li>
                  );
                }}
                renderInput={(params) => {
                  return (
                    <Box ref={params.InputProps.ref} sx={{width: '250px'}}>
                      <TextField
                        inputProps={params.inputProps}
                        sx={{padding: 1}}
                        size='small'
                        autoFocus
                        placeholder={placeholder}
                      />
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        <button
                          onClick={() => {
                            handleAllButtonClicked();
                          }}
                          onMouseOut={() => {
                            setHoverAll(false);
                          }}
                          onMouseOver={() => {
                            setHoverAll(true);
                          }}
                          style={{
                            textShadow: 'none',
                            margin: '0 2px',
                            padding: '2px 6px',
                            lineHeight: '14px',
                            fontSize: '10px',
                            borderWidth: '1px',
                            borderRadius: '3px',
                            color: !hoverAll ? '#aaa' : '#62c462',
                            backgroundColor: 'transparent',
                            borderColor: !hoverAll ? '#ccc' : '#62c462',
                          }}
                        >
                          All
                        </button>

                        <button
                          onClick={() => {
                            handleNoneButtonClicked();
                          }}
                          onMouseOut={() => {
                            setHoverNone(false);
                          }}
                          onMouseOver={() => {
                            setHoverNone(true);
                          }}
                          style={{
                            textShadow: 'none',
                            margin: '0 2px',
                            padding: '2px 6px',
                            lineHeight: '14px',
                            fontSize: '10px',
                            borderWidth: '1px',
                            borderRadius: '3px',
                            color: !hoverNone ? '#aaa' : '#ee5f5b',
                            backgroundColor: 'transparent',
                            borderColor: !hoverNone ? '#ccc' : '#ee5f5b',
                          }}
                        >
                          None
                        </button>
                      </div>
                    </Box>
                  );
                }}
                slots={{
                  popper: PopperComponent,
                }}
                isOptionEqualToValue={(option, value) => {
                  return option.value === value;
                }}
              />
            </div>
          </ClickAwayListener>
        </Popper>
      </Box>
    </Box>
  );
}

export {IntelliHiddenFilterSelect};
