import {CompositeLayer} from '@deck.gl/core';
import {IconLayer} from '@deck.gl/layers';
import Supercluster from 'supercluster';

function getVehicleIcon(markerData, machineIconMapping, machineIconImageMapping) {
  // Determine icon based on vehicle type
  let iconUrl = machineIconMapping[markerData.machineType];

  // Add outline to icon
  if (markerData.selected) {
    const img = machineIconImageMapping[markerData.machineType];
    const offset = 3; // Offset for outline
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = img.width + offset * 2;
    canvas.height = img.height + offset * 2;
    context.drawImage(img, offset, offset);

    // Loop to offset shadow in both x and y direction to generate outline
    for (let x = -offset; x <= offset; x++) {
      for (let y = -offset; y <= offset; y++) {
        context.shadowColor = '#ff9900';
        context.shadowBlur = 0;
        context.shadowOffsetX = x;
        context.shadowOffsetY = y;
        context.drawImage(img, offset, offset);
      }
    }
    iconUrl = canvas.toDataURL();
  }

  return {
    url: iconUrl,
    width: 256,
    height: 256,
  };
}

export default class IconClusterLayer extends CompositeLayer {
  shouldUpdateState({changeFlags}) {
    return changeFlags.somethingChanged;
  }

  updateState({props, oldProps, changeFlags}) {
    const rebuildIndex = changeFlags.dataChanged || props.sizeScale !== oldProps.sizeScale;

    if (rebuildIndex) {
      const index = new Supercluster({
        maxZoom: 20,
        radius: props.sizeScale * Math.sqrt(props.zoom / 2),
      });
      index.load(
        // @ts-ignore Supercluster expects proper GeoJSON feature
        props.data.map((d) => {
          return {
            geometry: {coordinates: props.getPosition(d)},
            properties: d,
          };
        })
      );
      this.setState({index});
    }

    const z = props.zoom;
    if (rebuildIndex || props.zoom != oldProps.zoom) {
      const data = this.state.index.getClusters([-180, -90, 180, 90], z);
      const selectedMarkerIndex = data.findIndex((markerClusterObj) => {
        return !markerClusterObj?.properties?.cluster && markerClusterObj?.properties?.selected;
      });
      if (selectedMarkerIndex) {
        // push selected vehicle's marker to the end of array to ensure it renders on top of other markers
        const selectedMarker = data.splice(selectedMarkerIndex, 1);
        data.push(...selectedMarker);
      }
      this.setState({
        data: data,
        z,
      });
    }
  }

  getPickingInfo({info, mode}) {
    const pickedObject = info.object?.properties;

    if (pickedObject) {
      let objects;
      if (pickedObject.cluster && mode !== 'hover') {
        objects = this.state.index.getLeaves(pickedObject.cluster_id, 'Infinity').map((f) => {
          return f.properties;
        });
      }
      return {...info, object: pickedObject, objects};
    }
    return {...info, object: undefined};
  }

  getClusterIcon(data) {
    const clusterPointsCount = data.properties.point_count || 1;
    const selectedIncluded = this.state.index.getLeaves(data.properties.cluster_id, 'Infinity').some((markerObj) => {
      return markerObj.properties.selected;
    });
    const clusterNumberColor = selectedIncluded ? '#ff9900' : '#000000';
    const svg = window.btoa(`
      <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" width="280" height="280">
        <circle cx="140" cy="140" opacity=".6" r="90" />
        <circle cx="140" cy="140" opacity=".3" r="110" />
        <circle cx="140" cy="140" opacity=".2" r="120" />
        <circle cx="140" cy="140" opacity=".1" r="150" />
        <text 
          x="50%" y="50%" dominant-baseline="middle" text-anchor="middle"
          fill="${clusterNumberColor}"
          style="font-size: 75px; font-family: sans-serif; font-weight: bold;"
        >${clusterPointsCount}</text>
      </svg>`);

    const svg64 = `data:image/svg+xml;base64,${svg}`;

    const iconObj = {
      url: svg64,
      height: 256,
      width: 256,
    };
    return iconObj;
  }

  renderLayers() {
    const {data} = this.state;
    const {machineIconMapping, machineIconImageMapping} = this.props;

    return new IconLayer(
      {
        data: data,
        pickable: true,
        visible: true,

        getPosition: (d) => {
          return d.geometry.coordinates;
        },
        getIcon: (d) => {
          if (d) {
            if (!d.properties.cluster) {
              return getVehicleIcon(d.properties, machineIconMapping, machineIconImageMapping);
            } else {
              return this.getClusterIcon(d);
            }
          }
        },
        getAngle: (d) => {
          if (!d.properties.cluster) {
            return d.properties.bearing;
          }
          return 0;
        },
        getSize: 55,
      },
      this.getSubLayerProps({
        id: 'icon',
      })
    );
  }
}
