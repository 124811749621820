import React, {useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import {DateTime} from 'luxon';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {useNavigate} from 'react-router-dom';
import {
  setServicesData,
  setOperationsData,
  setTaskConfigs,
  setInspectionsData,
  setLoading,
  setInstallTypes,
  setDatesCache,
  setDaysDiff,
  setInOutTime,
  setCompareByInOutTime,
  setVehicles,
  setVehicleLabels,
  setInspectionsDict,
  setkpiLandingPageOn,
  updateActiveDevices,
  setSelectedKPI,
  setScorecards,
  setSelectedScorecardId,
  setSelectedScorecardPageIndex,
  setStalestGenTime,
  setStatsviewRangeSelected,
} from './dashboardSlice';

// Declare abort controllers
let abortController;

function Menu(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const datesCache = useSelector((state) => {
    return state.dashboard.dates;
  });
  const operationsData = useSelector((state) => {
    return state.dashboard.operationsData;
  });
  const inspectionsData = useSelector((state) => {
    return state.dashboard.inspectionsData;
  });
  const servicesData = useSelector((state) => {
    return state.dashboard.servicesData;
  });
  const loading = useSelector((state) => {
    return state.dashboard.loading;
  });
  const selectedKPI = useSelector((state) => {
    return state.dashboard.selectedKPI;
  });

  const keyRef = useRef(DateTime.now());
  const [searchParams, setSearchParams] = useSearchParams();
  const [shouldUpdateData, setShouldUpdateData] = useState(true);

  // We have to set dates using the start time of first active task to NOW
  const [dates, setDates] = useState({
    start: null,
    end: null,
    max: null,
  });

  useEffect(() => {
    if (dates.start == null || dates.end == null) {
      // Null values means initilization still needs to occur
      initDates();
    }
  }, []);

  useEffect(async () => {
    if (shouldUpdateData) {
      await loadReport();
    }
  }, [dates]);

  useEffect(async () => {
    if (dates.start != null || dates.end != null) {
      const datesStartISO = dates.start.toISO();
      const datesEndISO = dates.end.toISO();
      if (datesStartISO != datesCache.start || datesEndISO != datesCache.end) {
        // set new dates to reload data if date cached is updated (from scorecard page), and is different from date range of data fetched.
        setShouldUpdateData(true);
        setDates((values) => {
          const newDateObject = {
            ...values,
            start: DateTime.fromISO(datesCache.start).setZone(customerSettings.general.timeZone, {}),
            end: DateTime.fromISO(datesCache.end).setZone(customerSettings.general.timeZone, {}),
          };
          return newDateObject;
        });
      }
    }
  }, [datesCache]);

  async function loadReport() {
    dispatch(setLoading(true));

    if (dates.start != null && dates.end != null) {
      window.history.replaceState(
        null,
        '',
        window.location.origin +
          `/statsview?start=${encodeURIComponent(dates.start)}&end=${encodeURIComponent(dates.end)}`
      );
      const getDevicesRequest = fetch('/getDevices', {cache: 'no-store'});
      if (typeof abortController !== 'undefined') {
        abortController.abort();
      }
      abortController = new AbortController();
      const operationsDataRequest = getOperationsData(dates, abortController);
      const inspectionsDataRequest = getInspectionsData(dates, abortController);
      const servicesRequest = getServicesData(dates, abortController);
      const installTypeRequest = getInstallTypes(customerSettings);
      const vehicleLabelsRequest = await fetch('/getVehicleLabels', {cache: 'no-store'});

      const [
        operationsDataResponse,
        servicesResponse,
        installTypeResponse,
        getDevicesResponse,
        vehicleLabelsResponse,
        inspectionsResponse,
      ] = await Promise.all([
        operationsDataRequest,
        servicesRequest,
        installTypeRequest,
        getDevicesRequest,
        vehicleLabelsRequest,
        inspectionsDataRequest,
      ]);

      if (typeof operationsDataResponse.success === 'undefined' || operationsDataResponse.success == false) {
        navigate('/error', {state: {errorMsg: 'Error retrieving operations data please try again.'}});
        return;
      }

      dispatch(
        setInOutTime({
          inFieldTime: operationsDataResponse.data.inFieldTotal,
          outFieldTime: operationsDataResponse.data.outFieldTotal,
        })
      );

      const vehicleLabels = await vehicleLabelsResponse.json();
      const devices = await getDevicesResponse.json();

      dispatch(setVehicleLabels(vehicleLabels));
      dispatch(updateActiveDevices(devices));

      dispatch(setStalestGenTime(operationsDataResponse.data.stalestGenTime));
      dispatch(setOperationsData(operationsDataResponse.data.operationsReport));
      dispatch(setInspectionsData(inspectionsResponse.data));
      dispatch(setServicesData(servicesResponse));
      dispatch(setInstallTypes(installTypeResponse));
      dispatch(setTaskConfigs(operationsDataResponse.data.taskConfigIdDict));
      dispatch(setInspectionsDict(operationsDataResponse.data.inspectionItemsDict));
      dispatch(setVehicles(operationsDataResponse.data.vehicleSNDict));

      const dayDiff = Math.round((new DateTime(dates.end) - new DateTime(dates.start)) / (1000 * 60 * 60 * 24));
      dispatch(setDaysDiff(dayDiff));
    }

    dispatch(setStatsviewRangeSelected(true));
    dispatch(setLoading(false));
  }

  async function getScorecards() {
    // TODO REPLACE END DATE FROM THE DATA SELECTOR

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      cache: 'no-store',
    };
    const response = await fetch('/statsview/getScorecards', options);
    const result = await response.json();
    return result.data;
  }

  function datePickerRender() {
    let buttonDisplayString = '';

    if (
      typeof dates.start !== 'undefined' &&
      typeof dates.end !== 'undefined' &&
      dates.start != null &&
      dates.end != null
    ) {
      buttonDisplayString = ` ${dates.start.toFormat('L/d/yy')} ` + `- ${dates.end.toFormat('L/d/yy')} `;
      return (
        <DateRangePicker
          key={keyRef.current}
          onApply={dateSelection}
          initialSettings={{
            startDate: dates.start.toFormat('MM/dd/yyyy'),
            endDate: dates.end.toFormat('MM/dd/yyyy'),
            maxDate: dates.max.toFormat('MM/dd/yyyy'),
            // timePicker: false,
            // timePicker24Hour: false,
            locale: {
              format: 'MM/DD/YYYY',
            },
          }}
          disabled={loading}
        >
          <button className='btn border-dark btn-light col-12 cropview-menu-text h-100'>
            <i className='fas fa-calendar-alt' />
            {buttonDisplayString}
          </button>
        </DateRangePicker>
      );
    }
  }

  function dateSelection(event, picker) {
    const [todayStart, todayEnd] = getTodayDates();
    const yesterdayStart = todayStart.plus({days: -1});
    const yesterdayEnd = todayEnd.plus({days: -1});

    // Clear the filters
    if (Object.prototype.hasOwnProperty.call(props, 'setFilterDefault')) {
      props.setFilterDefault();
    }

    const startDate = DateTime.fromISO(picker.startDate.toISOString()).setZone(customerSettings.general.timeZone, {
      keepLocalTime: true,
    });
    const endDate = DateTime.fromISO(picker.endDate.toISOString())
      .set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 999,
      })
      .setZone(customerSettings.general.timeZone, {keepLocalTime: true});

    const newDataDates = {
      start: startDate,
      end: endDate,
      max: yesterdayEnd,
    };
    setShouldUpdateData(true);
    setDates(newDataDates);
    dispatch(setSelectedScorecardId(null));
    dispatch(setSelectedScorecardPageIndex(null));
    dispatch(setDatesCache({start: newDataDates.start.toISO(), end: newDataDates.end.toISO()}));
  }

  async function initDates() {
    const scorecardsRequest = getScorecards();
    const [scorecardsResponse] = await Promise.all([scorecardsRequest]);
    dispatch(setScorecards(scorecardsResponse));

    // Determine if we have cached dates
    const cachedStart = datesCache.start != null ? DateTime.fromISO(datesCache.start) : null;
    const cachedEnd = datesCache.end != null ? DateTime.fromISO(datesCache.end) : null;

    // Init using current shift
    const [todayStart, todayEnd] = getTodayDates();
    const yesterdayStart = todayStart.plus({days: -1});
    const yesterdayEnd = todayEnd.plus({days: -1});

    // Check if values were inputted through url
    const startParam = cachedStart == null ? decodeURIComponent(searchParams.get('start')) : cachedStart;
    const endParam = cachedEnd == null ? decodeURIComponent(searchParams.get('end')) : cachedEnd;

    let startTime = DateTime.fromISO(startParam).setZone(customerSettings.general.timeZone);
    let endTime = DateTime.fromISO(endParam).setZone(customerSettings.general.timeZone);

    const urlSearch = window.location.search;
    if (urlSearch.includes('scorecardId')) {
      const scorecardId = window.location.search.split('=')[1];

      let foundCard = null;
      for (let i = 0; i < scorecardsResponse.length; i++) {
        const resp = scorecardsResponse[i];
        const scorecard = resp.data;

        if (scorecard.id == scorecardId) {
          foundCard = scorecard;
          if (scorecard.pages.length > 0) {
            dispatch(setSelectedScorecardPageIndex(0));
          }
          break;
        }
      }

      startTime = DateTime.fromISO(foundCard.startDate).setZone(customerSettings.general.timeZone);
      endTime = DateTime.fromISO(foundCard.endDate).setZone(customerSettings.general.timeZone);

      dispatch(setSelectedScorecardId(scorecardId));
      dispatch(setSelectedKPI('Scorecards'));
      dispatch(setkpiLandingPageOn(false));
    }

    // If cache dates are available, that means data was previously loaded into redux and Menu unmounted
    let updateData = !(cachedStart && cachedEnd && inspectionsData && operationsData && servicesData);

    // Ensure the url values are valid or else they need to be re-initilizationed
    if (!startTime.isValid || !endTime.isValid || (startTime >= yesterdayEnd && endTime >= yesterdayEnd)) {
      // If dates are after the lastUploadTime, or the values are invalid, set to the 24hr before
      startTime = yesterdayStart;
      endTime = yesterdayEnd;
    } else if (startTime.isValid && endTime.isValid && startTime < yesterdayEnd && endTime >= yesterdayEnd) {
      // Otherwise the dates are valid, check if the end time is after the lastUploadTime
      endTime = yesterdayEnd;
    }

    if (endTime >= yesterdayEnd) {
      // Always Force Reload Data when today is selected
      updateData = true;
    }

    setShouldUpdateData(updateData);

    keyRef.current = DateTime.now();
    const newDates = {
      start: startTime,
      end: endTime,
      max: yesterdayEnd,
    };
    dispatch(setStatsviewRangeSelected(true));
    dispatch(setDatesCache({start: startTime.toISO(), end: endTime.toISO()}));
    setDates(newDates);
  }

  function getTodayDates() {
    // Get startDate for today
    const now = DateTime.local({zone: customerSettings.general.timeZone});
    const todayStart = now.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    // Get endDate for today
    const todayEnd = todayStart.plus({days: 1, milliseconds: -1});

    return [todayStart, todayEnd];
  }

  return <React.Fragment>{selectedKPI != 'Scorecards' && datePickerRender()}</React.Fragment>;
}

async function getInspectionsData(datesParam, abortControllerParam) {
  const queryData = {
    start: datesParam.start.toISO(),
    end: datesParam.end.toISO(),
  };

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    cache: 'no-store',
    signal: abortControllerParam.signal,
  };
  const response = await fetch(
    `/statsview/getInspectionsData?start=${encodeURIComponent(queryData.start)}&end=${encodeURIComponent(
      queryData.end
    )}`,
    options
  );
  const result = await response.json();
  return result;
}

async function getOperationsData(datesParam, abortControllerParam) {
  const queryData = {
    start: datesParam.start.toISO(),
    end: datesParam.end.toISO(),
  };

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    cache: 'no-store',
    signal: abortControllerParam.signal,
  };
  const response = await fetch(
    `/statsview/getOperationsData?start=${encodeURIComponent(queryData.start)}&end=${encodeURIComponent(
      queryData.end
    )}`,
    options
  );
  const result = await response.json();
  return result;
}

async function getServicesData(datesParam, abortControllerParam) {
  // TODO REPLACE END DATE FROM THE DATA SELECTOR
  const queryData = {
    start: datesParam.start.toISO(),
    end: datesParam.end.toISO(),
  };

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    cache: 'no-store',
    signal: abortControllerParam.signal,
  };
  const response = await fetch(
    `/statsview/getServiceData?start=${encodeURIComponent(queryData.start)}&end=${encodeURIComponent(queryData.end)}`,
    options
  );
  const result = await response.json();
  return result.data;
}

async function getInstallTypes(customerSettingsParam) {
  if (
    typeof customerSettingsParam.dashboard.kpiIdleInstallStatusWarningDisplay !== 'undefined' &&
    customerSettingsParam.dashboard.kpiIdleInstallStatusWarningDisplay === false
  ) {
    return {};
  }

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    cache: 'no-store',
    signal: abortController.signal,
  };
  const response = await fetch(`/getDeviceInstallTypes`, options);
  const result = await response.json();
  return result;
}

export {Menu, getOperationsData, getServicesData, getInspectionsData, getInstallTypes};
