import {createSlice} from '@reduxjs/toolkit';
import {isEqual} from 'lodash';
import packageInfo from '../package.json';
import {sortVehicleNamesHelper} from './app/utils';

const defaultAuthStatus = {
  loggedIn: false,
  cabviewEnabled: false,
  cropviewEnabled: false,
  shopviewEnabled: false,
  inspectionEnabled: false,
  zoneInfoEnabled: false,
  dashboardEnabled: false,
  alertviewEnabled: false,
};

const appSlice = createSlice({
  name: 'appSlice',
  initialState: {
    authenticationStatus: defaultAuthStatus,
    customerName: '',
    customerSettings: {},
    customerManagementAccess: {},
    customerViewAccess: {},
    userName: '',
    userSettings: {},
    auth: null,
    initialized: false,
    reloadApp: false,
    vehicles: [],
    vehicleLabels: [],
    appFilters: {},
  },
  reducers: {
    updateCustomerInfo(state, action) {
      const customerInfo = action.payload;

      if (!isEqual(state.customerName, customerInfo.name)) {
        state.customerName = customerInfo.name;
      }
      if (!isEqual(state.customerSettings, customerInfo.settings)) {
        state.customerSettings = customerInfo.settings;
      }
      if (!isEqual(state.customerManagementAccess, customerInfo.managementAccess)) {
        state.customerManagementAccess = customerInfo.managementAccess;
      }
      if (!isEqual(state.customerViewAccess, customerInfo.viewAccess)) {
        state.customerViewAccess = customerInfo.viewAccess;
      }
    },
    updateUserAccountInfo(state, action) {
      const userAccount = action.payload;

      if (!isEqual(state.userSettings, userAccount.settings)) {
        state.userSettings = userAccount.settings;
      }

      // Setup users unit system
      // If users unit system is default, use customer unit system
      if (state.userSettings.general.unitsLength == 'default') {
        state.userSettings.general.unitsLength = state.customerSettings.general.unitsLength;
      }
      if (state.userSettings.general.unitsArea == 'default') {
        state.userSettings.general.unitsArea = state.customerSettings.general.unitsArea;
      }
    },
    setAuthStatus(state, action) {
      const authenticationStatus = action.payload;

      if (!isEqual(state.authenticationStatus, authenticationStatus)) {
        state.authenticationStatus = authenticationStatus;
      }
    },
    setAuth(state, action) {
      const auth = action.payload;
      state.auth = auth;
    },
    setInitialized(state, action) {
      const initialized = action.payload;
      state.initialized = initialized;
    },
    setReloadApp(state, action) {
      state.reloadApp = action.payload;
    },
    updateVehicles(state, action) {
      state.vehicles = action.payload;
    },
    updateVehicleLabels(state, action) {
      state.vehicleLabels = action.payload;
    },
    updateAppFilters(state, action) {
      state.appFilters = action.payload;
    },
  },
});

function checkAuthentication() {
  return async (dispatch, getState) => {
    try {
      const checkAuthResponse = await fetch(`/checkAuthentication?v=${packageInfo.version}`, {cache: 'no-store'});
      const checkAuthResult = await checkAuthResponse.json();

      dispatch(appSlice.actions.setReloadApp(checkAuthResult.reloadApp));
      dispatch(appSlice.actions.setAuthStatus(checkAuthResult));
    } catch (error) {
      console.error(error);
      dispatch(appSlice.actions.setAuthStatus(defaultAuthStatus));
    }
  };
}

function getCustomerInfo() {
  return async (dispatch, getState) => {
    try {
      const customerInfoResponse = await fetch('/getCustomerInfo', {cache: 'no-store'});
      const customerInfo = await customerInfoResponse.json();
      dispatch(appSlice.actions.updateCustomerInfo(customerInfo));
    } catch (error) {
      dispatch(appSlice.actions.updateCustomerInfo({}));
    }
  };
}

function getUserAccountInfo() {
  return async (dispatch, getState) => {
    try {
      const userAccountResponse = await fetch('/getUserAccount', {cache: 'no-store'});
      const userAccount = await userAccountResponse.json();

      dispatch(appSlice.actions.updateUserAccountInfo(userAccount));
    } catch (error) {
      dispatch(appSlice.actions.updateUserAccountInfo({}));
    }
  };
}

function getCustomerAndUserInfo() {
  return async (dispatch, getState) => {
    try {
      const customerInfoResponse = await fetch('/getCustomerInfo', {cache: 'no-store'});
      const customerInfo = await customerInfoResponse.json();
      dispatch(appSlice.actions.updateCustomerInfo(customerInfo));
    } catch (error) {
      dispatch(appSlice.actions.updateCustomerInfo({}));
    }

    try {
      const userAccountResponse = await fetch('/getUserAccount', {cache: 'no-store'});
      const userAccount = await userAccountResponse.json();

      dispatch(appSlice.actions.updateUserAccountInfo(userAccount));
    } catch (error) {
      dispatch(appSlice.actions.updateUserAccountInfo({}));
    }
  };
}

function getGlobalVehicleData() {
  return async (dispatch, getState) => {
    // Get vehicle information
    const getVehiclesRequest = fetch('/getVehiclesNoAppFilters', {cache: 'no-store'});
    const getVehicleLabelsRequest = fetch('/getVehicleLabels', {cache: 'no-store'});
    const getFilterSessionDataRequest = fetch('/getFilterSessionData', {cache: 'no-store'});

    // Make all calls
    const [getVehiclesResponse, getVehicleLabelsResponse, getFilterSessionDataResponse] = await Promise.all([
      getVehiclesRequest,
      getVehicleLabelsRequest,
      getFilterSessionDataRequest,
    ]);

    // Get vehicles collection
    const vehicles = await getVehiclesResponse.json();
    const vehicleLabels = await getVehicleLabelsResponse.json();
    const filterSessionData = await getFilterSessionDataResponse.json();

    vehicles.sort((a, b) => {
      return sortVehicleNamesHelper(a.name, b.name);
    });

    dispatch(appSlice.actions.updateVehicles(vehicles));
    dispatch(appSlice.actions.updateVehicleLabels(vehicleLabels));
    dispatch(appSlice.actions.updateAppFilters(filterSessionData));
  };
}

export {getCustomerAndUserInfo, getCustomerInfo, getUserAccountInfo, getGlobalVehicleData, checkAuthentication};
export const {
  updateCustomerInfo,
  updateUserAccountInfo,
  updateVehicles,
  setAuthStatus,
  setAuth,
  setInitialized,
  updateAppFilters,
} = appSlice.actions;
export default appSlice.reducer;
