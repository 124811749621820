import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {DateTime} from 'luxon';
import {setTriggerRefreshData} from '../features/Statsview/dashboardSlice';

function DownStatusIcon(props) {
  const dispatch = useDispatch();
  const location = useLocation();

  const lastCropviewTimeStamp = useSelector((state) => {
    return state.cropview.lastTripTaskEndTime;
  });
  const processingInProgress = useSelector((state) => {
    return state.cropview.processingInProgress;
  });
  const refreshRequired = useSelector((state) => {
    return state.cropview.refreshRequired;
  });
  const stalestGenTime = useSelector((state) => {
    return state.dashboard.stalestGenTime;
  });
  const triggerRefreshData = useSelector((state) => {
    return state.dashboard.triggerRefreshData;
  });
  const statsviewRangeSelected = useSelector((state) => {
    return state.dashboard.statsviewRangeSelected;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });

  const defaultStatusToolTip = `Some services are not available, 
    you may experience some interruptions using the platform`;
  const [serviceStatusActive, setServiceStatusActive] = useState(false);
  const [serviceStatusTooltip, setServiceStatusTooltip] = useState(defaultStatusToolTip);
  const [downStatusUpdateToggle, setdownStatusUpdateToggle] = useState(false);
  const [downStatusProcessed, setDownStatusProcessed] = useState(false);
  const [lastDataTimeStampString, setLastDataTimeStampString] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(async () => {
    const downStatusProcessedTemp = await getDownStatus();
    setDownStatusProcessed(downStatusProcessedTemp);

    const lastDataTimeStampStringTemp = await getLastDataTimeStampString();
    setLastDataTimeStampString(lastDataTimeStampStringTemp);
  }, [downStatusUpdateToggle, refreshRequired, lastCropviewTimeStamp, processingInProgress]);

  useEffect(async () => {
    let newToolTip;

    if (location.pathname == '/cropview') {
      if (processingInProgress) {
        newToolTip = (
          <div>
            {'Data reprocessing in progress for your selected time range.\nDisplayed data may be subject to change.'}
          </div>
        );
        setServiceStatusActive(true);
      } else {
        let refreshText;
        if (refreshRequired) {
          refreshText = (
            <>
              <div>Cropview has not been refreshed in 1 hour</div>
              <div>Please refresh for latest updates</div>
            </>
          );
          setServiceStatusActive(true);
        } else {
          setServiceStatusActive(false);
        }

        let displayTimeString = 'Unavailable';
        if (lastCropviewTimeStamp) {
          displayTimeString = DateTime.fromISO(lastCropviewTimeStamp)
            .setZone(customerSettings.general.timeZone)
            .toFormat('MM/dd/yyyy, hh:mm:ss a ZZZZ');
        }
        const lastCropviewTimeText = (
          <>
            <div>Last data timestamp:</div>
            <div>{displayTimeString}</div>
          </>
        );

        newToolTip = (
          <div>
            {refreshText}
            {lastCropviewTimeText}
          </div>
        );
      }
    } else if (location.pathname == '/statsview' && statsviewRangeSelected) {
      let downText;
      if (downStatusProcessed.geotabDownStatus === true || downStatusProcessed.faunaDownStatus === true) {
        downText = <div>{defaultStatusToolTip}</div>;
        setServiceStatusActive(true);
      } else {
        setServiceStatusActive(false);
      }

      let lastDataText;
      if (!props.login || props.login !== true) {
        // Append Last data timestamp to Tooltip message
        lastDataText = (
          <>
            <div>Last data timestamp:</div>
            <div>{lastDataTimeStampString || 'Unavailable'}</div>
          </>
        );
      }

      let stalestTimeString;
      if (typeof stalestGenTime !== 'string') {
        stalestTimeString = 'Unavailable';
      } else {
        stalestTimeString = DateTime.fromISO(stalestGenTime)
          .setZone(customerSettings.general.timeZone)
          .toFormat('MM/dd/yyyy, hh:mm:ss a ZZZZ');
      }

      const statsviewText = (
        <>
          <div>StatsView refreshed at:</div>
          <div>
            {stalestTimeString}
            <button style={innerButtonStyle} onClick={statsviewRefreshClick} disabled={triggerRefreshData}>
              <FontAwesomeIcon
                icon={triggerRefreshData ? 'fas fa-spinner' : 'fas fa-refresh'}
                style={{color: '#ffffff'}}
              />
            </button>
          </div>
        </>
      );

      newToolTip = (
        <div>
          {downText}
          {lastDataText}
          {statsviewText}
        </div>
      );
    } else {
      let downText;
      if (downStatusProcessed.geotabDownStatus === true || downStatusProcessed.faunaDownStatus === true) {
        downText = <div>{defaultStatusToolTip}</div>;
        setServiceStatusActive(true);
      } else {
        // reset the tooltip display and string
        newToolTip = ``;
        setServiceStatusActive(false);
      }

      let lastDataText;
      if (!props.login || props.login !== true) {
        // Append Last data timestamp to Tooltip message
        lastDataText = (
          <>
            <div>Last data timestamp:</div>
            <div>{lastDataTimeStampString || 'Unavailable'}</div>
          </>
        );
      }

      newToolTip = (
        <div>
          {downText}
          {lastDataText}
        </div>
      );
    }

    // Update Tooltip Message in state
    setServiceStatusTooltip(newToolTip);
  }, [
    downStatusProcessed,
    lastDataTimeStampString,
    refreshRequired,
    lastCropviewTimeStamp,
    processingInProgress,
    stalestGenTime,
    triggerRefreshData,
  ]);

  async function getLastDataTimeStampString() {
    const serviceStatus = await getServiceStatus();
    // Default Latest Data timestamp string for display
    let displayTimeString = 'None';

    if (serviceStatus.geotabStatus.lastDataTime != null && serviceStatus.faunaStatus.lastDataTime != null) {
      // If Both services last data time is available
      // Parse Last data times
      const geotabLastDataLocalTime = new Date(serviceStatus.geotabStatus.lastDataTime);
      const faunaLastDataLocalTime = new Date(serviceStatus.faunaStatus.lastDataTime);

      // Compare time and use latest time for display
      if (geotabLastDataLocalTime > faunaLastDataLocalTime) {
        displayTimeString = DateTime.fromISO(serviceStatus.geotabStatus.lastDataTime)
          .setZone(customerSettings.general.timeZone)
          .toFormat('MM/dd/yyyy, hh:mm:ss a ZZZZ');
      } else {
        displayTimeString = DateTime.fromISO(serviceStatus.faunaStatus.lastDataTime)
          .setZone(customerSettings.general.timeZone)
          .toFormat('MM/dd/yyyy, hh:mm:ss a ZZZZ');
      }
    } else if (serviceStatus.geotabStatus.lastDataTime != null) {
      // If only geotab last data time is available
      displayTimeString = DateTime.fromISO(serviceStatus.geotabStatus.lastDataTime)
        .setZone(customerSettings.general.timeZone)
        .toFormat('MM/dd/yyyy, hh:mm:ss a ZZZZ');
    } else if (serviceStatus.faunaStatus.lastDataTime != null) {
      // If only fauna last data time is available
      displayTimeString = DateTime.fromISO(serviceStatus.faunaStatus.lastDataTime)
        .setZone(customerSettings.general.timeZone)
        .toFormat('MM/dd/yyyy, hh:mm:ss a ZZZZ');
    }

    return displayTimeString;
  }

  async function getServiceStatus() {
    const serviceStatusResponse = await fetch('/getServiceStatus');
    const serviceStatus = await serviceStatusResponse.json();
    return serviceStatus;
  }

  async function getDownStatus() {
    const downStatusResponse = await fetch('/getDownStatus');
    const downStatusArray = await downStatusResponse.json();
    // default down Status, if return empty array means fauna is down
    const downStatusProcessed = {
      geotabDownStatus: false,
      faunaDownStatus: false,
    };
    // Loop through downStatus in order of response (customer specific -> all)
    for (let i = 0; i < downStatusArray.length; i++) {
      if (downStatusArray[i].geotabDownStatus === true) {
        downStatusProcessed.geotabDownStatus = true;
      }
      if (downStatusArray[i].faunaDownStatus === true) {
        downStatusProcessed.faunaDownStatus = true;
      }
    }
    return downStatusProcessed;
  }

  async function buttonClick() {
    setdownStatusUpdateToggle(!downStatusUpdateToggle);
  }

  async function statsviewRefreshClick() {
    dispatch(setTriggerRefreshData(true));
  }

  const bubbleStyle = {
    display: isHovered ? 'block' : 'none',
    position: 'absolute',
    top: '100%',
    left: '50%',
    transform: 'translateX(-50%)',
    padding: '1px',
    backgroundColor: '#000000',
    borderRadius: '5px',
    width: '250px',
    textAlign: 'center',
    fontSize: '14px',
    color: '#ffffff',
    zIndex: 9999,
  };

  const innerButtonStyle = {
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    fontSize: '14px',
  };

  return (
    <div
      style={{
        position: 'relative',
        display: 'inline-block',
      }}
      onMouseEnter={() => {
        return setIsHovered(true);
      }}
      onMouseLeave={() => {
        return setIsHovered(false);
      }}
      onClick={buttonClick}
    >
      <button
        style={{
          display: props.login && props.login === true && !serviceStatusActive ? 'none' : '',
          border: 'none',
          backgroundColor: props.login && props.login === true ? '#fff' : '#f8f9fc',
        }}
      >
        {serviceStatusActive ? (
          <FontAwesomeIcon
            icon='fas fa-triangle-exclamation'
            size={props.small === true ? '1x' : '2x'}
            style={{color: '#f4c526'}}
          />
        ) : (
          <FontAwesomeIcon
            icon='fas fa-info-circle '
            size={props.small === true ? '1x' : '2x'}
            style={{color: '#858796'}}
          />
        )}
      </button>
      <div style={bubbleStyle}>{serviceStatusTooltip}</div>
    </div>
  );
}

export {DownStatusIcon};
