import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {updateSearchText} from './searchBarSlice';
import {DownStatusIcon} from '../DownStatusIcon';
import {sendGAEvent} from '../../app/utils';
import {GlobalFilterModal} from '../GlobalFilterModal';

import {Button, Badge} from '@mui/material';

function Topbar(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const displaySettings = useSelector((state) => {
    return state.framework;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const searchText = useSelector((state) => {
    return state.searchBar.searchText;
  });
  const appFilters = useSelector((state) => {
    return state.app.appFilters;
  });
  const vehicles = useSelector((state) => {
    return state.app.vehicles;
  });
  const vehicleLabels = useSelector((state) => {
    return state.app.vehicleLabels;
  });

  const [filtersModalOpen, setFiltersModalOpen] = useState(false);

  useEffect(() => {
    // Focus on dropdown input when opened
    $('.dropdown').on('shown.bs.dropdown', function (e) {
      $('.dropdown-menu input').focus();
    });
  }, []);

  // Get count of filters
  const allVehInFilter = [];
  if (typeof appFilters.vehicles !== 'undefined') {
    appFilters.vehicles.forEach((vehicleSN) => {
      allVehInFilter.push(vehicleSN);
    });
  }
  if (typeof appFilters.vehicleLabels !== 'undefined') {
    appFilters.vehicleLabels.forEach((groupId) => {
      if (groupId.length < 8) {
        const vehType = parseInt(groupId);
        vehicles.forEach((vehicle) => {
          if (vehicle.machineType == vehType) {
            allVehInFilter.push(vehicle.serialNumber);
          }
        });
      } else {
        const vehLabel = vehicleLabels.find((label) => {
          return label.groupId == groupId;
        });
        if (vehLabel) {
          vehLabel.vehicleSNs.forEach((vehicleSN) => {
            allVehInFilter.push(vehicleSN);
          });
        }
      }
    });
  }
  const uniqueVehInFilter = [...new Set(allVehInFilter)].length;

  const vehicleFilterCnt = appFilters?.vehicles?.length || 0;
  const vehicleLabelFilterCnt = appFilters?.vehicleLabels?.length || 0;
  const totalFilterCnt = vehicleFilterCnt + vehicleLabelFilterCnt;

  return (
    <React.Fragment>
      {/* Filter modal */}
      <GlobalFilterModal open={filtersModalOpen} setFiltersModalOpen={setFiltersModalOpen}></GlobalFilterModal>

      {/* Topbar */}
      <nav className='navbar navbar-expand topbar static-top shadow'>
        <button id='sidebarToggleTop' className='btn btn-link d-md-none ml-n2 mr-1' onClick={openSidebar}>
          <FontAwesomeIcon icon='fa fa-bars' style={{color: 'black'}} />
        </button>
        <div className='d-sm-flex align-items-center justify-content-between mr-auto ml-md-2 my-2 my-md-0 mw-100'>
          <h1 className='h3 mb-0 text-gray-800'>{props.text}</h1>
        </div>

        {/* Filter button */}
        {userSettings.general.globalFiltersEnabled && location.pathname != '/statsview' && (
          <Badge
            badgeContent={uniqueVehInFilter}
            max={9999}
            showZero={totalFilterCnt > 0}
            overlap='circular'
            color='error'
          >
            <Button
              title='App Filters'
              sx={{minWidth: 'auto'}}
              onClick={() => {
                setFiltersModalOpen(true);
              }}
            >
              <FontAwesomeIcon icon='fas fa-filter' size='2x' style={{color: totalFilterCnt ? '#4e73df' : '#858697'}} />
            </Button>
          </Badge>
        )}

        {/* Down status */}
        <DownStatusIcon login={false} small={false} />

        {/* Search */}
        {props.search && (
          <React.Fragment>
            <div
              className='dropdown dropdown-fw dropdown-fw-backdrop'
              style={{display: !displaySettings.smallScreen && 'none'}}
            >
              <button className='btn mr-n2' data-toggle='dropdown' aria-expanded='false'>
                <FontAwesomeIcon icon='fas fa-search' size='2x' style={{color: searchText && '#4e73df'}} />
              </button>
              <div className='dropdown-menu dropdown-menu-right'>
                <input
                  type='search'
                  className='dropdown-item border form-control'
                  placeholder='Search..'
                  value={searchText}
                  onChange={(e) => {
                    return dispatch(updateSearchText(e.target.value));
                  }}
                  onFocus={() => {
                    sendGAEvent('search_focus', 'SearchBarMobile', 'search');
                  }}
                />
              </div>
            </div>

            <input
              type='search'
              className='form-control w-25'
              placeholder='Search..'
              value={searchText}
              onChange={(e) => {
                return dispatch(updateSearchText(e.target.value));
              }}
              onFocus={() => {
                sendGAEvent('search_focus', 'SearchBarDesktop', 'search');
              }}
              style={{display: displaySettings.smallScreen && 'none'}}
            />
          </React.Fragment>
        )}

        {/* User button */}
        <div className='dropdown dropdown-backdrop'>
          <button className='btn' id='dropdownMenuButton' data-toggle='dropdown' aria-expanded='false'>
            <FontAwesomeIcon icon='fas fa-user-circle' size='2x' />
          </button>
          <div className='dropdown-menu dropdown-menu-right' aria-labelledby='dropdownMenuButton'>
            <div className='dropdown-header'>
              <div>
                <FontAwesomeIcon icon='fas fa-user-circle' /> {userSettings.username}
              </div>
              <div>
                <FontAwesomeIcon icon='fas fa-database' /> {customerSettings.general.organizationName}
              </div>
            </div>
            <div className='dropdown-divider'></div>
            <Link className='dropdown-item' to='/personal_settings'>
              Personal Settings
            </Link>
            {checkOrganizationSettingsAccess() && (
              <Link className='dropdown-item' to='/organization_settings'>
                Organization Settings
              </Link>
            )}
            <Link className='dropdown-item' to='' style={{color: 'red'}} onClick={logoutClick} id='main-logout-button'>
              Logout
            </Link>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );

  // Handle click on logout button
  function logoutClick() {
    dispatch({type: 'RESET'}); // Need to reset the states to clear current cache
    navigate('/login');
    fetch('/logout')
      .then((response) => {
        window.location.href = '/login'; // Use window.location to cause a new page to load
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function openSidebar() {
    props.handleSetSideMenuOpened(true);
  }

  function checkOrganizationSettingsAccess() {
    const organizationSettingsAccess = Object.keys(userSettings.roleViewAccess).some((key) => {
      return userSettings.roleViewAccess[key];
    });

    // Organization settings are only available for accounts with geotab access
    return organizationSettingsAccess;
  }
}

export {Topbar};
