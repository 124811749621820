import React, {useState, useEffect, useMemo} from 'react';
import {
  FormControl,
  Select,
  MenuItem,
  ListSubheader,
  TextField,
  InputAdornment,
  Typography,
  InputLabel,
  Paper,
  IconButton,
  Button,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

const containsText = function (text, searchText) {
  return text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
};

function IntelliSearchSelect({
  name,
  id,
  value,
  options,
  disabled,
  onChange,
  className,
  label,
  allNoneEnabled = false,
  returnFullEvent = false,
  multiple = false,
  onOpenFocusSearch = false,
  placeholder = 'Select...',
  optionDisplayLimit = 4,
}) {
  const [searchText, setSearchText] = useState('');
  const [groups, setGroups] = useState([]);

  // Determine if groups provided
  useEffect(() => {
    const uniqueGroups = [
      ...new Set(
        options.map((option) => {
          return option.group || '';
        })
      ),
    ];
    const sortedGroups = uniqueGroups.sort();
    setGroups(sortedGroups);
  }, [options]);

  // Filter options based on search
  const displayedOptions = useMemo(() => {
    if (!options) {
      return [];
    }
    return options.filter((option) => {
      return containsText(option.label, searchText);
    });
  }, [searchText, options]);

  useEffect(() => {}, [value]);

  function renderAllNone() {
    return (
      <ListSubheader>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: '3px'}}>
          <Button
            size='small'
            variant='outlined'
            className='font-weight-normal mr-1 '
            sx={{
              textTransform: 'none',
              color: '#aaa',
              borderColor: '#ccc',
              minWidth: 'auto',
              fontSize: '11px',
              lineHeight: '13px',
              '&:hover': {
                color: '#62c462',
                borderColor: '#62c462',
              },
            }}
            onClick={() => {
              const allOptions = displayedOptions
                .filter((option) => {
                  return !option.disabled;
                })
                .map((option) => {
                  return option.value;
                });
              handleChange({target: {name: name, value: allOptions}});
            }}
          >
            All
          </Button>
          <Button
            size='small'
            variant='outlined'
            className='font-weight-normal'
            sx={{
              textTransform: 'none',
              color: '#aaa',
              borderColor: '#ccc',
              minWidth: 'auto',
              fontSize: '11px',
              lineHeight: '13px',
              '&:hover': {
                color: '#ee5f5b',
                borderColor: '#ee5f5b',
              },
            }}
            onClick={() => {
              handleChange({target: {name: name, value: []}});
            }}
          >
            None
          </Button>
        </div>
      </ListSubheader>
    );
  }

  function renderOptions() {
    if (groups.length > 0 && !(groups.length == 1 && groups[0] == '')) {
      // Use groups

      // Place options into groups
      const optionsByGroup = {};
      groups.forEach((group) => {
        optionsByGroup[group] = [];
      });

      displayedOptions.forEach((option) => {
        optionsByGroup[option.group || ''].push(option);
      });

      const nonEmptyGroupNames = Object.keys(optionsByGroup).filter((group) => {
        return optionsByGroup[group].length > 0;
      });

      return nonEmptyGroupNames.map((group, groupIdx) => {
        const subheaderElement = (
          <ListSubheader>
            <div className='d-flex justify-content-between'>
              <span>{group}</span>
              <div>
                <Button
                  size='small'
                  variant='outlined'
                  className='font-weight-normal mr-1 '
                  sx={{
                    textTransform: 'none',
                    color: '#aaa',
                    borderColor: '#ccc',
                    minWidth: 'auto',
                    fontSize: '11px',
                    lineHeight: '13px',
                    '&:hover': {
                      color: '#62c462',
                      borderColor: '#62c462',
                    },
                  }}
                  onClick={() => {
                    const groupOptions = optionsByGroup[group]
                      .filter((option) => {
                        return !option.disabled;
                      })
                      .map((option) => {
                        return option.value;
                      });
                    const selectedOptions = [...new Set([...value, ...groupOptions])];
                    handleChange({target: {name: name, value: selectedOptions}});
                  }}
                >
                  All
                </Button>
                <Button
                  size='small'
                  variant='outlined'
                  className='font-weight-normal'
                  sx={{
                    textTransform: 'none',
                    color: '#aaa',
                    borderColor: '#ccc',
                    minWidth: 'auto',
                    fontSize: '11px',
                    lineHeight: '13px',
                    '&:hover': {
                      color: '#ee5f5b',
                      borderColor: '#ee5f5b',
                    },
                  }}
                  onClick={() => {
                    const groupOptions = optionsByGroup[group].map((option) => {
                      return option.value;
                    });
                    const selectedOptions = value.filter((item) => {
                      return !groupOptions.includes(item);
                    });
                    handleChange({target: {name: name, value: selectedOptions}});
                  }}
                >
                  None
                </Button>
              </div>
            </div>
          </ListSubheader>
        );

        const optionsRenderElementList = optionsByGroup[group].map((option, optionIdx) => {
          return (
            <MenuItem key={optionIdx} value={option.value} disabled={option.disabled}>
              {option.label}
            </MenuItem>
          );
        });

        return [subheaderElement].concat(optionsRenderElementList);
      });
    } else {
      // Do not use groups

      return displayedOptions.map((option, optionIdx) => {
        return (
          <MenuItem key={optionIdx} value={option.value} disabled={option.disabled}>
            {option.label}
          </MenuItem>
        );
      });
    }
  }

  function handleChange(event) {
    if (returnFullEvent) {
      onChange(event);
    } else {
      onChange(event.target.value);
    }
  }

  return (
    <FormControl fullWidth>
      {label && (
        <InputLabel sx={{maxWidth: 'none'}} shrink>
          {label}
        </InputLabel>
      )}
      <Select
        // Disables auto focus on MenuItems to allow TextField search to be in focus
        MenuProps={{
          autoFocus: false,
          sx: {
            '& .MuiList-root': {
              paddingTop: 0,
              maxHeight: '300px',
              height: 'auto',
            },
            '& .MuiListSubheader-root': {
              padding: 1,
              paddingBottom: 0,
            },
            '& .MuiMenuItem-root': {
              paddingY: '2px',
            },
          },
        }}
        sx={{backgroundColor: 'white'}}
        id={id}
        name={name}
        value={value}
        label={label}
        className={className}
        multiple={multiple}
        disabled={disabled}
        size='small'
        onChange={handleChange}
        onClose={() => {
          setSearchText('');
        }}
        displayEmpty
        renderValue={(value) => {
          if (!multiple && value) {
            const valueOption = options.find((option) => {
              return option.value == value;
            });
            if (valueOption) {
              return valueOption.label;
            }
          }
          if (multiple && value && value.length > 0) {
            const valueOptions = value.map((singleValue) => {
              const valueOption = options.find((option) => {
                return option.value == singleValue;
              });
              return valueOption ? valueOption.label : 'unknown value';
            });
            return valueOptions.length > optionDisplayLimit
              ? `${valueOptions.length} options selected`
              : valueOptions.join(', ');
          }
          return <Typography color='gray'>{placeholder}</Typography>;
        }}
      >
        {/* TextField is put into ListSubheader so that it doesn't
              act as a selectable item in the menu
              i.e. we can click the TextField without triggering any selection.*/}
        <ListSubheader>
          <TextField
            size='small'
            // disable Autofocus on textfield so keybaord does not open by default
            autoFocus={onOpenFocusSearch}
            placeholder='Type to search...'
            fullWidth
            value={searchText}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <IconButton
                  onClick={() => {
                    setSearchText('');
                  }}
                >
                  <ClearIcon fontSize='small' />
                </IconButton>
              ),
            }}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key !== 'Escape') {
                // Prevents autoselecting item while typing (default Select behaviour)
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>

        {/* Render the all none buttons if required */}
        {allNoneEnabled && multiple && renderAllNone()}

        {/* Render the options */}
        {renderOptions()}
      </Select>
    </FormControl>
  );
}

export {IntelliSearchSelect};
