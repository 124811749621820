import React, {useState, useEffect} from 'react';
import BarChart from '../../../components/BarChart';
import {useDispatch, useSelector} from 'react-redux';
import {customChartProperties, sortVehicleNamesHelper, deepCopy, unionLabelsAndDataVehicles} from '../../../app/utils';
import {genServiceTotalCompletionData, genServiceHealthGroupedData, genServiceHealth} from '../dashboardDataProcessing';
import ChartJS from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import {
  KpiTooltip,
  ScorecardSelectHolder,
  KpiTable,
  StatsViewReportFiltersWrapper,
  StatsViewReportSortByWrapper,
  StatsViewReportMainWrapperStyle,
  StatsViewReportTooltipRowStyle,
  StatsViewReportSideControlWrapper,
  StatsViewReportGraphAndControlStyle,
} from '../dashboardUtils';
import {
  Select,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
  CircularProgress,
  FormControl,
  InputLabel,
  Grid,
  Box,
} from '@mui/material';
import {IntelliSearchSelect} from '../../../components/IntelliSearchSelect';
import {setCompareByDatesCache, setCompareByToggle} from '../dashboardSlice';
ChartJS.register(annotationPlugin);

function KpiServiceCompletion(props) {
  const dispatch = useDispatch();
  const loading = useSelector((state) => {
    return state.dashboard.loading;
  });
  const operationsData = useSelector((state) => {
    return state.dashboard.operationsData;
  });
  const servicesData = useSelector((state) => {
    return state.dashboard.servicesData;
  });
  const compareByServicesData = useSelector((state) => {
    return state.dashboard.compareByServicesData;
  });
  const compareByToggle = useSelector((state) => {
    return state.dashboard.compareByToggle;
  });
  const compareByVehicleSNDict = useSelector((state) => {
    return state.dashboard.compareByVehicleSNDict;
  });
  const compareByDatesCache = useSelector((state) => {
    return state.dashboard.compareByDatesCache;
  });
  const vehicleSNDict = useSelector((state) => {
    return state.dashboard.vehicleSNDict;
  });
  const filterOptions = useSelector((state) => {
    return state.dashboard.filterOptions;
  });
  const unitsSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsLength;
  });
  const addToScorecardTrigger = useSelector((state) => {
    return state.dashboard.addToScorecardTrigger;
  });
  const selectedSubset = useSelector((state) => {
    return state.dashboard.selectedSubset;
  });
  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });
  const comparisonLoading = useSelector((state) => {
    return state.dashboard.comparisonLoading;
  });
  const vehicleLabels = useSelector((state) => {
    return state.dashboard.vehicleLabels;
  });

  const [vehicleSNFilterOptions, setVehicleSNFilterOptions] = useState([]);
  const [serviceIntervalSelectedUnit, setServiceIntervalSelectedUnit] = useState({
    engHr: 'Engine Hours',
    odo: 'Km',
    time: 'Days',
  });

  const [servicesBarData, setServicesBarData] = useState(null);
  const [serviceIntervalSelected, setServiceIntervalSelected] = useState('completion');
  const [serviceHealthTypeAvailableOptions, setServiceHealthTypeAvailableOptions] = useState([]);
  const [serviceHealthFilters, setServiceHealthFilters] = useState({
    serviceHealthVehicleSNs: [],
    serviceHealthVehicleType: [],
    serviceHealthGroupByMachineType: true,
    serviceHealthVehicleCount: '25',
    serviceHealthVehicleSort: 'Desc',
  });

  const [serviceTotalCompletionData, setServiceTotalCompletionData] = useState(null);
  const [compareByServiceTotalCompletionData, setCompareByServiceTotalCompletionData] = useState(null);

  const [tableData, setTableData] = useState([]);
  const [addToScorecardOpened, setAddToScorecardOpened] = useState(null);
  const [serviceCompletionData, setServiceCompletionData] = useState(null);

  useEffect(() => {
    const opts = Object.keys(vehicleSNDict)
      .sort((vehicleSNa, vehicleSNb) => {
        const aName = vehicleSNDict[vehicleSNa].name;
        const bName = vehicleSNDict[vehicleSNb].name;
        return sortVehicleNamesHelper(aName, bName);
      })
      .map((vehSN) => {
        return {value: vehSN, text: vehicleSNDict[vehSN].name};
      });
    setVehicleSNFilterOptions(opts);
  }, [vehicleSNDict]);

  function handleFilters(value) {
    setServiceHealthFilters((values) => {
      return {...values, 'serviceHealthVehicleSNs': value};
    });
  }

  useEffect(() => {
    // Determine units based on customer units system
    if (unitsSystem == 'imperial') {
      setServiceIntervalSelectedUnit((values) => {
        return {...values, odo: 'Miles'};
      });
    }
  }, [unitsSystem]);

  useEffect(() => {
    if (addToScorecardOpened == null) {
      setAddToScorecardOpened(false);
    } else {
      setAddToScorecardOpened(true);
    }
  }, [addToScorecardTrigger]);

  useEffect(() => {
    if (props.pageStates?.serviceIntervalSelected) {
      setServiceIntervalSelected(props.pageStates?.serviceIntervalSelected);
    } else {
      setServiceIntervalSelected('completion');
    }
    if (props?.pageFilters) {
      setServiceHealthFilters((values) => {
        return {
          ...values,
          ...props.pageFilters,
        };
      });
    }
    if (props.pageStates?.compareByDateRange && props.pageStates?.compareByToggle) {
      dispatch(setCompareByDatesCache(props.pageStates?.compareByDateRange));
    }
    if (props.pageStates?.compareByToggle) {
      dispatch(setCompareByToggle(props.pageStates?.compareByToggle));
    }
    // If we are in a scorecard but compareByToggle is not set. Then we need to set it to false
    else if (props.hasOwnProperty('pageStates')) {
      dispatch(setCompareByToggle(false));
    }
  }, [props.pageStates, props.pageFilters]);

  useEffect(() => {
    if (servicesData != null) {
      generateData();

      // Check which Service Health has Data.
      const vehicleServices = servicesData.vehicleServices;
      const serviceHealthIntervalOptions = ['odo', 'engHr', 'time'];
      const serviceHealthAvailableOptions = [];
      serviceHealthIntervalOptions.forEach((serviceIntervalType) => {
        const vehicleKeys = Object.keys(vehicleServices);
        for (const vehicleSN of vehicleKeys) {
          const vehicleHasSelectedService =
            vehicleServices[vehicleSN].serviceStats[serviceIntervalType].overBy.length > 0;
          if (vehicleHasSelectedService) {
            serviceHealthAvailableOptions.push(serviceIntervalType);
            break;
          }
        }
      });
      setServiceHealthTypeAvailableOptions(serviceHealthAvailableOptions);
    }
  }, [operationsData, servicesData, serviceHealthFilters, compareByToggle, compareByServicesData]);

  useEffect(() => {
    if (servicesData != null && vehicleSNDict && serviceIntervalSelected != 'completion') {
      generateServiceHealthData();
    }
  }, [
    servicesData,
    serviceHealthFilters,
    vehicleSNDict,
    serviceIntervalSelected,
    compareByToggle,
    compareByServicesData,
  ]);

  function handleVehiclesFilters(value, selectName) {
    setServiceHealthFilters((values) => {
      return {...values, [selectName]: value};
    });
  }

  function handleChecked(event) {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setServiceHealthFilters((values) => {
      return {...values, [name]: value};
    });
  }

  function generateData() {
    if (servicesData) {
      // Apply filters
      let filteredVehicleSNs = Object.keys(servicesData.vehicleServices).filter((vehSN) => {
        // Check if vehicleSN is in the filter
        if (serviceHealthFilters.serviceHealthVehicleSNs.length > 0) {
          if (!serviceHealthFilters.serviceHealthVehicleSNs.includes(vehSN)) {
            return false;
          }
        }

        return true;
      });

      if (serviceHealthFilters.serviceHealthVehicleType.length > 0) {
        const filterVehicleTypes = [];
        const filterVehicleLabels = [];

        serviceHealthFilters.serviceHealthVehicleType.forEach((groupId) => {
          if (groupId.length < 8) {
            filterVehicleTypes.push(parseInt(groupId));
          } else {
            filterVehicleLabels.push(groupId);
          }
        });

        const vehicleLabelsFiltered = vehicleLabels.filter((label) => {
          return filterVehicleLabels.includes(label.groupId);
        });

        filteredVehicleSNs = filteredVehicleSNs.filter((vehicleSN) => {
          let inType = false;
          let inLabel = false;
          if (filterVehicleTypes.length > 0) {
            const vehicleType =
              vehicleSNDict[vehicleSN]?.machineType != undefined ? vehicleSNDict[vehicleSN]?.machineType : 0;
            inType = filterVehicleTypes.includes(vehicleType);
          }

          if (filterVehicleLabels.length > 0) {
            inLabel = vehicleLabelsFiltered.some((label) => {
              return label.vehicleSNs.includes(vehicleSN);
            });
          }

          return inType || inLabel;
        });
      }

      let onTimeServices = servicesData.onTimeServices;
      let lateServices = servicesData.lateServices;

      // Determine onTime and Late Services values based on filters
      if (
        serviceHealthFilters.serviceHealthVehicleSNs.length > 0 ||
        serviceHealthFilters.serviceHealthVehicleType.length > 0
      ) {
        onTimeServices = 0;
        lateServices = 0;
        filteredVehicleSNs.forEach((vehSN) => {
          const curVeh = servicesData.vehicleServices[vehSN];
          onTimeServices += curVeh['onTimeServices'];
          lateServices += curVeh['lateServices'];
        });
      }

      const serviceTotalCompletionDataTemp = genServiceTotalCompletionData(onTimeServices, lateServices);

      // Compare by dataset
      let compareByServiceTotalCompletionDataTemp = null;
      if (compareByToggle && compareByServicesData != null) {
        let compareByFilteredVehicleSNs = Object.keys(compareByServicesData.vehicleServices).filter((vehSN) => {
          // Check if vehicleSN is in the filter
          if (serviceHealthFilters.serviceHealthVehicleSNs.length > 0) {
            if (!serviceHealthFilters.serviceHealthVehicleSNs.includes(vehSN)) {
              return false;
            }
          }

          return true;
        });

        if (serviceHealthFilters.serviceHealthVehicleType.length > 0) {
          const filterVehicleTypes = [];
          const filterVehicleLabels = [];

          serviceHealthFilters.serviceHealthVehicleType.forEach((groupId) => {
            if (groupId.length < 8) {
              filterVehicleTypes.push(parseInt(groupId));
            } else {
              filterVehicleLabels.push(groupId);
            }
          });

          const vehicleLabelsFiltered = vehicleLabels.filter((label) => {
            return filterVehicleLabels.includes(label.groupId);
          });

          compareByFilteredVehicleSNs = compareByFilteredVehicleSNs.filter((vehicleSN) => {
            let inType = false;
            let inLabel = false;
            if (filterVehicleTypes.length > 0) {
              const vehicleType =
                vehicleSNDict[vehicleSN]?.machineType != undefined ? vehicleSNDict[vehicleSN]?.machineType : 0;
              inType = filterVehicleTypes.includes(vehicleType);
            }

            if (filterVehicleLabels.length > 0) {
              inLabel = vehicleLabelsFiltered.some((label) => {
                return label.vehicleSNs.includes(vehicleSN);
              });
            }

            return inType || inLabel;
          });
        }

        let compareByonTimeServices = compareByServicesData.onTimeServices;
        let compareByLateServices = compareByServicesData.lateServices;

        // Determine onTime and Late Services values based on filters
        if (
          serviceHealthFilters.serviceHealthVehicleSNs.length > 0 ||
          serviceHealthFilters.serviceHealthVehicleType.length > 0
        ) {
          compareByonTimeServices = 0;
          compareByLateServices = 0;
          compareByFilteredVehicleSNs.forEach((vehSN) => {
            const curVeh = compareByServicesData.vehicleServices[vehSN];
            compareByonTimeServices += curVeh['onTimeServices'];
            compareByLateServices += curVeh['lateServices'];
          });
        }

        compareByServiceTotalCompletionDataTemp = genServiceTotalCompletionData(
          compareByonTimeServices,
          compareByLateServices,
          true
        );
        serviceTotalCompletionDataTemp.datasets = [
          ...serviceTotalCompletionDataTemp.datasets,
          ...compareByServiceTotalCompletionDataTemp.datasets,
        ];
      }

      if (lateServices + onTimeServices > 0) {
        setServiceTotalCompletionData(serviceTotalCompletionDataTemp);
        setCompareByServiceTotalCompletionData(compareByServiceTotalCompletionDataTemp);
      } else {
        setServiceTotalCompletionData(null);
        setCompareByServiceTotalCompletionData(null);
      }

      // Check count filter for display
      // If the value is zero it means all vehicles should be displayed
      let serviceCompletionVehicleKeys = filteredVehicleSNs;
      const vehicleFilterCount = parseInt(serviceHealthFilters.serviceHealthVehicleCount);
      if (vehicleFilterCount > 0) {
        if (serviceCompletionVehicleKeys.length > vehicleFilterCount) {
          serviceCompletionVehicleKeys = filteredVehicleSNs.slice(0, vehicleFilterCount);
        }
      }

      // Get service objects for filtered vehicles
      const vehicleServicesTableData = serviceCompletionVehicleKeys.map((vehicleSN) => {
        return servicesData.vehicleServices[vehicleSN];
      });

      // Create service complete datasets on filtered vehicles
      setTableData(vehicleServicesTableData);
    }
  }

  function unionLabelsAndDataServices(baseData, compareByBaseData, excludedKeys = [], skipKeys = []) {
    const baseDataCopy = deepCopy(baseData);
    const compareByBaseDataCopy = deepCopy(compareByBaseData);

    // Do for Vehicles
    const baseDataKeys = Object.keys(baseDataCopy);
    const compareByBaseDataKeys = Object.keys(compareByBaseDataCopy);

    baseDataKeys.forEach((baseDataKey) => {
      if (!compareByBaseDataKeys.includes(baseDataKey)) {
        const objZeros = deepCopy(baseDataCopy[baseDataKey]);
        Object.keys(objZeros).forEach((innerObjKey) => {
          if (!excludedKeys.includes(innerObjKey) && !skipKeys.includes(innerObjKey)) {
            objZeros[innerObjKey] = 0;
          } else if (skipKeys.includes(innerObjKey)) {
            objZeros[innerObjKey] = [0];
          }
        });
        compareByBaseDataCopy[baseDataKey] = objZeros;
      }
    });
    compareByBaseDataKeys.forEach((baseDataKey) => {
      if (!baseDataKeys.includes(baseDataKey)) {
        const objZeros = deepCopy(compareByBaseDataCopy[baseDataKey]);
        Object.keys(objZeros).forEach((innerObjKey) => {
          if (!excludedKeys.includes(innerObjKey)) {
            objZeros[innerObjKey] = 0;
          } else if (skipKeys.includes(innerObjKey)) {
            objZeros[innerObjKey] = [0];
          }
        });
        baseDataCopy[baseDataKey] = objZeros;
      }
    });

    return [baseDataCopy, compareByBaseDataCopy];
  }

  function generateServiceHealthData() {
    if (servicesData) {
      // First we need to generate the grouped data
      const groupedServicesData = genServiceHealthGroupedData(
        servicesData.vehicleServices,
        serviceHealthFilters,
        vehicleSNDict,
        serviceIntervalSelected
      );
      let compareByGroupedServicesData = null;
      if (compareByToggle && compareByServicesData != null) {
        compareByGroupedServicesData = genServiceHealthGroupedData(
          compareByServicesData.vehicleServices,
          serviceHealthFilters,
          compareByVehicleSNDict,
          serviceIntervalSelected
        );
      }

      // Union keys between compare by and non compare by
      // for vehicles if compare by is toggled
      let groupedServicesDataCopy = deepCopy(groupedServicesData);
      let compareByGroupedServicesDataCopy = deepCopy(compareByGroupedServicesData);
      if (compareByToggle) {
        const [newGroupedServicesData, newCompareByGroupedServicesData] = unionLabelsAndDataServices(
          groupedServicesData,
          compareByGroupedServicesData,
          ['name'],
          ['overBy', 'intervals']
        );
        groupedServicesDataCopy = newGroupedServicesData;
        compareByGroupedServicesDataCopy = newCompareByGroupedServicesData;
      }

      // Generate data
      const servicesHealthBarData = genServiceHealth(
        groupedServicesDataCopy,
        groupedServicesDataCopy, // Sorting group
        serviceHealthFilters,
        serviceIntervalSelected,
        serviceIntervalSelectedUnit,
        false
      );
      let compareByServicesHealthBarData = null;
      if (compareByToggle && compareByServicesData != null) {
        compareByServicesHealthBarData = genServiceHealth(
          compareByGroupedServicesDataCopy,
          groupedServicesDataCopy, // Sorting group
          serviceHealthFilters,
          serviceIntervalSelected,
          serviceIntervalSelectedUnit,
          true
        );
        servicesHealthBarData.datasets = [
          ...servicesHealthBarData.datasets,
          ...compareByServicesHealthBarData.datasets,
        ];
      }

      setServicesBarData(servicesHealthBarData);
    }
  }

  function serviceCompletionTotalsBarGraph() {
    return (
      <div style={{height: '60vh', maxHeight: '500px'}}>
        {/* Chart */}
        <BarChart
          data={serviceTotalCompletionData}
          options={{
            maintainAspectRatio: false,
            plugins: {
              title: {
                text: 'Service Completion On Time vs Late',
                display: true,
                font: {
                  size: customChartProperties.titleFontSize,
                  lineHeight: customChartProperties.lineHeight,
                },
              },
              legend: {
                onClick: (e) => {
                  return false;
                },
                labels: {
                  font: {
                    size: customChartProperties.legendFontSize,
                  },
                },
              },
            },
            scales: {
              y: {
                title: {
                  text: '% Completed',
                  display: true,
                  font: {
                    size: customChartProperties.axisTitleFontSize,
                    weight: customChartProperties.axisTitleFontWeight,
                  },
                },
                type: 'linear',
                position: 'left',
                stacked: true,
                ticks: {
                  count: 5,
                  min: 0, // Minimum value
                  max: 100, // Maximum value
                  beginAtZero: true,
                  autoSkip: false,
                  stepSize: 20,
                  callback: function (val, index) {
                    return `${val} %`;
                  },
                },
              },
              x: {
                display: true,
              },
            },
          }}
        />
      </div>
    );
  }

  function serviceHealthBarGraph() {
    return (
      <div style={{height: '60vh', minHeight: '500px'}}>
        {/* Chart */}
        <BarChart
          data={servicesBarData}
          options={{
            maintainAspectRatio: false,
            plugins: {
              annotation: {
                annotations: {
                  // Draw a Line to represent Target
                  line1: {
                    type: 'line',
                    scaleID: 'y',
                    value: 0,
                    endValue: 0,
                    borderColor: customChartProperties.colorRed,
                    borderWidth: 2,
                    display: true,
                  },
                },
              },
              title: {
                display: true,
                font: {
                  size: customChartProperties.titleFontSize,
                  lineHeight: customChartProperties.lineHeight,
                },
                text: `Average Service Late/Early (${serviceIntervalSelectedUnit[serviceIntervalSelected]})`,
              },
              legend: {
                onClick: (e) => {
                  return false;
                },
                labels: {
                  filter: function (legendItem, data) {
                    return legendItem.text != 'Target' || 100;
                  },
                  font: {
                    size: customChartProperties.legendFontSize,
                  },
                },
              },
            },
            scales: {
              y: {
                title: {
                  text: `${serviceIntervalSelectedUnit[serviceIntervalSelected]}`,
                  display: true,
                  font: {
                    size: customChartProperties.axisTitleFontSize,
                    weight: customChartProperties.axisTitleFontWeight,
                  },
                },
              },
              TargetAxis: {
                title: {
                  text: '',
                  display: false,
                },
                display: false,
              },
              x: {
                stacked: true,
                ticks: {
                  autoSkip: servicesBarData.labels.length > customChartProperties.autoSkipLimit,
                  maxRotation: 90,
                  minRotation: 0,
                  font: {
                    size: customChartProperties.xLabelFontSize,
                    lineHeight: customChartProperties.lineHeight,
                  },
                },
                title: {
                  text: serviceHealthFilters.serviceHealthGroupByMachineType ? 'Machine Type' : 'Vehicle Name',
                  display: false,
                  font: {
                    size: customChartProperties.axisTitleFontSize,
                    weight: customChartProperties.axisTitleFontWeight,
                  },
                },
              },
            },
          }}
          plugins={[
            {
              id: 'customPlugin',
              beforeDraw: function (chart) {
                chart.config.data.datasets.forEach((dataset, i) => {
                  if (dataset.legendBackgroundColor) {
                    chart.legend.legendItems[i].fillStyle = dataset.legendBackgroundColor;
                    chart.legend.legendItems[i].strokeStyle = dataset.legendBorderColor
                      ? dataset.legendBorderColor
                      : dataset.legendBackgroundColor;
                  }
                });
              },
            },
          ]}
        />
      </div>
    );
  }

  function filterControlComponents() {
    return [
      /* Vehicle Filter */
      <IntelliSearchSelect
        label='Vehicles'
        allNoneEnabled
        id='dashboard-vehicles-serviceHealthVehicleSNs-filter'
        key='dashboard-vehicles-serviceHealthVehicleSNs-filter'
        name='serviceHealthVehicleSNs'
        multiple={true}
        search={true}
        value={serviceHealthFilters.serviceHealthVehicleSNs}
        options={vehicleSNFilterOptions.map((opt) => {
          return {'label': opt.text, 'value': opt.value};
        })}
        onChange={(s) => {
          handleVehiclesFilters(s, 'serviceHealthVehicleSNs');
        }}
        placeholder='Filter for Vehicles'
      />,
      /* Vehicle Type Filter */
      <IntelliSearchSelect
        label='Vehicle Labels'
        allNoneEnabled
        id='dashboard-vehicles-serviceHealthVehicleType-filter'
        key='dashboard-vehicles-serviceHealthVehicleType-filter'
        name='serviceHealthVehicleType'
        multiple={true}
        search={true}
        value={serviceHealthFilters.serviceHealthVehicleType}
        options={filterOptions.vehicles.vehicleType.map((opt) => {
          return {'label': opt.text, 'value': opt.value};
        })}
        onChange={(s) => {
          handleVehiclesFilters(s, 'serviceHealthVehicleType');
        }}
        placeholder='Filter for Vehicle Labels'
      />,
    ];
  }

  function displayControlComponents() {
    return serviceIntervalSelected == 'completion'
      ? []
      : [
          /* Vehicle Sort */
          <IntelliSearchSelect
            label='Sort By'
            id='dashboard-vehicles-serviceHealthVehicleSort-filter'
            key='dashboard-vehicles-serviceHealthVehicleSort-filter'
            name='serviceHealthVehicleSort'
            multiple={false}
            value={serviceHealthFilters.serviceHealthVehicleSort}
            options={filterOptions.vehicles.efficiencyVehicleSort.map((opt) => {
              return {'label': opt.text, 'value': opt.value};
            })}
            onChange={(s) => {
              handleVehiclesFilters(s, 'serviceHealthVehicleSort');
            }}
            placeholder='Sorting method'
          />,
          /* Vehicle Count */
          <IntelliSearchSelect
            label={`${serviceHealthFilters.serviceHealthGroupByMachineType ? 'Vehicle Type' : 'Vehicle'} Count`}
            id='dashboard-vehicles-serviceHealthVehicleCount-filter'
            key='dashboard-vehicles-serviceHealthVehicleCount-filter'
            name='serviceHealthVehicleCount'
            multiple={false}
            value={serviceHealthFilters.serviceHealthVehicleCount}
            options={filterOptions.vehicles.vehicleCount.map((opt) => {
              return {'label': opt.text, 'value': opt.value};
            })}
            onChange={(s) => {
              handleVehiclesFilters(s, 'serviceHealthVehicleCount');
            }}
            placeholder={`Show  All Vehicles`}
          />,
        ];
  }

  // Check if loading
  if (loading || (comparisonLoading && compareByToggle)) {
    return <CircularProgress className='mt-4 mx-auto d-block' size={200} />;
  }
  if (props.graphOnly) {
    return (
      <React.Fragment>
        {/* Tooltip */}
        <div className='col-12 flex-col px-0'>
          <KpiTooltip selectedSubset={selectedSubset} />
        </div>
        {serviceIntervalSelected == 'completion' ? (
          !serviceTotalCompletionData ? (
            <React.Fragment>
              <div className='col-lg-6 flex-col text-center px-0 align-items-center mt-5 mb-auto pb-3'>
                {' '}
                No data available
              </div>
              <div className='col-lg-6 flex-col text-center px-0 align-items-center mb-auto pb-3'>
                <KpiTable
                  pageStates={props.pageStates}
                  tableData={tableData}
                  type={'services'}
                  filters={serviceHealthFilters}
                  filterOptions={{'serviceHealthVehicleSNs': vehicleSNFilterOptions}}
                  handleFilters={handleFilters}
                />
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className='col-lg-6 flex-col text-center px-0 align-items-center mb-auto pb-3'>
                {serviceCompletionTotalsBarGraph()}
              </div>
              <div className='col-lg-6 flex-col text-center px-0 align-items-center mb-auto pb-3'>
                <KpiTable
                  pageStates={props.pageStates}
                  tableData={tableData}
                  type={'services'}
                  filters={serviceHealthFilters}
                  filterOptions={{'serviceHealthVehicleSNs': vehicleSNFilterOptions}}
                  handleFilters={handleFilters}
                />
              </div>
            </React.Fragment>
          )
        ) : !servicesBarData ? (
          <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
        ) : (
          <React.Fragment>
            <div className='col-lg-8 flex-col text-center px-0 align-items-center mb-auto pb-5'>
              {serviceHealthBarGraph()}
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  return (
    <Box sx={StatsViewReportMainWrapperStyle}>
      {/* Hidden Add to Scorecard*/}
      <ScorecardSelectHolder
        autofillPageName={'Machine Service Completion'}
        pageFilters={serviceHealthFilters}
        pageStates={{
          serviceIntervalSelected: serviceIntervalSelected,
          compareByToggle: compareByToggle,
          compareByDateRange: compareByDatesCache,
        }}
        hidden={true}
        opened={addToScorecardOpened}
        updateExternalOpenedState={setAddToScorecardOpened}
      />

      {/* Service type selection */}
      <div className='col-12 flex-col ml-n1'>
        <label>Service Interval Type: </label>
        <ToggleButtonGroup
          color='primary'
          value={serviceIntervalSelected}
          exclusive
          onChange={(e) => {
            const selectedType = e.target.value;
            setServiceIntervalSelected(selectedType);
          }}
          name='serviceHealthServiceType'
          aria-label='serviceHealthServiceType'
        >
          <ToggleButton value='completion' style={{textTransform: 'none'}}>
            Completion
          </ToggleButton>
          {serviceHealthTypeAvailableOptions.includes('engHr') && (
            <ToggleButton value='engHr' style={{textTransform: 'none'}}>
              Engine Hour
            </ToggleButton>
          )}
          {serviceHealthTypeAvailableOptions.includes('odo') && (
            <ToggleButton value='odo' style={{textTransform: 'none'}}>
              Odometer
            </ToggleButton>
          )}
          {serviceHealthTypeAvailableOptions.includes('time') && (
            <ToggleButton value='time' style={{textTransform: 'none'}}>
              Time
            </ToggleButton>
          )}
        </ToggleButtonGroup>
      </div>

      {/* Graph type selection */}
      {serviceIntervalSelected == 'completion' ? (
        <React.Fragment>
          {/* In Small screen mode Filters and Sort by display */}
          {smallScreen && (
            <StatsViewReportFiltersWrapper key={`ServiceCompleteFilter`}>
              {filterControlComponents()}
            </StatsViewReportFiltersWrapper>
          )}
          {/* Graph and XY Axis Control */}
          <Grid container spacing={2} sx={{padding: 1}}>
            <Grid item xs={smallScreen ? 12 : 7} md={8} lg={9}>
              <Box sx={StatsViewReportGraphAndControlStyle}>
                <Grid item xs={12} md={6} sx={{textAlign: 'center', alignItems: 'center'}}>
                  {!serviceTotalCompletionData ? 'No data available' : serviceCompletionTotalsBarGraph()}
                </Grid>
                <Grid item xs={12} md={6} sx={{textAlign: 'center', alignItems: 'center'}}>
                  {/* KPI Table */}
                  <KpiTable
                    pageStates={props.pageStates}
                    tableData={tableData}
                    type={'services'}
                    filters={serviceHealthFilters}
                    filterOptions={{'serviceHealthVehicleSNs': vehicleSNFilterOptions}}
                    handleFilters={handleFilters}
                  />
                </Grid>
              </Box>
            </Grid>
            {!smallScreen && (
              <Grid
                item
                xs={5}
                md={4}
                lg={3}
                sx={{
                  display: smallScreen ? 'none' : 'block',
                }}
              >
                <StatsViewReportSideControlWrapper
                  filterControlComponents={filterControlComponents()}
                  displayControlComponents={displayControlComponents()}
                />
              </Grid>
            )}
          </Grid>
        </React.Fragment>
      ) : !servicesBarData ? (
        <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
      ) : (
        <React.Fragment>
          {/* Filters */}
          {smallScreen && (
            <React.Fragment>
              <StatsViewReportFiltersWrapper key={`ServiceCompleteFilter`}>
                {filterControlComponents()}
              </StatsViewReportFiltersWrapper>
              {/* Sort By */}
              <StatsViewReportSortByWrapper key={`ServiceCompleteSort`}>
                {displayControlComponents()}
              </StatsViewReportSortByWrapper>
            </React.Fragment>
          )}
          {/* Graph and XY Axis Control */}
          <Grid container sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}} spacing={0}>
            <Grid item xs={smallScreen ? 12 : 7} md={8} lg={9} sx={StatsViewReportGraphAndControlStyle}>
              {/* Graph */}
              <Grid item xs={12} lg={10} sx={{textAlign: 'center'}}>
                {serviceHealthBarGraph()}
              </Grid>
              {/* X-Axis Control */}
              <Grid
                item
                xs={12}
                sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}
              >
                <FormControl sx={{minWidth: '120px', m: 1}}>
                  <InputLabel>View by</InputLabel>
                  <Select
                    size='small'
                    label='View by'
                    name='serviceHealthGroupByMachineType'
                    value={serviceHealthFilters.serviceHealthGroupByMachineType}
                    onChange={handleChecked}
                  >
                    <MenuItem value={true}>{'Machine Type'}</MenuItem>
                    <MenuItem value={false}>{'Vehicle'}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {!smallScreen && (
              <Grid
                item
                xs={5}
                md={4}
                lg={3}
                sx={{
                  display: smallScreen ? 'none' : 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'start',
                  justifyContent: 'center',
                  borderRadius: '4px',
                  backgroundColor: '#ffffff',
                  boxShadow: 3,
                  padding: 1,
                }}
              >
                <StatsViewReportSideControlWrapper
                  filterControlComponents={filterControlComponents()}
                  displayControlComponents={displayControlComponents()}
                />
              </Grid>
            )}
          </Grid>
        </React.Fragment>
      )}
    </Box>
  );
}

export {KpiServiceCompletion};
