import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {TextField, Autocomplete} from '@mui/material';

import {
  ModalHeader,
  ModalFooter,
  ModalRowSection,
  ModalWideColumnSection,
  ModalBody,
  ModalWarning,
} from '../../components/Modal';

import {setInspectionSelectedVehicle, setInspectionNameInput} from './inspectionFormSlice';

function InspectionFormSelectModal(props) {
  const dispatch = useDispatch();

  // Collect the needed data to populate the selection modal
  const inspectionVehicles = useSelector((state) => {
    return state.inspectionForm.vehicles;
  });

  const inspectionNameInput = useSelector((state) => {
    return state.inspectionForm.inspectionNameInput;
  });

  const inspectionReportNameInputRequired = useSelector((state) => {
    return state.app.customerSettings.inspection.inspectionReportNameInput;
  });

  // Get currently selected vehicle
  const scannedVehSN = useSelector((state) => {
    return state.inspectionForm.scannedVehicle;
  });

  // Set the component specific states
  const [selectedVehicle, setSelectedVehicle] = useState({
    value: '',
    label: '',
  });
  const [selectOptions, setSelectOptions] = useState({
    vehicles: [],
  });

  useEffect(() => {
    // Update select dropdown options when new vehicle data has come in
    updateSelectOptions();
  }, [inspectionVehicles, scannedVehSN]);

  const [selectModalWarnings, setSelectModalWarnings] = useState({
    noVehicleSelectedWarning: false,
    noVehicleFoundWarning: false,
    noNameInputWarning: false,
  });

  // Updates the select dropdowns and options
  function updateSelectOptions() {
    const vehicleOptions = [];
    // Iterate through each vehicle and create an option for each vehicle
    for (let i = 0; i < inspectionVehicles.length; i++) {
      const vehicleInspectionActive = !(inspectionVehicles[i]?.inspectionActive === false);
      if (!vehicleInspectionActive || inspectionVehicles[i].archived) {
        continue;
      }
      vehicleOptions.push({
        value: inspectionVehicles[i].serialNumber,
        label: inspectionVehicles[i].name,
      });

      // If a vehicle on the list matches the currently scanned
      // vehicle then set it as the default
      if (inspectionVehicles[i].serialNumber === scannedVehSN) {
        const defaultVehSN = inspectionVehicles[i].serialNumber;
        const defaultVehLabel = inspectionVehicles[i].name;

        setSelectedVehicle({
          value: defaultVehSN,
          label: defaultVehLabel,
        });
      }
    }

    setSelectOptions({
      vehicles: vehicleOptions,
    });
  }

  // Event handler when form confirm is clicked
  function inspectionVehicleSelectionConfirm() {
    const defaultSelectPageWarnings = {
      noVehicleFoundWarning: false,
      noVehicleSelectedWarning: false,
      noNameInputWarning: false,
    };

    if (inspectionReportNameInputRequired && (!inspectionNameInput || !inspectionNameInput.trim())) {
      setSelectModalWarnings({
        ...defaultSelectPageWarnings,
        noNameInputWarning: true,
      });
      return;
    }

    if (typeof selectedVehicle.value !== 'undefined' && selectedVehicle.value != '') {
      // Search for the full vehicle document using vehicleSN
      const vehicles = inspectionVehicles.filter((inspectionVehicle) => {
        return inspectionVehicle.serialNumber == selectedVehicle.value;
      });

      // Check that a single matching vehicle was found
      if (vehicles.length == 1) {
        // Set the store to track the selecte vehicle for inspection reports
        dispatch(setInspectionSelectedVehicle(vehicles[0]));
      } else {
        setSelectModalWarnings({
          ...defaultSelectPageWarnings,
          noVehicleFoundWarning: true,
        });
      }
    } else {
      setSelectModalWarnings({
        ...defaultSelectPageWarnings,
        noVehicleSelectedWarning: true,
      });
    }
  }

  function selectorValueSelected(vehicleSN) {
    const vehicles = inspectionVehicles.filter((inspectionVehicle) => {
      return inspectionVehicle.serialNumber == vehicleSN;
    });

    if (!vehicles.length || vehicles.length !== 1) {
      return;
    }

    const selVehSN = vehicles[0].serialNumber;
    const selVehLabel = vehicles[0].name;

    setSelectedVehicle({
      value: selVehSN,
      label: selVehLabel,
    });
  }

  // Create modal for a vehicle to be selected
  return (
    <div>
      <ModalHeader title='Create Inspection Report' noCloseButton={true} />
      <ModalBody>
        <ModalRowSection>
          <ModalWideColumnSection>
            <div className='font-weight-bold border-bottom'>Vehicle</div>
          </ModalWideColumnSection>
          <ModalWideColumnSection>
            <div className='row m-0'>
              <Autocomplete
                size='small'
                fullWidth
                value={selectedVehicle}
                onChange={(event, selectedOption) => {
                  if (selectedOption) {
                    selectorValueSelected(selectedOption.value);
                  } else {
                    setSelectedVehicle({
                      value: '',
                      label: '',
                    });
                  }
                }}
                options={selectOptions.vehicles}
                getOptionLabel={(option) => {
                  if (typeof option == 'object') {
                    return option.label;
                  } else {
                    const optionObj = props.options.find((opt) => {
                      return opt.value == option;
                    });
                    if (optionObj) {
                      return optionObj.label;
                    } else {
                      return '';
                    }
                  }
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      placeholder='Please Select a Vehicle...'
                      error={props.error}
                      helperText={props.helperText}
                    />
                  );
                }}
                isOptionEqualToValue={(option, value) => {
                  return option.value === value.value;
                }}
              />
            </div>
          </ModalWideColumnSection>
        </ModalRowSection>
        {inspectionReportNameInputRequired && (
          <ModalRowSection>
            <ModalWideColumnSection>
              <div className='font-weight-bold border-bottom'>Inspector/User Name</div>
            </ModalWideColumnSection>
            <ModalWideColumnSection>
              <div className='row m-0'>
                <TextField
                  size='small'
                  placeholder='Please enter your name'
                  fullWidth
                  required
                  value={inspectionNameInput}
                  onChange={(e) => {
                    dispatch(setInspectionNameInput(e.target.value));
                    if (selectModalWarnings.noNameInputWarning && e.target.value) {
                      setSelectModalWarnings((values) => {
                        return {...values, noNameInputWarning: false};
                      });
                    }
                  }}
                />
              </div>
            </ModalWideColumnSection>
          </ModalRowSection>
        )}
        {selectModalWarnings.noVehicleSelectedWarning && (
          <ModalWarning text='No vehicle selected. Please select a vehicle' />
        )}
        {selectModalWarnings.noVehicleFoundWarning && <ModalWarning text='No vehicle found. Please select a vehicle' />}
        {selectModalWarnings.noNameInputWarning && <ModalWarning text='Please Enter a Inspector/User name' />}
      </ModalBody>
      <ModalFooter
        onSubmit={() => {
          return inspectionVehicleSelectionConfirm();
        }}
        submitText='Next'
        noCancelButton={true}
      />
    </div>
  );
}

export {InspectionFormSelectModal};
