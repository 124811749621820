import React, {useState, useEffect, useMemo, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as turf from '@turf/turf';
import {Map, useMap} from '@vis.gl/react-google-maps';
import {ScatterplotLayer, GeoJsonLayer, LineLayer, IconLayer, TextLayer} from '@deck.gl/layers';
import {GoogleMapsOverlay} from '@deck.gl/google-maps';
import {unitsAreaConversion, AC_TO_METERS2} from '../../app/utils';
import {updateZoneZoom} from './zoneInfoSlice';

const zoneColorsList = ['#af4a4d', '#4daf4a', '#4a4daf', '#af4a99', '#000000', '#ffffff'];

function DeckGLOverlay(props) {
  const map = useMap();
  const overlay = useMemo(() => {
    return new GoogleMapsOverlay(props);
  }, []);

  useEffect(() => {
    overlay.setMap(map);
    return () => {
      return overlay.setMap(null);
    };
  }, [map]);

  overlay.setProps(props);
  return null;
}

function MapControlButtons(props) {
  const map = useMap();
  const ref = useRef();

  useEffect(() => {
    if (map && ref) {
      map.controls[google.maps.ControlPosition.TOP_RIGHT].push(ref.current);
    }
  }, [map, ref]);

  return (
    <div ref={ref}>
      <div>
        <button className='btn-lg bg-light mt-2 mr-2' onClick={props.showAll}>
          <FontAwesomeIcon icon='fas fa-eye' fixedWidth />
        </button>
      </div>
      <div>
        <button
          className='btn-lg bg-light mr-2'
          onClick={() => {
            return props.setDisplayZones(!props.displayZones);
          }}
        >
          <FontAwesomeIcon icon={props.displayZones ? 'fas fa-clone' : 'far fa-clone'} fixedWidth />
        </button>
      </div>
      {props.displayZones && (
        <React.Fragment>
          <div>
            <button
              className='btn-lg bg-light mr-2 mt-n1'
              style={{borderTopLeftRadius: '0px', borderTopRightRadius: '0px'}}
              onClick={() => {
                props.changeZoneLabels();
              }}
            >
              <FontAwesomeIcon icon={'fas fa-comment-alt'} fixedWidth />
            </button>
          </div>
          <div>
            <button
              className='btn-lg bg-light mr-2 mt-n1'
              style={{borderTopLeftRadius: '0px', borderTopRightRadius: '0px'}}
              onClick={() => {
                props.changeZonesColor();
              }}
            >
              <FontAwesomeIcon icon={'fas fa-palette'} fixedWidth />
            </button>
          </div>
          <div>
            <button
              className='btn-lg bg-light mr-2 mt-n1'
              onClick={() => {
                return props.setDisplayRowBearing(!props.displayRowBearing);
              }}
            >
              <FontAwesomeIcon icon={props.displayRowBearing ? 'fas fa-compass' : 'far fa-compass'} fixedWidth />
            </button>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

function MapViewMap(props) {
  const dispatch = useDispatch();

  const zonesDicts = useSelector((state) => {
    return state.zoneinfo.zonesDicts;
  });
  const geofencesDict = useSelector((state) => {
    return state.zoneinfo.geofencesDict;
  });
  const statusFilter = useSelector((state) => {
    return state.zoneinfo.statusFilter;
  });
  const zoneZoom = useSelector((state) => {
    return state.zoneinfo.zoneZoom;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const unitsLengthSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsLength;
  });
  const unitsAreaSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsArea;
  });

  // Map states
  const [zoneColorIndex, setZoneColorIndex] = useState(0);
  const [zoneLabelsIndex, setZoneLabelsIndex] = useState(0);
  const [zoneInfoWindow, setZoneInfoWindow] = useState(null);
  const [bearingInfoWindow, setBearingInfoWindow] = useState(null);
  const [loaded, setLoaded] = useState(null);
  const [geofenceLayerData, setGeofenceLayerData] = useState(null);
  const [labelLayerData, setLabelLayerData] = useState(null);
  const [rowBearingLayerData, setRowBearingLayerData] = useState(null);
  const [rowBearingLayerArrows, setRowBearingLayerArrows] = useState(null);
  const [displayZones, setDisplayZones] = useState(true);
  const [mapZoomLevel, setMapZoomLevel] = useState(0);
  const [displayRowBearing, setDisplayRowBearing] = useState(false);
  const [zoneExtremes, setZoneExtremes] = useState({
    latMax: null,
    latMin: null,
    lngMax: null,
    lngMin: null,
  });

  const map = useMap();
  const zoneInfoEnabled =
    typeof userSettings.general.zoneInfoEnabled != 'undefined' && userSettings.general.zoneInfoEnabled;

  useEffect(() => {
    if (map != null) {
      showAll();
    }
  }, [zoneExtremes]);

  useEffect(() => {
    if (map != null) {
      zoomToZone();
    }
  }, [zoneZoom]);

  useEffect(() => {
    if (map != null) {
      updateZoneExtremes();
    }
  }, [zonesDicts]);

  // Onload function
  useEffect(() => {
    if (loaded) {
      const zoneInfoWin = new google.maps.InfoWindow();
      const bearingInfoWin = new google.maps.InfoWindow();
      setZoneInfoWindow(zoneInfoWin);
      setBearingInfoWindow(bearingInfoWin);

      map.addListener('zoom_changed', function () {
        setMapZoomLevel(map.getZoom());
      });

      setMapZoomLevel(map.getZoom());
    }
  }, [loaded]);

  // Generate data for geofence and label layers
  useEffect(() => {
    // Check if zone data is defined
    if (zonesDicts == undefined || geofencesDict == undefined || zonesDicts == null || geofencesDict == null) {
      return;
    }

    // Check if zone data is empty
    if (
      Object.keys(geofencesDict).length == 0 ||
      Object.keys(zonesDicts.blocks).length == 0 ||
      Object.keys(zonesDicts.fields).length == 0 ||
      Object.keys(zonesDicts.regions).length == 0
    ) {
      return;
    }

    // Use geojson layer becaus eof multipolygons, after new zone management is implement
    // consider using polygon layer since geofence structure changed
    const geojsonBase = {
      type: 'FeatureCollection',
      name: 'Geofences Layer',
      crs: {
        type: 'name',
        properties: {
          name: 'urn:ogc:def:crs:OGC:1.3:CRS84',
        },
      },
      features: [],
    };

    // Create list for labels
    const labelData = [];
    const rowBearingData = [];
    const positions = [];

    // Iterate through blocks
    Object.values(zonesDicts.blocks).forEach((block) => {
      // Skip block based on blockActive
      if (!block.active) {
        return;
      }

      // // Check if should display based on active filter
      // if ((statusFilter == 'active' && !block.active) || (statusFilter == 'archived' && block.active)) {
      //   return;
      // }

      const fieldName = zonesDicts.fields[block.fieldId] ? zonesDicts.fields[block.fieldId].name : '';
      const regionName = zonesDicts.regions[block.regionId] ? zonesDicts.regions[block.regionId].name : '';
      const blockIntelliBlockNums = block.intelliblockNums;

      // Get center point of block
      const latCenter = (block.latMax + block.latMin) / 2;
      const lngCenter = (block.lngMax + block.lngMin) / 2;

      // Determine acreage
      const totalAcres = block.areaMeters2 / AC_TO_METERS2;
      let acreageWithUnit = `${totalAcres.toFixed(2)} ac`;
      if (unitsAreaSystem == 'hectare') {
        acreageWithUnit = `${unitsAreaConversion(totalAcres, 'ha', 'ac').toFixed(2)} ha`;
      }

      // Iterate through each multipolygon for plotting
      let labelAdded = false;
      for (let j = 0; j < blockIntelliBlockNums.length; j++) {
        if (!Object.prototype.hasOwnProperty.call(geofencesDict, blockIntelliBlockNums[j])) {
          continue;
        }

        const geoFence = geofencesDict[blockIntelliBlockNums[j]];

        // Determine row spacing
        let rowSpacingWithUnit = 'N/A';
        if (geoFence.rowSpacingMeters > 0) {
          rowSpacingWithUnit =
            unitsLengthSystem == 'imperial'
              ? `${(geoFence.rowSpacingMeters * 3.28084).toFixed(2)} ft`
              : `${geoFence.rowSpacingMeters.toFixed(2)} m`;
        }

        // Determine row bearing
        let rowBearing = 'N/A';
        if (geoFence.rowBearing != -1) {
          rowBearing = geoFence.rowBearing;
        }

        // Add to label list, once per block
        if (!labelAdded) {
          labelAdded = true;

          const newLabelData = {
            position: [lngCenter, latCenter],
            blockName: block.name,
            fieldName: fieldName,
            regionName: regionName,
            rowSpacingWithUnit: rowSpacingWithUnit,
            acreageWithUnit: acreageWithUnit,
          };

          if (Object.prototype.hasOwnProperty.call(block, 'notes')) {
            newLabelData.notes = `${block.notes}`;
          }

          labelData.push(newLabelData);
        }

        // Check if geometry is present to ensure graceful error handling
        let coords = [];
        if (Object.prototype.hasOwnProperty.call(geoFence, 'geometry') && geoFence.geometry != undefined) {
          if (Object.prototype.hasOwnProperty.call(geoFence.geometry, 'coordinates')) {
            coords = geoFence.geometry.coordinates;
          }
        }

        // Add all properties to be visible in the info window
        // on the geojson layer
        const newGeofenceObj = {
          type: 'Feature',
          properties: {
            blockName: block.name,
            fieldName: fieldName,
            regionName: regionName,
            rowSpacingWithUnit: rowSpacingWithUnit,
            acreageWithUnit: acreageWithUnit,
            rowBearing: rowBearing,
          },
          geometry: {
            type: 'Polygon',
            coordinates: coords,
          },
        };

        if (Object.prototype.hasOwnProperty.call(block, 'notes')) {
          newGeofenceObj.properties.notes = `${block.notes}`;
        }

        geojsonBase.features.push(newGeofenceObj);

        // Add row bearing layer
        for (let k = 0; k < coords.length; k++) {
          if (geoFence.rowBearing != -1 && coords[k].length > 0) {
            // Get bearing in degree
            const bearing = geoFence.rowBearing;
            const bearingDir1 = bearing > 180 ? bearing - 180 : bearing; // Range 0 to 180
            const bearingDir2 = bearing - 180; // Range -180 to 0

            // Create polygon object and find center
            const outerCoords = coords[0];
            const polygonObj = turf.polygon([outerCoords]);
            const polygonCenter = turf.center(polygonObj);

            // Create long line extending from center
            const point1 = turf.destination(polygonCenter, 5, bearingDir1, {units: 'kilometers'});
            const point2 = turf.destination(polygonCenter, 5, bearingDir2, {units: 'kilometers'});
            const lineString = turf.lineString([point1.geometry.coordinates, point2.geometry.coordinates]);

            // Get intersections between line and polygon
            const intersections = turf.lineIntersect(lineString, polygonObj);
            if (intersections.features.length == 0) {
              continue;
            }

            // Create line points to plot
            const linePoints = [];
            intersections.features.forEach((feature) => {
              linePoints.push({
                lng: feature.geometry.coordinates[0],
                lat: feature.geometry.coordinates[1],
              });
            });
            const start = intersections.features[0].geometry.coordinates;
            const end = intersections.features[1].geometry.coordinates;
            const lineData = {
              bearing: bearing,
              intelliblockNum: geoFence.intelliblockNum,
              start: [start[0], start[1]],
              end: [end[0], end[1]],
            };

            // Determine positions for the direction arrows of the row bearing
            const angle = 90 - Math.atan2(end[0] - start[0], end[1] - start[1]) * (180 / Math.PI);
            const x1 = start[0] + 0.15 * (end[0] - start[0]);
            const y1 = start[1] + 0.15 * (end[1] - start[1]);
            const x2 = end[0] - 0.15 * (end[0] - start[0]);
            const y2 = end[1] - 0.15 * (end[1] - start[1]);

            // Add data objects
            positions.push({position: [x1, y1, angle + 180]});
            positions.push({position: [x2, y2, angle]});
            rowBearingData.push(lineData);
          }
        }
      }
    });

    setGeofenceLayerData(geojsonBase);
    setLabelLayerData(labelData);
    setRowBearingLayerData(rowBearingData);
    setRowBearingLayerArrows(positions);
  }, [geofencesDict, zonesDicts]);

  function showAll() {
    if (
      zoneExtremes.latMin == null ||
      zoneExtremes.latMax == null ||
      zoneExtremes.lngMin == null ||
      zoneExtremes.lngMax == null
    ) {
      return;
    }
    if (
      zoneExtremes.latMin == undefined ||
      zoneExtremes.latMax == undefined ||
      zoneExtremes.lngMin == undefined ||
      zoneExtremes.lngMax == undefined
    ) {
      return;
    }
    const sw = {lat: zoneExtremes.latMin, lng: zoneExtremes.lngMin};
    const ne = {lat: zoneExtremes.latMax, lng: zoneExtremes.lngMax};

    const bounds = new google.maps.LatLngBounds();
    bounds.extend(sw);
    bounds.extend(ne);

    map.fitBounds(bounds);
    map.setZoom(map.getZoom() + 0.0);
  }

  function updateZoneExtremes() {
    // Track extremes of zones
    const extremes = {
      latMax: null,
      latMin: null,
      lngMax: null,
      lngMin: null,
    };

    Object.values(zonesDicts.regions).forEach((region) => {
      if (extremes.latMax == null || region.latMax > extremes.latMax) {
        extremes.latMax = region.latMax;
      }
      if (extremes.latMin == null || region.latMin < extremes.latMin) {
        extremes.latMin = region.latMin;
      }
      if (extremes.lngMax == null || region.lngMax > extremes.lngMax) {
        extremes.lngMax = region.lngMax;
      }
      if (extremes.lngMin == null || region.lngMin < extremes.lngMin) {
        extremes.lngMin = region.lngMin;
      }
    });
    setZoneExtremes(extremes);
  }

  function zoomToZone() {
    Object.keys(zonesDicts).forEach((zoneLevel) => {
      Object.values(zonesDicts[zoneLevel]).forEach((zoneObj) => {
        if (zoneObj.name == zoneZoom) {
          const latMax = parseFloat(zoneObj.latMax);
          const latMin = parseFloat(zoneObj.latMin);
          const lngMax = parseFloat(zoneObj.lngMax);
          const lngMin = parseFloat(zoneObj.lngMin);

          const sw = {lat: latMin, lng: lngMin};
          const ne = {lat: latMax, lng: lngMax};

          const bounds = new google.maps.LatLngBounds();
          bounds.extend(sw);
          bounds.extend(ne);

          map.fitBounds(bounds);
          map.setZoom(map.getZoom() + 0.0);

          dispatch(updateZoneZoom(''));
        }
      });
    });
  }

  function changeZoneLabels() {
    const zoneInfoEnabled =
      typeof userSettings.general.zoneInfoEnabled != 'undefined' && userSettings.general.zoneInfoEnabled;
    const labelCycleLength = zoneInfoEnabled ? 5 : 3;
    setZoneLabelsIndex((zoneLabelsIndex + 1) % labelCycleLength);
  }

  function changeZonesColor() {
    setZoneColorIndex((zoneColorIndex + 1) % zoneColorsList.length);
  }

  // Layers for map
  const layers = [
    new GeoJsonLayer({
      id: 'geofences',
      data: geofenceLayerData,
      visible: displayZones,
      stroked: true,
      filled: true,
      pickable: true,
      autoHighlight: true,
      highlightColor: [255, 255, 0, 128], // Yellow
      getFillColor: (geofence) => {
        const hex = zoneColorsList[zoneColorIndex];
        const rgb = hex.match(/[0-9a-f]{2}/g).map((x) => {
          return parseInt(x, 16);
        });
        rgb.push(70); // Opacity
        return rgb;
      },
      getLineColor: (geofence) => {
        const hex = zoneColorsList[zoneColorIndex];
        const rgb = hex.match(/[0-9a-f]{2}/g).map((x) => {
          return parseInt(x, 16);
        });
        return rgb;
      },
      getLineWidth: 0.2,
      onClick: (clickedObj) => {
        const geofence = clickedObj.object;

        // Create info window content
        let infoWindowContent = `<div><b>${geofence.properties.blockName}</b></div>`;

        // Display field and region
        infoWindowContent +=
          `<div style="font-size:10px"><b>Field:</b> ${geofence.properties.fieldName}</div>` +
          `<div style="font-size:10px"><b>Region:</b> ${geofence.properties.regionName}</div>`;

        if (Object.prototype.hasOwnProperty.call(geofence.properties, 'notes')) {
          infoWindowContent += `<div style="font-size:10px"><b>Notes:</b> ${geofence.properties.notes}</div>`;
        }

        // Display zone info
        if (zoneInfoEnabled) {
          infoWindowContent += `<div style="font-size:10px"><b>Row Spacing:</b> ${geofence.properties.rowSpacingWithUnit}</div>`;

          let areaContent = `<div style="font-size:10px"><b>Acreage:</b> ${geofence.properties.acreageWithUnit}</div>`;
          if (unitsAreaSystem == 'hectare') {
            areaContent = `<div style="font-size:10px"><b>Hectares:</b> ${geofence.properties.acreageWithUnit}</div>`;
          }
          infoWindowContent += areaContent;
        }

        // Add row bearing display info
        if (displayRowBearing) {
          infoWindowContent += `<div style="font-size:10px"><b>Bearing:</b> ${`${Math.round(
            geofence.properties.rowBearing
          )}°`}</div>`;
        }

        if (geofence) {
          zoneInfoWindow.setContent(infoWindowContent);
          zoneInfoWindow.setPosition(new google.maps.LatLng(clickedObj.coordinate[1], clickedObj.coordinate[0]));
          zoneInfoWindow.open({
            map,
            shouldFocus: false,
          });
        } else {
          zoneInfoWindow.close();
        }
      },
      updateTriggers: {
        getFillColor: [zoneColorIndex],
        getLineColor: [zoneColorIndex],
      },
    }),
    new LineLayer({
      id: 'BearingLines',
      data: rowBearingLayerData,
      visible: displayRowBearing && mapZoomLevel > 12,
      getSourcePosition: (d) => {
        return d.start;
      },
      getTargetPosition: (d) => {
        return d.end;
      },
      getColor: [0, 0, 0],
      getWidth: 4,
      pickable: true,
      onHover: (hoverObj) => {
        if (hoverObj.picked) {
          const infoWindowContent = `<div>Bearing: ${`${Math.round(hoverObj.object.bearing)}°`}</div>`;
          bearingInfoWindow.setContent(infoWindowContent);
          bearingInfoWindow.setPosition(new google.maps.LatLng(hoverObj.coordinate[1], hoverObj.coordinate[0]));
          bearingInfoWindow.open({
            map,
            shouldFocus: false,
          });
        } else {
          bearingInfoWindow.close();
        }
      },
    }),
    new IconLayer({
      id: `bearingArrows`,
      data: rowBearingLayerArrows,
      visible: displayRowBearing && mapZoomLevel > 12,
      getColor: [255, 255, 255],
      getIcon: () => {
        return {
          url: '/img/arrow.png', // Your arrow icon URL
          width: 200,
          height: 200,
        };
      },
      getPosition: (d) => {
        return [d.position[0], d.position[1]];
      },
      getAngle: (d) => {
        return d.position[2];
      },
      sizeScale: 25,
      sizeUnits: 'meters',
      sizeMinPixels: 5,
      sizeMaxPixels: 15,
    }),
    new TextLayer({
      id: 'labels',
      data: labelLayerData,
      visible: displayZones && mapZoomLevel > 14,
      getPosition: (labelData) => {
        return labelData.position;
      },
      getText: (labelData) => {
        let labelText = labelData.blockName;
        if (zoneLabelsIndex == 1) {
          labelText = labelData.fieldName;
        } else if (zoneLabelsIndex == 2) {
          labelText = labelData.regionName;
        } else if (zoneLabelsIndex == 3) {
          labelText = `Row spacing: ${labelData.rowSpacingWithUnit}`;
        } else if (zoneLabelsIndex == 4) {
          labelText = `Acreage: ${labelData.acreageWithUnit}`;
        } else {
          // Default to block name if index is invalid
          labelText = labelData.blockName;
        }
        return labelText;
      },
      getColor: [0, 0, 0],
      background: true,
      backgroundPadding: [3, 3],
      getBackgroundColor: [255, 255, 255, 128],
      getSize: 12,
      fontFamily: 'sans-serif',
      fontWeight: 'bold',
      getTextAnchor: 'middle',
      getAlignmentBaseline: 'center',
      updateTriggers: {
        getText: [zoneLabelsIndex],
      },
    }),
  ];

  return (
    <React.Fragment>
      <Map
        defaultCenter={{lat: 37.9718, lng: -122.7651}}
        defaultZoom={9}
        mapTypeId={'hybrid'} // Set map type to satellite
        gestureHandling={'greedy'}
        disableDefaultUI={true} // Optional: removes default UI controls
        minZoom={5}
        tilt={0}
        styles={[
          {
            featureType: 'poi',
            stylers: [{visibility: 'off'}],
          },
        ]} // Optional: empty styles array to ensure no style overrides
        reuseMaps={false}
      >
        <DeckGLOverlay
          layers={layers}
          onLoad={() => {
            setLoaded(true);
          }}
        />
        <MapControlButtons
          displayZones={displayZones}
          displayRowBearing={displayRowBearing}
          setDisplayZones={setDisplayZones}
          setDisplayRowBearing={setDisplayRowBearing}
          changeZoneLabels={changeZoneLabels}
          changeZonesColor={changeZonesColor}
          showAll={showAll}
        />
      </Map>
    </React.Fragment>
  );
}

export {MapViewMap};
