import React, {useState, useEffect} from 'react';
import BarChart from '../../../components/BarChart';
import {useDispatch, useSelector} from 'react-redux';
import {customChartProperties} from '../../../app/utils';
import {genInspectionRateData} from '../dashboardDataProcessing';
import {
  KpiTooltip,
  ScorecardSelectHolder,
  KpiTable,
  StatsViewReportGraphAndControlStyle,
  StatsViewReportMainWrapperStyle,
} from '../dashboardUtils';
import {DateTime} from 'luxon';
import ChartJS from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import {sortVehicleNamesHelper, unionLabelsAndDataVehicles} from '../../../app/utils';
import {setCompareByDatesCache, setCompareByToggle} from '../dashboardSlice';
import {CircularProgress, Grid, Box} from '@mui/material';
import {displayPartsToString} from 'typescript';

ChartJS.register(annotationPlugin);

function KpiInspectionRates(props) {
  const dispatch = useDispatch();
  const loading = useSelector((state) => {
    return state.dashboard.loading;
  });
  const operationsData = useSelector((state) => {
    return state.dashboard.operationsData;
  });
  const vehicleSNDict = useSelector((state) => {
    return state.dashboard.vehicleSNDict;
  });
  const comparisonLoading = useSelector((state) => {
    return state.dashboard.comparisonLoading;
  });
  const inspectionsData = useSelector((state) => {
    return state.dashboard.inspectionsData;
  });
  const inspectionsDict = useSelector((state) => {
    return state.dashboard.inspectionsDict;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const compareByInspectionsData = useSelector((state) => {
    return state.dashboard.compareByInspectionsData;
  });
  const compareByDatesCache = useSelector((state) => {
    return state.dashboard.compareByDatesCache;
  });
  const compareByToggle = useSelector((state) => {
    return state.dashboard.compareByToggle;
  });
  const addToScorecardTrigger = useSelector((state) => {
    return state.dashboard.addToScorecardTrigger;
  });
  const selectedSubset = useSelector((state) => {
    return state.dashboard.selectedSubset;
  });

  const [filters, setFilters] = useState({
    'inspectionVehicleSNs': [],
  });

  const [filterOptions, setFilterOptions] = useState({
    'inspectionVehicleSNs': [],
  });

  function handleFilters(value) {
    setFilters((values) => {
      return {...values, 'inspectionVehicleSNs': value};
    });
  }

  const [inspectionRateData, setInspectionRateData] = useState(null);
  const [addToScorecardOpened, setAddToScorecardOpened] = useState(null);
  const [totalInspections, setTotalInspections] = useState(0);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (props?.pageFilters) {
      setFilters((values) => {
        return {
          ...values,
          ...props.pageFilters,
        };
      });
    }
    if (props.pageStates?.compareByDateRange && props.pageStates?.compareByToggle) {
      dispatch(setCompareByDatesCache(props.pageStates?.compareByDateRange));
    }
    if (props.pageStates?.compareByToggle) {
      dispatch(setCompareByToggle(props.pageStates?.compareByToggle));
    }
    // If we are in a scorecard but compareByToggle is not set. Then we need to set it to false
    else if (props.hasOwnProperty('pageStates')) {
      dispatch(setCompareByToggle(false));
    }
  }, [props.pageStates, props.pageFilters]);

  useEffect(() => {
    if (addToScorecardOpened == null) {
      setAddToScorecardOpened(false);
    } else {
      setAddToScorecardOpened(true);
    }
  }, [addToScorecardTrigger]);

  useEffect(() => {
    const vehicleSNs = Object.keys(vehicleSNDict);
    vehicleSNs.sort((vehicleSNa, vehicleSNb) => {
      const aName = vehicleSNDict[vehicleSNa].name;
      const bName = vehicleSNDict[vehicleSNb].name;
      return sortVehicleNamesHelper(aName, bName);
    });
    const vehOptions = vehicleSNs.map((sn) => {
      return {
        value: sn,
        text: vehicleSNDict[sn].name,
      };
    });
    setFilterOptions({'inspectionVehicleSNs': vehOptions});
  }, [operationsData, vehicleSNDict]);

  useEffect(() => {
    if (operationsData != null) {
      generateData();
    }
  }, [operationsData, inspectionsData, inspectionsDict, filters, compareByToggle, compareByInspectionsData]);

  function generateData() {
    if (inspectionsData && inspectionsDict) {
      const vehFiltersMappedToNames = filters['inspectionVehicleSNs'].map((sn) => {
        if (vehicleSNDict.hasOwnProperty(sn) && vehicleSNDict[sn].hasOwnProperty('name')) {
          return vehicleSNDict[sn].name;
        }
      });

      // Generate data
      const inspectionRateDataTemp = genInspectionRateData(
        inspectionsData.totalResults.pass,
        inspectionsData.totalResults.fail
      );
      let compareByInspectionRateDataTemp;
      if (compareByToggle && compareByInspectionsData != null) {
        compareByInspectionRateDataTemp = genInspectionRateData(
          compareByInspectionsData.totalResults.pass,
          compareByInspectionsData.totalResults.fail,
          true
        );
        inspectionRateDataTemp.datasets = [
          ...inspectionRateDataTemp.datasets,
          ...compareByInspectionRateDataTemp.datasets,
        ];
      }

      if (inspectionsData.totalResults.pass + inspectionsData.totalResults.fail > 0) {
        setInspectionRateData(inspectionRateDataTemp);
      } else {
        setInspectionRateData(null);
      }
      const totalInspectionsTemp = inspectionsData.totalResults.pass + inspectionsData.totalResults.fail;
      setTotalInspections(totalInspectionsTemp);

      // Generate data based on filters
      if (filters['inspectionVehicleSNs'].length > 0) {
        const filteredTotalResults = {pass: 0, fail: 0};
        Object.keys(inspectionsData.vehicleResults).forEach((vehName) => {
          if (vehFiltersMappedToNames.includes(vehName)) {
            filteredTotalResults.pass += inspectionsData.vehicleResults[vehName].pass;
            filteredTotalResults.fail += inspectionsData.vehicleResults[vehName].fail;
          }
        });

        const inspectionRateDataFiltered = genInspectionRateData(filteredTotalResults.pass, filteredTotalResults.fail);
        if (filteredTotalResults.pass + filteredTotalResults.fail > 0) {
          setInspectionRateData(inspectionRateDataFiltered);
          const totalInspectionsTemp = filteredTotalResults.pass + filteredTotalResults.fail;
          setTotalInspections(totalInspectionsTemp);
        } else {
          setInspectionRateData(null);
        }
      }

      // Generate table data
      const inspectionsTableDataUnfiltered = Object.keys(inspectionsData.vehicleResults).map((vehicleName) => {
        let failedItemsString = '';
        let failedItemsDatesString = '';
        inspectionsData.vehicleResults[vehicleName].failedItems.forEach((item) => {
          let dateTimeString = item.dateTime;
          if (typeof dateTimeString == 'object') {
            dateTimeString = item.dateTime;
          }
          failedItemsString += inspectionsDict[item.id].name + ',\n';
          failedItemsDatesString +=
            DateTime.fromISO(dateTimeString).setZone(customerSettings.general.timeZone).toLocaleString() + ',\n';
        });
        return {
          vehicleName: vehicleName,
          failures: inspectionsData.vehicleResults[vehicleName].fail,
          failedItems: failedItemsString.slice(0, -2),
          failedItemsDates: failedItemsDatesString.slice(0, -2),
        };
      });

      const inspectionsTableData = inspectionsTableDataUnfiltered.filter((row) => {
        return (
          row.failures > 0 &&
          (vehFiltersMappedToNames.includes(row.vehicleName) || filters['inspectionVehicleSNs'].length == 0)
        );
      });
      setTableData(inspectionsTableData);
    }
  }

  function inspectionRatesBarGraph() {
    return (
      <div style={{height: '50vh', maxHeight: '400px'}}>
        {/* Chart */}
        <BarChart
          data={inspectionRateData}
          options={{
            maintainAspectRatio: false,
            plugins: {
              title: {
                text: 'Inspection Pass vs Fail',
                display: true,
                font: {
                  size: customChartProperties.titleFontSize,
                  lineHeight: customChartProperties.lineHeight,
                },
              },
              legend: {
                onClick: (e) => {
                  return false;
                },
                labels: {
                  font: {
                    size: customChartProperties.legendFontSize,
                  },
                },
              },
            },
            scales: {
              y: {
                title: {
                  text: '% Passed',
                  display: true,
                  font: {
                    size: customChartProperties.axisTitleFontSize,
                    weight: customChartProperties.axisTitleFontWeight,
                  },
                },
                type: 'linear',
                position: 'left',
                stacked: true,
                ticks: {
                  count: 5,
                  min: 0, // Minimum value
                  max: 100, // Maximum value
                  beginAtZero: true,
                  autoSkip: false,
                  stepSize: 20,
                  callback: function (val, index) {
                    return `${val} %`;
                  },
                },
              },
              x: {
                stacked: true,
                display: true,
              },
            },
          }}
        />
      </div>
    );
  }

  // Check if loading
  if (loading || (comparisonLoading && compareByToggle)) {
    return <CircularProgress className='mt-4 mx-auto d-block' size={200} />;
  }
  if (props.graphOnly) {
    return (
      <React.Fragment>
        {/* Tooltip */}
        <div className='col-12 flex-col px-0'>
          <KpiTooltip selectedSubset={selectedSubset} />
        </div>
        {!inspectionRateData ? (
          <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
        ) : (
          <React.Fragment>
            <div className='col-lg-6 flex-col text-center px-0 align-items-center mb-auto pb-3'>
              {inspectionRatesBarGraph()}
            </div>
            <div className='col-lg-6 flex-col text-center px-0 align-items-center mb-auto pb-3'>
              <KpiTable
                pageFilters={props.pageFilters}
                tableData={tableData}
                type={'inspections'}
                filters={filters}
                filterOptions={filterOptions}
                handleFilters={handleFilters}
                pageStates={props.pageStates}
              />
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  return (
    <Box sx={StatsViewReportMainWrapperStyle}>
      {/* Hidden Add to Scorecard */}
      <ScorecardSelectHolder
        pageFilters={filters}
        autofillPageName={'Machine Inspection Rates'}
        pageStates={{
          compareByToggle: compareByToggle,
          compareByDateRange: compareByDatesCache,
        }}
        hidden={true}
        opened={addToScorecardOpened}
        updateExternalOpenedState={setAddToScorecardOpened}
      />
      {/* Graph and XY Axis Control */}
      <Grid container spacing={2} sx={{padding: 1}}>
        <Grid item xs={12}>
          <Box sx={StatsViewReportGraphAndControlStyle}>
            {!inspectionRateData ? (
              <React.Fragment>
                <div className='col-lg-6 flex-col text-center px-0 align-items-center mt-5 mb-auto pb-3'>
                  No data available
                </div>
                <div className='col-lg-6 flex-col text-center px-0 align-items-center mb-auto pb-3'>
                  <KpiTable
                    pageFilters={props.pageFilters}
                    tableData={tableData}
                    type={'inspections'}
                    filters={filters}
                    filterOptions={filterOptions}
                    handleFilters={handleFilters}
                    pageStates={props.pageStates}
                  />
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className='col-lg-6 flex-col text-center px-0 align-items-center mb-auto pb-3'>
                  {inspectionRatesBarGraph()}
                </div>
                <div className='col-lg-6 flex-col text-center px-0 align-items-center mb-auto pb-3'>
                  <KpiTable
                    pageFilters={props.pageFilters}
                    tableData={tableData}
                    type={'inspections'}
                    filters={filters}
                    filterOptions={filterOptions}
                    handleFilters={handleFilters}
                    pageStates={props.pageStates}
                  />
                </div>
              </React.Fragment>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export {KpiInspectionRates};
